import * as Api from '@ViewModels';
import { StyleDeclaration, css } from 'aphrodite';
import { styleSheet } from './styles';

const handwritingStyleFontMap: Partial<Record<Api.HandwritingStyle, { fontFamily: string; fontSize: number }>> = {
	[Api.HandwritingStyle.Aladdin]: { fontFamily: Api.HandwritingStyle.Aladdin, fontSize: 23 },
	[Api.HandwritingStyle.Cinderella]: { fontFamily: Api.HandwritingStyle.Cinderella, fontSize: 30 },
	[Api.HandwritingStyle.Donald]: { fontFamily: Api.HandwritingStyle.Donald, fontSize: 22 },
	[Api.HandwritingStyle.Nemo]: { fontFamily: Api.HandwritingStyle.Nemo, fontSize: 28 },
	[Api.HandwritingStyle.Scar]: { fontFamily: Api.HandwritingStyle.Scar, fontSize: 27 },
	[Api.HandwritingStyle.Tarzan]: { fontFamily: Api.HandwritingStyle.Tarzan, fontSize: 28 },
	[Api.HandwritingStyle.Woody]: { fontFamily: Api.HandwritingStyle.Woody, fontSize: 24 },
};

export function HwcTextPreview({
	handwritingStyle,
	postcardText,
	signature,
	styleDeclaration,
}: {
	handwritingStyle: Api.HandwritingStyle;
	postcardText: string;
	signature: string;
	styleDeclaration?: StyleDeclaration;
}) {
	return (
		<div
			className={css(styleSheet.mainPreview, styleSheet.mainPreviewModeText, styleDeclaration)}
			style={{
				fontFamily: handwritingStyleFontMap[handwritingStyle].fontFamily,

				fontSize: handwritingStyleFontMap[handwritingStyle].fontSize,
			}}
		>
			<p className={css(styleSheet.mainPreviewTextContent)}>{postcardText}</p>
			<p className={css(styleSheet.mainPreviewSignature)}>{signature}</p>
		</div>
	);
}
