import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { AutomationStepIcon } from '../../svgs/icons/AutomationStepIcon';
import { styleSheet } from './styles';

interface IProps {
	automationTemplate: Api.AutomationTemplateViewModel | Api.IAutomationOnHold;
	maxStepsToDisplay?: number;
	className?: string;
	styles?: StyleDeclarationValue[];
}

export const AutomationSteps: React.FC<IProps> = props => {
	const { className, styles } = props;
	const { automationTemplate, maxStepsToDisplay } = props;
	let steps = [];
	if (automationTemplate instanceof Api.AutomationTemplateViewModel) {
		steps = automationTemplate.publishedStepReferences || automationTemplate.draftStepReferences || [];
		if (automationTemplate.status === Api.TemplateStatus.Draft) {
			steps = automationTemplate.draftStepReferences;
		}
	} else {
		const template = automationTemplate?.template;
		steps = template?.publishedSteps || [];
	}

	const visibleSteps = steps?.slice(0, maxStepsToDisplay);

	const overflowSteps = steps.length > maxStepsToDisplay ? steps.slice(maxStepsToDisplay) : [];
	return visibleSteps?.length > 0 ? (
		<div className={`${css(styleSheet.container, ...(styles || []))} automation-steps ${className || ''}`}>
			{visibleSteps.map(x => {
				return (
					<AutomationStepIcon
						className={css(styleSheet.stepIcon)}
						key={x.id}
						type={x._type as Api.AutomationStepModelType}
					/>
				);
			})}
			{overflowSteps?.length > 0 && (
				<span className={css(styleSheet.stepsOverflowLabel)}>{`... ${overflowSteps.length} more`}</span>
			)}
		</div>
	) : null;
};
