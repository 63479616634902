import { ContactViewModel, IOperationResultNoValue } from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import * as AppState from '../../../../models/AppState';
import { useEventLogging } from '../../../../models/Logging';
import { getPrincipalInitials } from '../../../../models/UiUtils';
import { useEnvironment, useErrorMessages, useUserSession } from '../../../../models/hooks/appStateHooks';
import { DataOriginContainer } from '../../../containers/DataOriginContainer';
import { white } from '../../../styles/colors';
import { Avatar2 } from '../../Avatar2';
import { ResourceVisibility, VisibilityDropdown } from '../../VisibilityDropdown';
import { FullscreenModalNavLink } from '../../fullscreen/FullscreenModalNavLink';
import { EmailIcon } from '../../svgs/icons/EmailIcon';
import { PostageIcon } from '../../svgs/icons/PostageIcon';
import { CalendarButton } from './presentation';
import { styleSheet } from './styles';

interface IProps extends AppState.IEnvironmentComponentProps {
	className?: string;
	compactLayout?: boolean;
	contact: ContactViewModel;
	disableCompanyLink?: boolean;
	linkToProfile?: boolean;
	showEmailQuickBtn?: boolean;
	showHwcActionButton?: boolean;
	onEmailQuickBtnClicked?: () => void;
	onPostageBtnClicked?: () => void;
	onBookAppointmentBtnClicked?: (url: string) => void;
}

function _ContactInfoHeader({
	className,
	compactLayout,
	contact,
	disableCompanyLink,
	linkToProfile,
	showEmailQuickBtn,
	showHwcActionButton,
	onEmailQuickBtnClicked,
	onPostageBtnClicked,
	onBookAppointmentBtnClicked,
}: IProps) {
	const userSession = useUserSession();
	const environment = useEnvironment();

	const { logEvent, logInput } = useEventLogging('ContactInfoHeader');
	const errorMessages = useErrorMessages();
	const [visibility, setVisibility] = React.useState<ResourceVisibility>();
	const renderLinkToProfile = (el: JSX.Element) => {
		if (!linkToProfile) {
			return el;
		}
		return (
			<Link className={css(styleSheet.link)} to={`/people/${contact.id}`}>
				{el}
			</Link>
		);
	};

	const onVisibilityChanged = (_visibility: ResourceVisibility) => {
		if (contact.visibility !== _visibility) {
			if (_visibility === 'admin') {
				// check to make sure we can...
				// TODO: doesn't account for shared => admin
				const canChangeVisibility =
					contact.creatorId === userSession.user.id ||
					(userSession.user.groups || []).findIndex(x => x === 'admin') >= 0;
				if (!canChangeVisibility) {
					errorMessages.push({
						messages: ['You do not have permission to make this contact private.'],
					});
					return;
				}
			}
			const promise = contact.updateVisibility(_visibility);
			if (promise) {
				logInput('ChangeVisibility', 'Click', { visibility: _visibility });
				// override visibility while we set it server-side
				setVisibility(_visibility);
				promise
					.then(() => {
						// clear the override... the vm value should be updated by now

						setVisibility(null);
					})
					.catch((error: IOperationResultNoValue) => {
						// clear the override...

						setVisibility(null);

						errorMessages.push({
							messages: [error.systemMessage],
						});
						logEvent('ChangeVisibility-Error', { ...error });
					});
			}
		}
	};

	const renderTitleInfo = () => {
		if (contact.jobTitle || contact.companyName) {
			const contactModel = contact.toJs();
			const companyId = contactModel.company ? contactModel.company.id : null;
			return (
				<div className={css(styleSheet.headerInfoTitle)}>
					{!!contact.jobTitle && (
						<span className='truncate-text' title={contact.jobTitle}>
							{contact.jobTitle}
						</span>
					)}
					{!!contact.jobTitle && !!contact.companyName && <span>&nbsp;at&nbsp;</span>}
					{!!contact.companyName &&
						(!!companyId && !disableCompanyLink ? (
							<FullscreenModalNavLink
								className={`brand-link truncate-text ${css(styleSheet.companyLink)}`}
								title={contact.companyName}
								to={{ pathname: `/companies/${companyId}` }}
							>
								{contact.companyName}
							</FullscreenModalNavLink>
						) : (
							<span className={`truncate-text ${css(styleSheet.companyLink)}`}>{contact.companyName}</span>
						))}
				</div>
			);
		}

		return null;
	};

	const renderQuickActionButtons = () => {
		if (environment?.appType === 'web') {
			return (
				<>
					{showEmailQuickBtn ? (
						<a className={css(styleSheet.quickActionButton)} onClick={onEmailQuickBtnClicked}>
							<EmailIcon fillColor={white} width={16} height={14} />
						</a>
					) : null}

					{userSession.account.features.handwrittenCards?.enabled && showHwcActionButton ? (
						<a className={css(styleSheet.quickActionButton)} onClick={onPostageBtnClicked}>
							<PostageIcon fillColor={white} width={16} height={16} />
						</a>
					) : null}
					<CalendarButton contact={contact} onBookAppointmentBtnClicked={onBookAppointmentBtnClicked} />
				</>
			);
		}

		return null;
	};

	return (
		<div className={`${css(styleSheet.header)} contact-info-header ${className || ''}`}>
			{renderLinkToProfile(
				<Avatar2
					styleDeclaration={[styleSheet.avatar, styleSheet.avatarOverride]}
					fallbackText={getPrincipalInitials(contact)}
					imgSrc={contact?.profilePic}
				/>
			)}
			<div className={css(styleSheet.headerInfo)}>
				<div className={css(styleSheet.basicContactInfo)}>
					{contact.name && (
						<DataOriginContainer className={css(styleSheet.dataOriginName)} contact={contact} dataType='Name'>
							{renderLinkToProfile(<div className={css(styleSheet.headerInfoName)}>{contact.name}</div>)}
						</DataOriginContainer>
					)}
					{renderTitleInfo()}
				</div>
				<div className={css(styleSheet.reachability, compactLayout ? styleSheet.reachabilityCompact : null)}>
					<VisibilityDropdown
						className={css(styleSheet.visibility)}
						onVisibilityChanged={onVisibilityChanged}
						visibility={visibility || contact.visibility}
					/>
					<div className={css(styleSheet.quickActionButtons, compactLayout && styleSheet.quickActionButtonsCompact)}>
						{contact.socialProfiles != null
							? contact.socialProfiles.map(socialProfile => (
									<a
										className={css(
											styleSheet.quickActionButtonSocial,
											{
												facebook: styleSheet.quickActionButtonFb,
												linkedin: styleSheet.quickActionButtonLinkedin,
												twitter: styleSheet.quickActionButtonTwitter,
											}[socialProfile.typeId]
										)}
										href={socialProfile.url}
										target='_blank'
										rel='noreferrer'
										key={socialProfile.typeId}
									/>
								))
							: null}
						{renderQuickActionButtons()}
					</div>
				</div>
			</div>
		</div>
	);
}

export const ContactInfoHeader = observer(_ContactInfoHeader);
