import * as React from 'react';
import Waypoint from 'react-waypoint';
import shallowEqual from 'shallowequal';
import { Browser } from '../../../models/Browser';
import { Portal } from '../Portal';
import './styles.less';

interface IProps {
	className?: string;
	contentClassName?: string;
	hasFixedHeader?: boolean;
	header?: React.ReactNode;
	onScrollToBottom?(): void;
	scrollToBottomPortalId?: string;
	scrollViewId?: string;
}

interface IState {
	headerBounds?: ClientRect | DOMRect;
}

export class ScrollView extends React.PureComponent<IProps, IState> {
	public state: IState = {};
	private canUseStickyPositioning = Browser.supportsStickyPositioning;

	private headerDomElement: HTMLDivElement;

	public render() {
		const { className, children, hasFixedHeader, contentClassName, scrollViewId } = this.props;
		const { headerBounds } = this.state;
		const renderAbsoluteHeader = !!hasFixedHeader && !this.canUseStickyPositioning;
		const renderStickyHeader = !!hasFixedHeader && !!this.canUseStickyPositioning;
		return (
			<div className={`scroll-view ${className || ''}`}>
				{!!renderAbsoluteHeader && this.onRenderHeader(true)}
				<div className={`scroll-view-content ${contentClassName || ''}`} id={scrollViewId}>
					{(!hasFixedHeader || !!renderStickyHeader) && this.onRenderHeader(false, !!renderStickyHeader)}
					<div className='scroll-view-items'>
						{!!headerBounds && !!renderAbsoluteHeader && (
							<div
								className='scroll-view-items-header-placeholder'
								style={{ height: headerBounds.height || 0, width: 'auto' }}
							/>
						)}
						{children}
					</div>
					{this.renderScrollToBottomWaypoint()}
				</div>
			</div>
		);
	}

	private renderScrollToBottomWaypoint() {
		const { scrollToBottomPortalId, onScrollToBottom } = this.props;
		if (!onScrollToBottom) {
			return null;
		}
		const waypoint = <Waypoint bottomOffset='-200px' onEnter={onScrollToBottom} />;
		if (scrollToBottomPortalId) {
			return <Portal destination={scrollToBottomPortalId}>{waypoint}</Portal>;
		}
		return waypoint;
	}

	private onHeaderRef = (ref?: HTMLDivElement) => {
		this.headerDomElement = ref;
		setTimeout(() => {
			const headerBounds: ClientRect | DOMRect = this.headerDomElement
				? this.headerDomElement.getBoundingClientRect()
				: null;
			if (!shallowEqual(headerBounds, this.state.headerBounds)) {
				this.setState({
					headerBounds,
				});
			}
		}, 1);
	};

	private onRenderHeader(renderAbsoluteHeader: boolean, renderStickyHeader = false) {
		const { header, contentClassName } = this.props;
		if (header) {
			const style: React.CSSProperties = renderAbsoluteHeader
				? { position: 'absolute' }
				: renderStickyHeader
					? undefined
					: { position: 'static' };
			return (
				<div
					className={`scroll-view-header ${renderAbsoluteHeader ? contentClassName || '' : ''}`}
					ref={renderAbsoluteHeader ? this.onHeaderRef : undefined}
					style={style}
				>
					<div className='scroll-view-header-content'>{header}</div>
				</div>
			);
		}
	}
}
