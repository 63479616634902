import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import moment from 'moment';
import * as React from 'react';
import { generatePath, useLocation, useParams } from 'react-router';
import { getDisplayName } from '../../../extViewmodels/Utils';
import { useEventLogging } from '../../../models/Logging';
import {
	createContentStateWithHtmlStringValue,
	getContactCompanyLine,
	getMultilineFormattedAddress,
	getPrincipalInitials,
	hasRequiredHwcRecipientAddressFields,
	isAddressComplete,
} from '../../../models/UiUtils';
import { useErrorMessages, useFullscreenModal, useToaster, useUserSession } from '../../../models/hooks/appStateHooks';
import {
	invalidateHandwrittenCardAllCategoryTemplates,
	invalidateMyTemplates,
	useHWCOrderMutation,
	useTemplate,
	useTemplateDeleteMutation,
} from '../../../queries';
import { Avatar2 } from '../../components/Avatar2';
import { DayPicker } from '../../components/DayPicker';
import { DeleteConfirmation } from '../../components/DeleteConfirmation';
import { DeprecatedCloseButton } from '../../components/DeprecatedCloseButton';
import { DeprecatedPopover, PopoverType } from '../../components/DeprecatedPopover';
import { FabContext } from '../../components/FabContext';
import { GlobalSearch } from '../../components/GlobalSearch';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { Modal } from '../../components/Modal';
import { MultiContainerHeader } from '../../components/MultiContainerHeader';
import { TextArea } from '../../components/TextArea';
import { TextInput } from '../../components/TextInput';
import { TinyPopover } from '../../components/TinyPopover';
import { PurchaseCreditsModal } from '../../components/settings/PurchaseCreditsModal';
import { DropDownIcon } from '../../components/svgs/icons/DropDownIcon';
import { PostcardPostage } from '../../components/svgs/icons/PostcardPostage';
import { PostcardSpec } from '../../components/svgs/icons/PostcardSpec';
import { PostcardType } from '../../components/svgs/icons/PostcardType';
import { baseStyleSheet } from '../../styles/styles';
import { useHwcSaveTemplate } from '../BulkHwcPages/hooks/useHwcSaveTemplate';
import { SaveTemplateModal } from '../BulkHwcPages/presentation';
import { AddressFormField } from './AddressFormField';
import { HwcFrontBackPreview } from './HwcFrontBackPreview';
import { HwcOrderSuccessPage } from './HwcOrderSuccessPage';
import { HwcTogglePreviewValues } from './HwcPreviewToggle';
import { PostcardTextEditor } from './PostcardTextEditor';
import { RecipientAddressModal, addresseeNameFromHwcRecipient } from './RecipientAddressModal';
import { ReturnContactModal } from './ReturnAddressModal';
import { useCurrentUserGiftingBalance } from './hooks/useCurrentUserGiftingBalance';
import { HANDWRITTEN_CARD_TEXT_MAX_ALLOWED_CHARACTERS, usePostcardTextEditor } from './hooks/usePostcardTextEditor';
import { useRecipient } from './hooks/useRecipient';
import { styleSheet } from './styles';

export enum PostcardComposerStep {
	Preview = 'preview',
	Personalize = 'personalize',
	Order = 'order',
	Recipient = 'recipient',
	Success = 'success',
}

export const addressTextFromHwcRecipient = (recipient: Api.IHandwrittenCardRecipient) => {
	const displayName =
		recipient.address?.name ?? getDisplayName({ firstName: recipient.firstName, lastName: recipient.lastName });
	return `${displayName ? `${displayName}\n` : ''}${getMultilineFormattedAddress(recipient.address)}`;
};

export const addressTextFromHwcReturnContact = (returnContact: Api.IHandwrittenCardReturnContact) => {
	const displayName = getDisplayName({ firstName: returnContact.firstName, lastName: returnContact.lastName });

	return `${displayName ? `${displayName}\n` : ''}${getMultilineFormattedAddress(returnContact.address)}`;
};

export function PostcardComposer() {
	const userSession = useUserSession();
	const fullscreenModal = useFullscreenModal();
	const errorMessages = useErrorMessages();
	const toaster = useToaster();
	const { logApiError } = useEventLogging('PostcardComposer');
	const { step, templateId } = useParams<{
		step: PostcardComposerStep;
		templateId: string;
	}>();
	const location = useLocation<{ contactId?: string }>();

	const [selectedContact, setSelectedContact] = React.useState<Api.IContact>(null);
	const contactId = selectedContact?.id ?? location.state?.contactId;
	const templateQuery = useTemplate<Api.IHandwrittenCardTemplate>({ templateId });
	const [postcardContent, setPostcardContent] = React.useState('');
	const {
		createTemplateMutation,
		handleSaveAsButtonClicked,
		handleSaveAsModalClose,
		handleSaveAsModalSave,
		handleSaveButtonClicked,
		isSaveAllowed,
		isSaveAsPopoverShowing,
		isShowingSaveAsModal,
		toggleSaveAsPopover,
		updatingTemplateStatus,
	} = useHwcSaveTemplate({
		onSavedNewTemplate: template => {
			invalidateMyTemplates({ templateType: Api.TemplateType.HandwrittenCard });
			invalidateHandwrittenCardAllCategoryTemplates();

			fullscreenModal.history.replace({
				pathname: generatePath('/postcard/:templateId/:step', {
					step,

					templateId: template.id,
				}),
				state: location.state,
			});
		},
		postcardContent,

		template: templateQuery.data,
	});
	React.useEffect(() => {
		if (templateQuery.data?.content) {
			setPostcardContent(Api.getContentHtmlStringValueFromRawRichTextContentState(templateQuery.data.content));
		}
	}, [templateQuery.data?.content]);
	const { handwritingStyle, postcardText, getPostcardTextEditorProps } = usePostcardTextEditor({
		content: postcardContent,

		defaultHandwritingStyle: userSession.user.userPreferences?.handwrittenCardPreferences?.handwritingStyle,
		onContentChanged: setPostcardContent,
	});

	const [signature, setSignature] = React.useState(
		userSession.user.userPreferences?.handwrittenCardPreferences?.signature ?? ''
	);
	const [datePickerOpen, setDatePickerOpen] = React.useState(false);

	const [mailDate, setMailDate] = React.useState<Date>(null);
	const { handleRecipientChange, hwcRecipient, contactQuery } = useRecipient({ contactId });
	const recipientAddressText =
		hwcRecipient && hasRequiredHwcRecipientAddressFields(hwcRecipient.address)
			? addressTextFromHwcRecipient(hwcRecipient)
			: '';
	const [returnContact, setReturnContact] = React.useState<Api.IHandwrittenCardReturnContact>(
		userSession.user?.userPreferences?.handwrittenCardPreferences?.returnContact ??
			userSession.account?.preferences?.handwrittenCardPreferences?.returnContact
	);
	const returnAddressText =
		returnContact && isAddressComplete(returnContact.address) ? addressTextFromHwcReturnContact(returnContact) : '';
	const mailDateText = mailDate ? moment(mailDate).format('YYYY-MM-DD') : '';
	const [showRecipientAddress, setShowRecipientAddress] = React.useState(false);
	const [showReturnContactModal, setShowReturnContactModal] = React.useState(false);
	const [toggledPreview, setToggledPreview] = React.useState<HwcTogglePreviewValues>('text');
	const [showingDeleteTemplateConfirmation, setShowingDeleteTemplateConfirmation] = React.useState(false);
	const [showPurchaseCreditsModal, setShowPurchaseCreditsModal] = React.useState(false);
	const { balanceAfterSend, currentBalance, giftingQuery } = useCurrentUserGiftingBalance();
	const canPlaceOrder =
		postcardText && mailDate && returnContact && hwcRecipient && currentBalance > 0 && balanceAfterSend >= 0;
	const handleShowRecipientAddress = () => setShowRecipientAddress(true);
	const handleShowReturnContactModal = () => setShowReturnContactModal(true);
	const orderMutation = useHWCOrderMutation({
		onError: error => {
			toaster.push({
				message: error.systemMessage,
				type: 'errorMessage',
			});
			logApiError('orderMutation', error);
		},
		onSuccess: () => {
			fullscreenModal.history.replace({
				pathname: `/postcard/${templateId}/${PostcardComposerStep.Success}`,
				state: location.state,
			});

			toaster.push({ message: 'Your handwritten card has been scheduled!', type: 'successMessage' });
		},
	});
	const templateDeleteMutation = useTemplateDeleteMutation({
		onError: error => {
			logApiError('templateDeleteMutation', error);

			errorMessages.pushApiError(error);
		},
		onSuccess: () => {
			setShowingDeleteTemplateConfirmation(false);

			fullscreenModal.dismissModal();

			toaster.push({
				message: 'Card deleted successfully!',
				type: 'successMessage',
			});
			invalidateMyTemplates({ templateType: Api.TemplateType.HandwrittenCard });
			invalidateHandwrittenCardAllCategoryTemplates();
		},
	});
	const renderImagePreview = () => {
		return (
			<div className={css(styleSheet.mainPreview, styleSheet.mainPreviewModeImage)}>
				{templateQuery.data ? (
					<img
						src={templateQuery.data.image.publicUrl}
						alt='Template image preview'
						className={css(styleSheet.mainPreviewImage)}
					/>
				) : (
					<LoadingSpinner />
				)}
			</div>
		);
	};

	return (
		<div className={css(styleSheet.root)}>
			{step === PostcardComposerStep.Success ? (
				<>
					<MultiContainerHeader fullscreenHeader='Order Confirmation' />
					<HwcOrderSuccessPage />
				</>
			) : null}
			{step !== PostcardComposerStep.Success ? (
				<>
					<MultiContainerHeader fullscreenHeader='View Card' />
					<section className={css(styleSheet.mainPreviewContainer)}>
						<div className={css(styleSheet.actionsToolbar)}>
							{step === PostcardComposerStep.Personalize ||
							step === PostcardComposerStep.Recipient ||
							step === PostcardComposerStep.Order ? (
								isSaveAllowed ? (
									<TinyPopover
										anchor={
											<div className={css(styleSheet.outlineButton, baseStyleSheet.outlineButtonContainer)}>
												<button
													className={css(baseStyleSheet.outlineButton)}
													onClick={handleSaveButtonClicked}
													disabled={updatingTemplateStatus === 'loading' || !postcardContent}
												>
													<span>Save</span>
												</button>
												<button className={css(styleSheet.saveAsDropdownCaret)} onClick={toggleSaveAsPopover}>
													<DropDownIcon />
												</button>
											</div>
										}
										isOpen={isSaveAsPopoverShowing}
										dismissOnOutsideAction={true}
										align='center'
										placement={['bottom']}
										onRequestClose={toggleSaveAsPopover}
									>
										<button
											className={css(baseStyleSheet.outlineButtonContainer, styleSheet.outlineButton)}
											disabled={false}
											onClick={handleSaveAsButtonClicked}
										>
											Save as
										</button>
									</TinyPopover>
								) : (
									<button
										className={css(baseStyleSheet.outlineButtonContainer, styleSheet.outlineButton)}
										disabled={false}
										onClick={handleSaveAsButtonClicked}
									>
										Save as
									</button>
								)
							) : null}
							{(templateQuery.data?.scope === Api.TemplateScope.Account ||
								templateQuery.data?.scope === Api.TemplateScope.User) &&
							(templateQuery.data?.creator?.id === userSession.user.id ||
								new RegExp(/admin/i).test(userSession.user.role)) ? (
								<div className={css(baseStyleSheet.outlineButtonContainerDestructive)}>
									<button
										className={css(baseStyleSheet.outlineButtonDestructive)}
										onClick={() => setShowingDeleteTemplateConfirmation(true)}
									>
										<span>Delete</span>
									</button>
								</div>
							) : null}
						</div>
						<div className={css(styleSheet.mainPreviewContainer2)}>
							<div className={css(styleSheet.previewWrapper)}>
								<p className={css(styleSheet.cardPreviewText)}>Card Preview</p>
								{step === PostcardComposerStep.Preview ? (
									renderImagePreview()
								) : (
									<HwcFrontBackPreview
										templateId={templateId}
										value={toggledPreview}
										handwritingStyle={handwritingStyle}
										signature={signature}
										postcardText={postcardText}
										onValueChange={setToggledPreview}
									/>
								)}
							</div>
						</div>
					</section>
					<section className={css(styleSheet.configDrawer)}>
						{step === PostcardComposerStep.Preview ? (
							<>
								{templateQuery.data ? (
									<h2 className={css(styleSheet.configDrawerHeader)}>{templateQuery.data.name}</h2>
								) : (
									<LoadingSpinner type='small' />
								)}
								<div className={css(styleSheet.divider)} />
								<div className={css(styleSheet.cardInfoList)}>
									<div className={css(styleSheet.cardInfoItem)}>
										<PostcardSpec width={40} />
										<div>
											<div className={css(styleSheet.cardInfoHeader)}>Specs</div>
											<div className={css(styleSheet.cardInfoText)}>5 x 7”</div>
										</div>
									</div>
									<div className={css(styleSheet.cardInfoItem)}>
										<PostcardType width={40} />
										<div>
											<div className={css(styleSheet.cardInfoHeader)}>Card Type</div>
											<div className={css(styleSheet.cardInfoText)}>Folded</div>
										</div>
									</div>
									<div className={css(styleSheet.cardInfoItem)}>
										<PostcardPostage width={40} />
										<div>
											<div className={css(styleSheet.cardInfoHeader)}>Postage</div>
											<div className={css(styleSheet.cardInfoText)}>Postage Included</div>
										</div>
									</div>
								</div>
								<div className={css(styleSheet.divider)} />
								<button
									className={css(baseStyleSheet.ctaButton, styleSheet.personalizeButton)}
									onClick={() =>
										fullscreenModal.history.push({
											pathname: `/postcard/${templateId}/${PostcardComposerStep.Personalize}`,
											state: location.state,
										})
									}
								>
									Personalize & Send
								</button>
							</>
						) : null}
						{step === PostcardComposerStep.Personalize ? (
							<>
								<h2 className={css(styleSheet.configDrawerHeader)}>Personalization</h2>
								<div className={css(styleSheet.configDrawerSection)}>
									<h3 className={css(styleSheet.configDrawerMessage)}>Message</h3>
									<span
										className={css(
											styleSheet.configDrawerSubtitle,
											styleSheet.configDrawerCharCount,

											postcardText.length > HANDWRITTEN_CARD_TEXT_MAX_ALLOWED_CHARACTERS &&
												styleSheet.configDrawerCharCountOver
										)}
									>
										{postcardText.length}/{HANDWRITTEN_CARD_TEXT_MAX_ALLOWED_CHARACTERS}
									</span>
									<PostcardTextEditor {...getPostcardTextEditorProps()} />
								</div>
								<div className={css(styleSheet.configDrawerSection)}>
									<h4 className={css(styleSheet.configDrawerSelectSig)}>Signature</h4>
									<TextArea
										inputId='postcard-signature'
										value={signature}
										onChange={ev => setSignature(ev.target.value)}
									/>
								</div>
								<button
									className={css(baseStyleSheet.ctaButton)}
									onClick={() => {
										if (location.state?.contactId == null) {
											fullscreenModal.history.push({
												pathname: `/postcard/${templateId}/${PostcardComposerStep.Recipient}`,
												state: location.state,
											});
											return;
										}

										fullscreenModal.history.push({
											pathname: `/postcard/${templateId}/${PostcardComposerStep.Order}`,
											state: location.state,
										});
									}}
									disabled={!postcardText || postcardText.length > HANDWRITTEN_CARD_TEXT_MAX_ALLOWED_CHARACTERS}
								>
									{location.state?.contactId == null ? 'Next: Choose Recipient' : `Next: Review Order`}
								</button>
							</>
						) : null}
						{step === PostcardComposerStep.Recipient ? (
							<>
								<h2 className={css(styleSheet.configDrawerHeader, styleSheet.configDrawerHeaderRecipient)}>
									Select Recipient
								</h2>
								<p
									className={css(
										styleSheet.configDrawerSubtitle,
										styleSheet.configDrawerSubtitleRecipient,
										styleSheet.mb40
									)}
								>
									Search your contacts for who you would to recieve this card
								</p>
								<div className={css(styleSheet.divider, styleSheet.mb40)} />
								<p className={css(styleSheet.selectRecipientToSend, styleSheet.mb16)}>Select recipient to send:</p>
								{selectedContact ? (
									<>
										{contactQuery.isLoading ? (
											<div className={css(styleSheet.selectRecipientContactSpinnerContainer)}>
												<LoadingSpinner type='small' />
											</div>
										) : null}
										{contactQuery.data ? (
											<div className={css(styleSheet.selectRecipientContactPreview)}>
												<DeprecatedCloseButton onClick={() => setSelectedContact(null)} />
												<Avatar2
													imgSrc={contactQuery.data.profilePic}
													altText={contactQuery.data ? getDisplayName(contactQuery.data) : ''}
													fallbackText={getPrincipalInitials(contactQuery.data)}
													styleDeclaration={styleSheet.selectRecipientContactPreviewAvatar}
												/>
												<div className={css(styleSheet.selectRecipientContactNameContainer)}>
													<div className={css(baseStyleSheet.truncateText, styleSheet.selectRecipientContactName)}>
														{getDisplayName(contactQuery.data)}
													</div>
													{contactQuery.data.companyName ? (
														<div className={css(baseStyleSheet.truncateText, styleSheet.selectRecipientContactCompany)}>
															{getContactCompanyLine(contactQuery.data)}
														</div>
													) : null}
												</div>
											</div>
										) : null}
									</>
								) : (
									<GlobalSearch
										onContactSelected={contact => setSelectedContact(contact)}
										resultsStyles={[styleSheet.selectRecipientContactResults]}
									/>
								)}
								<div className={css(styleSheet.divider, styleSheet.mt40, styleSheet.mb56)} />
								<button
									className={css(baseStyleSheet.ctaButton)}
									onClick={() => {
										fullscreenModal.history.push({
											pathname: `/postcard/${templateId}/${PostcardComposerStep.Order}`,
											state: location.state,
										});
									}}
									disabled={!selectedContact}
								>
									Next: Review Order
								</button>
							</>
						) : null}
						{step === PostcardComposerStep.Order ? (
							<>
								<h2 className={css(styleSheet.configDrawerHeader)}>Review Order</h2>
								<div className={css(styleSheet.divider, styleSheet.dividerMargin)} />
								<div className={css(styleSheet.configDrawerSection)}>
									<strong className={css(styleSheet.configDrawerThirdHeader)}>
										Mail Date <span className={css(styleSheet.requiredIndicator)}>*</span>
									</strong>
									<span className={css(styleSheet.configDrawerSubtitle, styleSheet.mb16)}>
										Please allow up to 10 days for delivery
									</span>
									<DeprecatedPopover
										anchor={
											<span>
												<TextInput
													inputId='postcard-mail-date'
													type='date'
													onChange={ev => {
														const parsed = moment(ev.target.value);
														if (parsed.isValid()) {
															setMailDate(parsed.toDate());
														}
													}}
													onClick={() => setDatePickerOpen(true)}
													value={mailDateText}
												/>
											</span>
										}
										dismissOnClickOutside={true}
										isOpen={datePickerOpen}
										onRequestClose={() => setDatePickerOpen(false)}
										type={PopoverType.white}
										preferredPlacement='below'
									>
										<DayPicker month={mailDate} onDayClick={setMailDate} selectedDays={mailDate} />
									</DeprecatedPopover>
								</div>
								<AddressFormField
									address={returnAddressText}
									btnLabel='Add Return Address'
									id='return-address'
									label='Return Address'
									onBtnClick={handleShowReturnContactModal}
									onEditBtnClick={handleShowReturnContactModal}
									required={true}
								/>
								{contactQuery.status === 'loading' ? <LoadingSpinner /> : null}
								{contactQuery.status === 'error' ? (
									<p role='error' className={css(styleSheet.errorContactLoad)}>
										Failed to load contact&apos;s address
									</p>
								) : null}
								{contactQuery.status === 'success' ? (
									<AddressFormField
										address={recipientAddressText}
										btnLabel='Add Recipient Address'
										id='recipient-address'
										label='Recipient Address'
										onBtnClick={handleShowRecipientAddress}
										onEditBtnClick={handleShowRecipientAddress}
										required={true}
									/>
								) : null}
								<div className={css(styleSheet.divider, styleSheet.dividerMargin)} />
								{giftingQuery.status === 'loading' ? <LoadingSpinner /> : null}
								{giftingQuery.status === 'error' ? (
									<p role='error' className={css(styleSheet.errorContactLoad)}>
										Failed to load balance data
									</p>
								) : null}
								{giftingQuery.status === 'success' ? (
									<>
										<p className={css(styleSheet.balanceContainer)}>
											<strong className={css(styleSheet.balanceText)}>Current Balance:</strong>
											{new Intl.NumberFormat('en-US', { currency: 'USD', style: 'currency' }).format(currentBalance)}
										</p>

										{balanceAfterSend >= 0 || !Api.isAdmin(userSession.user) ? (
											<p className={css(styleSheet.balanceContainer)}>
												<strong className={css(styleSheet.balanceText)}>Balance After Send: </strong>{' '}
												{new Intl.NumberFormat('en-US', { currency: 'USD', style: 'currency' }).format(
													balanceAfterSend
												)}
											</p>
										) : (
											<button
												className={css(baseStyleSheet.ctaButtonSecondary)}
												onClick={() => setShowPurchaseCreditsModal(true)}
											>
												+ Add credits to continue
											</button>
										)}
									</>
								) : null}
								<div className={css(styleSheet.divider, styleSheet.dividerMargin)} />
								{userSession.account?.features?.handwrittenCards?.creditCostPerCard != null ? (
									<p className={css(styleSheet.balanceContainer)}>
										<strong className={css(styleSheet.total)}>Total:</strong>
										{new Intl.NumberFormat('en-US', { currency: 'USD', style: 'currency' }).format(
											userSession.account.features.handwrittenCards.currencyCostPerCard
										)}
									</p>
								) : null}
								<div className={css(styleSheet.divider, styleSheet.dividerMargin)} />
								<button
									className={css(baseStyleSheet.ctaButton)}
									disabled={orderMutation.isLoading || !canPlaceOrder}
									onClick={() => {
										const content = createContentStateWithHtmlStringValue(postcardContent).getRawRichTextContent();
										orderMutation.mutate({
											content,
											recipients: [hwcRecipient],
											returnContact,
											scheduledSendDate: mailDate,
											signature,
											style: handwritingStyle,
											templateId,
										});
									}}
								>
									Place Order
								</button>
							</>
						) : null}
					</section>
				</>
			) : null}
			<FabContext appearance={{ hidden: true }} />
			{showRecipientAddress ? (
				<RecipientAddressModal
					isOpen={showRecipientAddress}
					initialData={{
						addresseeName: addresseeNameFromHwcRecipient(hwcRecipient),
						address1: hwcRecipient.address?.address1 ?? '',
						address2: hwcRecipient.address?.address2 ?? '',
						city: hwcRecipient.address?.city ?? '',
						salutationName: hwcRecipient.firstName ?? '',
						postalCode: hwcRecipient.address?.postalCode ?? '',
						stateProvince: hwcRecipient.address?.stateProvince ?? '',
					}}
					onSave={data => {
						handleRecipientChange({
							...data,
							address: {
								name: data.addresseeName,
								address1: data.address1,
								address2: data.address2,
								city: data.city,
								postalCode: data.postalCode,
								stateProvince: data.stateProvince,
							},

							contactId,
							firstName: data.salutationName,
						});
						setShowRecipientAddress(false);
					}}
					onCancel={() => setShowRecipientAddress(false)}
				/>
			) : null}
			{showReturnContactModal ? (
				<ReturnContactModal
					isOpen={showReturnContactModal}
					initialRecipient={returnContact}
					onSave={data => {
						setReturnContact({
							...data,
							address: {
								address1: data.address1,
								address2: data.address2,
								city: data.city,
								country: data.country,
								postalCode: data.postalCode,
								stateProvince: data.stateProvince,
							},
							firstName: data.firstName,
							lastName: data.lastName,
						});
						setShowReturnContactModal(false);
					}}
					onCancel={() => setShowReturnContactModal(false)}
				/>
			) : null}
			{isShowingSaveAsModal ? (
				<Modal
					useDefaultHeader
					className={css(styleSheet.templateSaveModal)}
					isOpen={true}
					shouldCloseOnOverlayClick={false}
					onRequestClose={handleSaveAsModalClose}
				>
					<SaveTemplateModal
						initialTemplateScope={
							templateQuery.data?.scope === Api.TemplateScope.Account
								? Api.TemplateScope.Account
								: Api.TemplateScope.User
						}
						actionButtonsDisabled={createTemplateMutation.isLoading}
						onSaveButtonClicked={({ templateName, scope }) => {
							handleSaveAsModalSave({ name: templateName, scope });
						}}
						onCancelButtonClicked={handleSaveAsModalClose}
					/>
				</Modal>
			) : null}
			<DeleteConfirmation
				bodyText={`Are you sure you want to delete your card named ${templateQuery.data?.name}?`}
				destructiveButtonText='Yes, Delete'
				isOpen={showingDeleteTemplateConfirmation}
				onFinish={shouldDelete => {
					if (shouldDelete) {
						templateDeleteMutation.mutate({ id: templateQuery.data.id });
						return;
					}
					setShowingDeleteTemplateConfirmation(false);
				}}
				showSpinner={templateDeleteMutation.isLoading}
			/>
			<PurchaseCreditsModal
				isOpen={showPurchaseCreditsModal}
				onRequestClose={() => setShowPurchaseCreditsModal(false)}
			/>
		</div>
	);
}
