import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { IModalContext, ModalChildComponentContextKey } from '../../../../models';
import { IEventLoggingComponentProps, withEventLogging } from '../../../../models/Logging';
import { useErrorMessages, useUserSession } from '../../../../models/hooks/appStateHooks';
import { baseStyleSheet } from '../../../styles/styles';
import { Button } from '../../Button';
import { asModalComponent } from '../../Modal';
import { AutomationStepsBy } from '../../automation/AutomationSelector/AutomationStepsBy';
import { AutomationConfirmationHeader } from '../../contacts/ContactsAddTagModal/presentation';
import { styleSheet } from './styles';

interface IProps extends RouteComponentProps<any>, IModalContext<any>, IEventLoggingComponentProps {
	automation: Api.AutomationTemplateViewModel<Api.UserSessionContext>;
	contactId: string;
	className?: string;
}

const getCreateAutomationRequestForContacts = (
	userSession: Api.UserSessionContext,
	contactId: string,
	selectedFrom: Api.AutomationSelectionType,
	selectedEmployee: Api.IUser,
	isAdmin: boolean
) => {
	const request: Api.ICreateAutomationRequest = {
		autoStart: true,
		excludeContactIds: [],

		filter: null,
		includeContactIds: [contactId],
		putOnHold: false,
	};

	if (isAdmin) {
		request.sendFromOptions = {
			mode:
				selectedFrom === Api.AutomationSelectionType.ContactOwners
					? Api.SendEmailFrom.ContactOwner
					: Api.SendEmailFrom.CurrentUser,
		};
		if (selectedFrom === Api.AutomationSelectionType.Myself) {
			request.sendFromOptions.mode = Api.SendEmailFrom.SelectedUser;
			request.sendFromOptions.selectedUser = userSession.user?.id;
		}
		if (selectedEmployee?.id && selectedFrom === Api.AutomationSelectionType.Employee) {
			request.sendFromOptions.mode = Api.SendEmailFrom.SelectedUser;
			request.sendFromOptions.selectedUser = selectedEmployee.id;
		}
	}
	return request;
};

const _AutomationFromModal = ({
	automation,
	contactId,

	parentModal: { onRequestClose },
	logApiError,
	logEvent,
}: IProps) => {
	const userSession = useUserSession();
	const errorMessages = useErrorMessages();
	const [selectedEmployee, setSelectedEmployee] = useState<Api.IUser | null>(null);
	const [selectedFrom, setSelectedFrom] = useState<Api.AutomationSelectionType>(Api.AutomationSelectionType.Myself);

	const [preview, setPreview] = useState<Api.IPreviewCreateAutomationResult | null>(undefined);
	const [contact, setContact] = useState<Api.ContactViewModel | null>(null);
	const contactRef = useRef<Api.ContactViewModel>(null);

	contactRef.current = contact;
	const [isBusy, setIsBusy] = useState<boolean>(true);
	const isAdmin = userSession.account.isAdmin && userSession.account.features.automation.allowAdminToStartOnBehalf;

	useEffect(() => {
		if (contactId && userSession && contact?.id !== contactId) {
			setIsBusy(true);
			const contactViewModel = new Api.ContactViewModel(userSession, { id: contactId });
			setContact(contactViewModel);
			(async () => {
				try {
					const request = getCreateAutomationRequestForContacts(
						userSession,

						contactViewModel.id,
						selectedFrom,
						selectedEmployee,
						isAdmin
					);

					logEvent('LoadContactAndAutomationPreview', { templateId: automation.id });
					const [, automationPreviewOpResult] = await Promise.all([
						contactViewModel.load(),
						automation.previewStartForContacts(request),
					]);
					if (contactRef.current === contactViewModel) {
						setPreview(automationPreviewOpResult.value);
						setIsBusy(false);
					}
				} catch (error) {
					logApiError('LoadContactAndAutomationPreview-Error', error);

					errorMessages.pushApiError(error);
				}
			})();
		}
	}, [
		automation,
		contact,
		contactId,
		errorMessages,
		logApiError,
		logEvent,
		selectedEmployee,
		selectedFrom,
		userSession,
		isAdmin,
	]);

	const onUserSelected = (selection: Api.IUser) => {
		setSelectedEmployee(selection);
	};

	const onAutomationSelectionComesFrom = (type: Api.AutomationSelectionType) => {
		setSelectedFrom(type);
	};

	const handleSave = () => {
		if (!selectedEmployee && selectedFrom === Api.AutomationSelectionType.Employee) {
			return;
		}
		const request = getCreateAutomationRequestForContacts(
			userSession,
			contactId,
			selectedFrom,
			selectedEmployee,
			isAdmin
		);
		onRequestClose?.(request);
	};
	return (
		<article className={css(styleSheet.main)}>
			<AutomationConfirmationHeader automationTemplate={automation.toJs()} />
			{isAdmin ? (
				<>
					<h4 className={css(baseStyleSheet.truncateText, styleSheet.automationCardSubTitle)}>Automation steps by:</h4>
					<AutomationStepsBy
						userSession={userSession}
						preview={preview}
						isBusy={isBusy}
						onAutomationSelectionComesFrom={onAutomationSelectionComesFrom}
						onSelectedEmployee={onUserSelected}
					/>
				</>
			) : null}
			<footer className={css(styleSheet.footer)}>
				<Button
					kind='primary'
					label='Start Automation for Tagged Contact'
					disabled={!selectedEmployee && selectedFrom === Api.AutomationSelectionType.Employee}
					onClick={handleSave}
					style={{ marginRight: '8px' }}
				/>
				<Button kind='reverse' label='Skip' onClick={() => onRequestClose()} />
			</footer>
		</article>
	);
};

const AutomationFromModalAsObserver = observer(_AutomationFromModal);
const AutomationFromModalWithContext = inject(ModalChildComponentContextKey)(AutomationFromModalAsObserver);
const AutomationFromModalWithRouter = withRouter(AutomationFromModalWithContext);
const AutomationFromModalWithLogging = withEventLogging(AutomationFromModalWithRouter, 'AutomationFromModal');
export const AutomationFromModal = asModalComponent(AutomationFromModalWithLogging, {
	className: '',
	shouldCloseOnOverlayClick: true,
	useDefaultHeader: true,
});
