import { IContact, IRecipient, ResourceAutoCompleteViewModelType } from '@ViewModels';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { IUserSessionComponentProps, UserSessionViewModelKey } from '../../../../models/AppState';
import { isValidEmail } from '../../../../models/UiUtils';
import { ResourceAutoCompleteViewModel, VmUtils } from '../../../../viewmodels/AppViewModels';
import CCTokenRemoveIconUrl from '../../../assets/ccTokenRemove.svg';
import { Avatar } from '../../Avatar';
import { AutoCompleteDropdown, IAutoCompleteDropdownComponentProps } from '../../autocomplete/AutoCompleteDropdown';
import './styles.less';

interface IProps extends IUserSessionComponentProps {
	className?: string;
	onRecipientsChange?(recipients: IRecipient[]): void;
	recipients?: IRecipient[];
}

interface IState {
	inputHasFocus?: boolean;
	recipients?: ContactRecipient[];
}

type ContactRecipient = IRecipient & IContact;

class _EmailCCField extends React.Component<IProps, IState> {
	private dropdownElement: typeof AutoCompleteDropdown;

	private scrollToBottomAnchorRef: HTMLSpanElement;

	private searchViewModel: ResourceAutoCompleteViewModel<IContact>;
	public state: IState = {
		recipients: this.props.recipients || [],
	};

	public UNSAFE_componentWillMount() {
		this.searchViewModel = new ResourceAutoCompleteViewModel<IContact>(this.props.userSession, {
			filter: (x: IContact) => {
				const emailAddress =
					x.primaryEmail || (!!x.emailAddresses && x.emailAddresses.length > 0 ? x.emailAddresses[0] : null);
				return !!emailAddress && !!emailAddress.value;
			},
			pageSize: 25,
			type: ResourceAutoCompleteViewModelType.Contact,
		});

		this.setState({
			recipients: this.props.recipients || [],
		});
	}

	public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
		if (this.state.recipients !== nextProps.recipients) {
			this.setState({
				recipients: nextProps.recipients || [],
			});
		}
	}

	public render() {
		const autocompleteProps: Partial<IAutoCompleteDropdownComponentProps<IContact, ContactRecipient>> = {
			searchResultsTransformer: this.onTransformSearchResults,
		};
		return (
			<AutoCompleteDropdown
				{...autocompleteProps}
				anchor={this.anchorProvider}
				autoCompleteViewModel={this.searchViewModel}
				className='email-cc-field-dropdown'
				dropdownContentClassName='email-cc-field-dropdown-content'
				onItemSelected={this.onRecipientSelected}
				onRenderEmptyResults={this.onRenderEmptyResults}
				onRenderItem={this.onAutoCompleteItemRender}
				onRequestDeleteBackwards={this.onRequestDeleteBackwards}
				ref={this.onAutoCompleteDropdownRef}
				inputProps={{
					className: 'cc-field-token-container-text cc-field-token-container-input standardTextboxMed',
					onBlur: this.onToggleInputFocus(false),
					onFocus: this.onToggleInputFocus(true),
					onKeyDown: this.onInputKeyDown,
					style: {
						border: 'none',
						opacity: this.state.inputHasFocus ? 1 : 0,
					},
				}}
			/>
		);
	}

	private onScrollToBottomAnchorRef = (ref?: HTMLSpanElement) => {
		this.scrollToBottomAnchorRef = ref;
	};

	private onTransformSearchResults = (searchResults?: IContact[]): ContactRecipient[] => {
		return (searchResults || []).map(x => {
			// already checked for existance in autocomplete filter function

			const emailAddress = x.primaryEmail || x.emailAddresses[0];
			const recipient: ContactRecipient = {
				email: emailAddress.value,
				emailAddresses: x.emailAddresses || [],
				firstName: x.firstName,
				id: x.id,
				lastName: x.lastName,
				name: {
					firstName: x.firstName,
					lastName: x.lastName,
				},
				primaryEmail: x.primaryEmail,
				profilePic: x.profilePic,
			};
			return recipient;
		});
	};

	private onInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		switch (e.keyCode) {
			case 13: // enter
			case 188: // "."
			case 186: // ";"
			case 9: {
				// tab
				const text = (e.target as HTMLInputElement).value;
				// create token... if isDefaultPrevented() === true, the event was previously handled by the autocomplete dropdown
				if (!!text && !e.isDefaultPrevented()) {
					// assume text is an email
					if (isValidEmail(text)) {
						this.addTokenForEmail(text);
					} else {
						this.resetDropdownInputContent();
						setTimeout(() => {
							this.focus();
						});
					}
				}
				break;
			}
			default: {
				break;
			}
		}
	};

	private resetDropdownInputContent = () => {
		if (this.dropdownElement) {
			(this.dropdownElement as any).reset();
		}
	};

	private onToggleInputFocus = (inputHasFocus: boolean) => (e: React.FocusEvent<HTMLInputElement>) => {
		if (!inputHasFocus) {
			const text = (e.target as HTMLInputElement).value;
			if (!!text && isValidEmail(text)) {
				// create the token
				this.addTokenForEmail(text);
			}
		}
		this.setState({
			inputHasFocus,
		});
	};

	private addTokenForEmail = (email: string) => {
		const recipient: ContactRecipient = {
			email,
			primaryEmail: {
				value: email,
			},
		};
		this.onRecipientSelected(recipient);
		setTimeout(() => {
			this.focus();
		});
	};

	private onAutoCompleteDropdownRef = (ref: any) => {
		this.dropdownElement = ref;
	};

	private onRequestDeleteBackwards = () => {
		// test
	};

	private onRenderEmptyResults = () => {
		return <div className='email-cc-field-no-matches truncate-text'>No matching contact found.</div>;
	};

	private onAutoCompleteItemRender = (recipient: ContactRecipient, index: number, highlighted: boolean) => {
		// note: recipients here are full IContact
		const displayName = VmUtils.getDisplayName(recipient);
		return (
			<div
				className={`email-cc-field-recipient ${highlighted ? 'email-cc-field-recipient-highlighted' : ''}`}
				key={recipient.id || recipient.email || index}
				onMouseDown={this.onRecipientClicked(recipient)}
			>
				<Avatar className='email-cc-field-recipient-avatar' entity={recipient} />
				<div className='email-cc-field-recipient-details'>
					<div className='email-cc-field-recipient-details-name truncate-text' title={displayName}>
						{displayName}
					</div>
					{!!recipient.email && (
						<div className='email-cc-field-recipient-details-email truncate-text' title={recipient.email}>
							{recipient.email}
						</div>
					)}
				</div>
			</div>
		);
	};

	private anchorProvider = (inputElement: React.ReactElement<any>) => {
		const { className } = this.props;
		// note: recipients here can be full IContact or just a ContactRecipient with email
		return (
			<div className={`email-cc-field ${className || ''}`}>
				<div className='email-cc-field-content' onClick={this.onTokenContainerClicked}>
					<span className='email-cc-field-label'>Cc:</span>
					<div className='email-cc-field-tokens'>
						{!!this.state.recipients &&
							this.state.recipients.map((recipient, i) => {
								return (
									<div className='email-cc-field-token' key={recipient.email || i} onClick={this.onTokenClicked}>
										<span className='email-cc-field-token-text truncate-text'>{VmUtils.getDisplayName(recipient)}</span>
										<button
											className='email-cc-field-token-remove-button'
											onClick={this.onRemoveRecipientClicked(recipient)}
										>
											<img src={CCTokenRemoveIconUrl} />
										</button>
									</div>
								);
							})}
						{inputElement}

						<span ref={this.onScrollToBottomAnchorRef} />
					</div>
				</div>
			</div>
		);
	};

	private onTokenClicked = (e: React.MouseEvent<HTMLElement>) => {
		// prevent the input box from showing
		e.preventDefault();
		e.stopPropagation();
	};

	private onRemoveRecipientClicked = (recipient: ContactRecipient) => (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		e.stopPropagation();

		const { onRecipientsChange } = this.props;
		const recipients = [...(this.state.recipients || [])];
		recipients.splice(recipients.indexOf(recipient), 1);
		if (onRecipientsChange) {
			onRecipientsChange(recipients);
		} else {
			this.setState({
				recipients,
			});
		}
	};

	private scrollToBottom = () => {
		setTimeout(() => {
			if (this.scrollToBottomAnchorRef) {
				this.scrollToBottomAnchorRef.scrollIntoView();
			}
		}, 1);
	};

	private focus = () => {
		if (this.dropdownElement) {
			(this.dropdownElement as any).focus();

			this.scrollToBottom();
		}
	};

	private onTokenContainerClicked = () => {
		this.focus();
	};

	private onRecipientClicked = (recipient: ContactRecipient) => (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		e.stopPropagation();

		if (this.state.recipients && !this.state.recipients.some(x => x.id === recipient.id)) {
			this.onRecipientSelected(recipient);
		}
		this.resetDropdownInputContent();
	};

	private onRecipientSelected = (recipient: ContactRecipient) => {
		const { onRecipientsChange } = this.props;
		const recipients = [...(this.state.recipients || []), recipient];
		if (onRecipientsChange) {
			onRecipientsChange(recipients);
		} else {
			this.setState({
				recipients,
			});
		}
	};
}

const EmailCCFieldAsObserver = observer(_EmailCCField);
export const EmailCCField = inject(UserSessionViewModelKey)(EmailCCFieldAsObserver);
