import { ContactViewModel, EmailAddress, IOperationResultNoValue } from '@ViewModels';
import { css } from 'aphrodite';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { ErrorMessagesViewModelKey, IErrorMessageComponentProps } from '../../../../models/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '../../../../models/Logging';
import { getDefaultDateStringValue } from '../../../../models/UiUtils';
import { useToggle } from '../../../../models/hooks/useToggle';
import { DataOriginContainer } from '../../../containers/DataOriginContainer';
import { TinyPopover } from '../../TinyPopover';
import { EntityInfoBulletList } from '../../entities/EntityInfoBulletList';
import { DangerIcon } from '../../svgs/icons/DangerIcon';
import { styleSheet } from './styles';

interface IProps extends IErrorMessageComponentProps, IEventLoggingComponentProps {
	className?: string;
	compactLayout?: boolean;
	contact: ContactViewModel;
	info?: string;
}

interface IState {
	editItemIndex?: number;
	emailAddresses?: EmailAddress[];
	showEditNewItemInput?: boolean;
}

class _ContactInfoEmails extends React.Component<IProps, IState> {
	@observable private mEditableEmailAddress: EmailAddress;
	public readonly state: IState = {
		editItemIndex: -1,
	};

	public render() {
		const { className, contact, loggingCategory, compactLayout } = this.props;
		const { emailAddresses, editItemIndex, showEditNewItemInput } = this.state;
		return (
			<EntityInfoBulletList
				addButtonContent='Add email'
				className={`${css(styleSheet.container)} contact-info-emails ${className || ''}`}
				compactLayout={compactLayout}
				deleteConfirmationContent='Do you want to delete this email address?'
				editItemIndex={editItemIndex}
				editTextValue={this.mEditableEmailAddress ? this.mEditableEmailAddress.value : null}
				entity={contact}
				items={emailAddresses || contact.emailAddresses || []}
				loggingCategory={loggingCategory}
				onAddNewButtonClicked={this.onAddNewButtonClicked}
				onEditCanceled={this.onEditCanceled}
				onEditTextChanged={this.onEditTextChanged}
				onItemEditButtonClicked={this.onItemEditButtonClicked}
				onRemoveItem={this.onRemoveItem}
				onRenderItem={this.onRenderItem}
				onSaveEdit={this.onSaveEdit}
				showEditNewItemInput={showEditNewItemInput}
			/>
		);
	}

	private onRenderItem = (
		item: EmailAddress,
		index: number,
		bullet?: React.ReactNode,
		editButton?: React.ReactNode
	) => {
		const { contact } = this.props;
		const editing = !!this.mEditableEmailAddress && index === this.state.editItemIndex;

		let info = 'There is an issue with this address.';

		info += contact?.bounceMetadata?.date
			? ` Email bounced on ${getDefaultDateStringValue(contact?.bounceMetadata?.date)}`
			: ' Levitate previously received a bounced email notfication or was manually tagged.';

		const edit = !contact?.dataOriginEnabled ? editButton : null;

		const bounceHint =
			(contact.primaryEmail === item && contact.tags?.includes('Email Bounced')) ||
			(index === 0 && contact.tags?.includes('Email Bounced')) ? (
				<InfoHover info={info} />
			) : null;

		return (
			<>
				{bullet}
				<DataOriginContainer
					className={css(styleSheet.dataOriginContainer)}
					contact={contact}
					dataType='Email'
					email={item.value}
					iconPlacement='center'
				>
					<div className={`${css(styleSheet.email, !editing ? styleSheet.emailWithEdit : null)} truncate-text`}>
						<a
							className={`${css(styleSheet.emailLink)} truncate-text`}
							href={`mailto:${item.value}`}
							title={item.value}
						>
							{item.value}
						</a>
						{bounceHint}
					</div>
				</DataOriginContainer>
				{edit}
			</>
		);
	};

	@action
	private onEditTextChanged = (value: string) => {
		if (this.mEditableEmailAddress) {
			this.mEditableEmailAddress.value = value;
		}
	};

	@action
	private onRemoveItem = (item: EmailAddress) => {
		const nextState: IState = {
			editItemIndex: -1,
			showEditNewItemInput: false,
		};

		const { contact, logApiError, logEvent, errorMessages } = this.props;

		const emailAddresses = [...(contact.emailAddresses || [])];
		emailAddresses.splice(emailAddresses.indexOf(item), 1);

		const contactModel = { ...contact.toJs() };
		contactModel.emailAddresses = emailAddresses;

		// set override
		nextState.emailAddresses = emailAddresses;

		const promise = contact.update(contactModel);
		if (promise) {
			logEvent('RemoveEmail', { count: emailAddresses.length });
			promise
				.then(() => {
					this.setState({
						emailAddresses: null,
					});
				})
				.catch((error: IOperationResultNoValue) => {
					this.setState({
						emailAddresses: null,
					});

					errorMessages.pushApiError(error);

					logApiError('RemoveEmail-Error', error);
				});
		}

		this.mEditableEmailAddress = null;
		this.setState(nextState);
	};

	@action
	private onAddNewButtonClicked = () => {
		// need to init with null properties
		this.mEditableEmailAddress = {
			label: null,

			value: null,
		};
		this.setState({
			editItemIndex: -1,
			showEditNewItemInput: true,
		});
	};

	@action
	private onSaveEdit = () => {
		const nextState: IState = {
			editItemIndex: -1,
			showEditNewItemInput: false,
		};
		if (!!this.mEditableEmailAddress && !!this.mEditableEmailAddress.value) {
			const { contact, logApiError, logEvent, errorMessages } = this.props;
			const { editItemIndex } = this.state;
			const emailAddresses = [...(contact.emailAddresses || [])];

			if (editItemIndex >= 0) {
				// editing existing... replace

				emailAddresses.splice(editItemIndex, 1, this.mEditableEmailAddress);
			} else {
				emailAddresses.push(this.mEditableEmailAddress);
			}

			const contactModel = { ...contact.toJs() };
			contactModel.emailAddresses = emailAddresses;

			// set override
			nextState.emailAddresses = emailAddresses;

			const promise = contact.update(contactModel);
			if (promise) {
				logEvent('UpdateEmails', { count: emailAddresses.length });
				promise
					.then(() => {
						this.setState({
							emailAddresses: null,
						});
					})
					.catch((error: IOperationResultNoValue) => {
						this.setState({
							emailAddresses: null,
						});

						errorMessages.pushApiError(error);

						logApiError('UpdateEmails-Error', error);
					});
			}
		}

		this.mEditableEmailAddress = null;
		this.setState(nextState);
	};

	@action
	private onEditCanceled = () => {
		this.mEditableEmailAddress = null;
		this.setState({
			editItemIndex: -1,
			showEditNewItemInput: false,
		});
	};

	@action
	private onItemEditButtonClicked = (item: EmailAddress, index: number) => {
		this.mEditableEmailAddress = {
			...item,
		};
		this.setState({
			editItemIndex: index,
			showEditNewItemInput: false,
		});
	};
}

const ContactInfoEmailsAsObserver = observer(_ContactInfoEmails);
const ContactInfoEmailsWithContext = inject(ErrorMessagesViewModelKey)(ContactInfoEmailsAsObserver);
export const ContactInfoEmails = withEventLogging(ContactInfoEmailsWithContext, 'ContactInfoEmails');

interface IInfoProps {
	info?: string;
}

const InfoHover: React.FC<IInfoProps> = props => {
	const [isHovering, , setIsHovering] = useToggle(false);
	const { info } = props;
	return (
		<div onMouseEnter={setIsHovering(true)} onMouseLeave={setIsHovering(false)}>
			<TinyPopover
				anchor={<DangerIcon />}
				isOpen={isHovering}
				onRequestClose={setIsHovering(false)}
				align='center'
				placement={['right', 'bottom', 'left', 'top']}
			>
				<div className={css(styleSheet.infoMessage)}>{info}</div>
			</TinyPopover>
		</div>
	);
};
