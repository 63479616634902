import { ContactViewModel, RelationshipHealthStatus } from '@ViewModels';
import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { Component } from 'react';
import { IUserSessionComponentProps, UserSessionViewModelKey } from '../../../../models/AppState';
import { IEnvironment } from '../../../../models/Environment';
import { IEventLoggingComponentProps, withEventLogging } from '../../../../models/Logging';
import { SvgIcon } from '../../svgs/icons/SvgIcon';
import { styleSheet } from './styles';

interface IProps extends IEventLoggingComponentProps, IUserSessionComponentProps {
	className?: string;
	contact?: ContactViewModel;
	environment?: IEnvironment;
}

interface IState {
	contact?: ContactViewModel;
}

class _RelationshipHealthStatusBar extends Component<IProps, IState> {
	public readonly state: IState = {};

	public static getDerivedStateFromProps(props: IProps, state: IState) {
		const nextState: IState = {};
		if (props.contact !== state.contact) {
			nextState.contact = props.contact;
		}
		return Object.keys(nextState).length > 0 ? nextState : null;
	}

	public componentDidMount() {
		const nextState = _RelationshipHealthStatusBar.getDerivedStateFromProps(this.props, this.state);
		if (nextState) {
			this.setState(nextState);
		}
	}

	public render() {
		const { className, contact, environment } = this.props;
		if (!contact?.relationshipHealth) {
			return null;
		}

		const pluginLastInteraction = this.renderLastInteraction();
		if (environment.appType === 'plugin' && !pluginLastInteraction) {
			return null;
		}

		return (
			<div
				className={`relationship-health-status-bar ${css(
					styleSheet.container,
					environment.appType === 'plugin' && styleSheet.plugin
				)} ${className || ''}`}
			>
				<span className={css(styleSheet.backgroundSvg)}>
					<SvgIcon height={70} width={212}>
						<g fill='none' strokeWidth='3' transform='translate(-40 -1)'>
							<path
								stroke='#FFF'
								d='M0,100 C6.12041046,95.882865 28.6000778,80.7295899 67.439002,54.5401746 L67.4389998,54.5401713 C70.3844682,52.5540171 74.0585874,51.9750485 77.4721096,52.9591514 L104.333927,60.7032908 C108.047603,61.7739267 112.049625,60.9903444 115.085302,58.5982079 L150.65921,30.5657003 C154.954232,27.1811905 160.994205,27.1283926 165.347737,30.4373017 L185.095351,45.4465077 C189.653613,48.911022 196.024511,48.6727637 200.311271,44.8774641 L251,0'
								opacity='.106'
							/>
							<circle cx='191.5' cy='47.5' r='3.5' fill='#40BAF4' stroke='#56C2F6' />
							<circle cx='157.5' cy='27.5' r='3.5' fill='#40BAF4' stroke='#56C2F6' />
							<circle cx='109.5' cy='58.5' r='3.5' fill='#40BAF4' stroke='#56C2F6' />
							<circle cx='72.5' cy='52.5' r='3.5' fill='#40BAF4' stroke='#56C2F6' />
						</g>
					</SvgIcon>
				</span>
				{pluginLastInteraction}

				{environment.appType !== 'plugin' && (
					<div className={css(styleSheet.status)}>
						<div className={css(styleSheet.statusIndicator)}>
							<span>{`Status: ${this.getDescriptionForStatus(contact.relationshipHealth.status)}`}</span>
							<span
								className={css(
									styleSheet.statusIndicatorDot,
									this.getStyleForStatus(contact.relationshipHealth.status)
								)}
							/>
						</div>
					</div>
				)}
			</div>
		);
	}

	private renderLastInteraction() {
		const { contact } = this.props;
		const lastInteractionMoment = contact.relationshipHealth.lastInteractionDate
			? moment(contact.relationshipHealth.lastInteractionDate)
			: null;
		const lastInteractionInDays = lastInteractionMoment ? moment().diff(lastInteractionMoment, 'day') : -1;
		if (!!lastInteractionMoment && lastInteractionInDays >= 0) {
			return (
				<div className={css(styleSheet.lastInteraction)}>
					<div className={css(styleSheet.lastInteractionLabel)}>Last Interaction</div>
					<div className={css(styleSheet.lastInteractionTimeSpan)}>
						{lastInteractionInDays > 0
							? `${lastInteractionInDays} day${lastInteractionInDays > 1 ? 's' : ''} ago`
							: 'Within a day'}
					</div>
				</div>
			);
		}

		return null;
	}

	private getStyleForStatus = (status: RelationshipHealthStatus) => {
		switch (status) {
			case 'Healthy': {
				return styleSheet.statusIndicatorDotHealthy;
			}
			case 'Unhealthy': {
				return styleSheet.statusIndicatorDotUnhealthy;
			}
			default: {
				return styleSheet.statusIndicatorDotNeutral;
			}
		}
	};

	private getDescriptionForStatus = (status: RelationshipHealthStatus) => {
		switch (status) {
			case 'Healthy': {
				return 'Green';
			}
			case 'Unhealthy': {
				return 'Red';
			}
			default: {
				return 'Gray';
			}
		}
	};
}

const RelationshipHealthStatusBarAsObserver = observer(_RelationshipHealthStatusBar);
const RelationshipHealthStatusBarWithContext = inject(UserSessionViewModelKey)(RelationshipHealthStatusBarAsObserver);
export const RelationshipHealthStatusBar = withEventLogging(
	RelationshipHealthStatusBarWithContext,
	'RelationshipHealthStatusBar'
);
