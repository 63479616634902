import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { PortalDestination } from '../Portal';
import { ScrollView } from '../ScrollView';
import './styles.less';

export interface ITabbarItem<T = any> {
	className?: string;
	content?: React.ReactNode;
	dataContext?: T;
	header?: React.ReactNode;
	onClickRedirectionPath?: string;
	onSelect?(e: React.MouseEvent<HTMLElement>): void;
}

export interface ITabViewChild<TDataContext = any> {
	content?: React.ReactNode;
	tabbarItem: ITabbarItem<TDataContext>;
}

export interface ITabViewProps<TDataContext = any, TChildContext = any> {
	children?(context?: TChildContext): ITabViewChild[];
	className?: string;
	contentClassName?: string;
	hasFixedHeaders?: boolean;
	headerPortalDestinationId?: string;
	itemClassName?: string;
	onSelectedTabIndexChanged?(selectedIndex: number, tabbarItem?: ITabbarItem<TDataContext>): void;
	scrollViewClassName?: string;
	scrollViewContentClassName?: string;
	scrollViewId?: string;
	selectedItemClassName: string;
	selectedTabIndex?: number;
	styles?: StyleDeclarationValue[];
	selectedTabIndicator?: string;
	tabbarClassName?: string;
	tabbarTabsClassName?: string;
	/**
	 * If false, this control will render a flattened structure of tabbar items and content. If true, tabbar items and
	 * content will render in a ScrollView. default === true
	 */
	usesScrollView?: boolean;
}

interface IState {
	selectedTabbarItemIndex?: number;
}

export class TabView extends React.Component<ITabViewProps, IState> {
	public static defaultProps: Partial<ITabViewProps> = {
		usesScrollView: true,
	};

	public state: IState = {
		selectedTabbarItemIndex: this.props.selectedTabIndex || 0,
	};

	public UNSAFE_componentWillReceiveProps(nextProps: ITabViewProps) {
		const nextState: IState = {};

		if (nextProps.selectedTabIndex >= 0 && nextProps.selectedTabIndex !== this.state.selectedTabbarItemIndex) {
			nextState.selectedTabbarItemIndex = nextProps.selectedTabIndex;
		}

		this.setState(nextState);
	}

	public render() {
		const { children, className, contentClassName, styles, usesScrollView } = this.props;
		let selectedItem: ITabbarItem;
		const tabbarItems: ITabbarItem[] = (children ? children() : []).reduce((prev, curr, i) => {
			prev.push(curr.tabbarItem);
			if (i === this.state.selectedTabbarItemIndex) {
				selectedItem = curr;
			}
			return prev;
		}, []);
		const content = (
			<div className={`tab-view-content ${contentClassName || ''}`}>{selectedItem ? selectedItem.content : null}</div>
		);
		return (
			<div className={`${css(...(styles || []))} tab-view ${className || ''}`}>
				{usesScrollView ? (
					this.renderInScrollView(tabbarItems, content)
				) : (
					<>
						{this.renderTabbarHeader(tabbarItems)}
						{content}
					</>
				)}
			</div>
		);
	}

	private renderInScrollView(tabbarItems: ITabbarItem[], content: React.ReactNode) {
		const { hasFixedHeaders, scrollViewClassName, scrollViewContentClassName, scrollViewId } = this.props;
		return (
			<ScrollView
				scrollViewId={scrollViewId}
				className={`tab-view-scroll-view ${scrollViewClassName || ''}`}
				contentClassName={scrollViewContentClassName || ''}
				hasFixedHeader={hasFixedHeaders}
				header={this.renderTabbarHeader(tabbarItems)}
			>
				{content}
			</ScrollView>
		);
	}

	private renderTab(tabContent: React.ReactNode, selected: boolean) {
		if (React.isValidElement(tabContent)) {
			return React.cloneElement(tabContent as React.ReactElement, {
				selected,
			});
		}
		return tabContent;
	}

	private renderTabbarHeader(tabbarItems: ITabbarItem[], selectedItem?: ITabbarItem) {
		const {
			headerPortalDestinationId,
			itemClassName,
			selectedItemClassName,
			selectedTabIndicator,
			tabbarClassName,
			tabbarTabsClassName,
		} = this.props;
		const selectionIndicatorWidthAsPercent = tabbarItems.length > 0 ? 100.0 / tabbarItems.length : 0;
		const selectionIndicatorStyle: React.CSSProperties = {
			transform: `translateX(${100 * this.state.selectedTabbarItemIndex}%)`,
			width: `${selectionIndicatorWidthAsPercent}%`,
		};
		const header = selectedItem ? selectedItem.header : null;
		return (
			<React.Fragment>
				<div className={`tab-view-tabbar ${tabbarClassName || ''}`}>
					<menu className={`tab-view-tabbar-tabs ${tabbarTabsClassName ?? ''}`}>
						{tabbarItems.map((item, i) => {
							const className = `tab-view-tabbar-item ${item.className || ''} ${
								i === this.state.selectedTabbarItemIndex ? `tabbar-item-selected ${selectedItemClassName}` : ''
							} ${itemClassName || ''}`;
							const tab = this.renderTab(item.content, i === this.state.selectedTabbarItemIndex);
							if (item.onClickRedirectionPath) {
								return (
									<NavLink className={className} key={i} to={item.onClickRedirectionPath}>
										{tab}
									</NavLink>
								);
							}
							return (
								<button className={className} key={i} onClick={this.onTabbarItemClicked(item, i)}>
									{tab}
								</button>
							);
						})}
						{tabbarItems.length > 0 && (
							<div
								className={`tab-view-tabbar-selection-indicator ${selectedTabIndicator}`}
								style={selectionIndicatorStyle}
							/>
						)}
					</menu>
				</div>
				{headerPortalDestinationId ? (
					<PortalDestination id={headerPortalDestinationId}>{header}</PortalDestination>
				) : (
					header
				)}
			</React.Fragment>
		);
	}

	private onTabbarItemClicked = (item: ITabbarItem, index: number) => (e: React.MouseEvent<HTMLElement>) => {
		item.onSelect?.(e);
		if (!e.defaultPrevented) {
			const { onSelectedTabIndexChanged } = this.props;
			if (onSelectedTabIndexChanged) {
				// controlled case
				onSelectedTabIndexChanged(index, item);
			} else {
				this.setState({
					selectedTabbarItemIndex: index,
				});
			}
		}
	};
}
