import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { useErrorMessages } from '../../../../aida/hooks';
import { CardSize, IImpersonationContextComponentProps, ImpersonationContextKey } from '../../../../models';
import { CampaignType } from '../../../../models/AdminModels';
import { useEventLogging } from '../../../../models/Logging';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import {
	EmailCampaignBrowserViewModel,
	IScheduledSend,
	ITemplate,
	Industry,
	SocialMediaCampaignBrowserViewModel,
	TemplatesViewModel,
} from '../../../../viewmodels/AppViewModels';
import { NewCampaignButtonsDropDown } from '../../NewCampaignButtons';
import { CampaignSearchInputField } from '../CampaignSearchInputField';
import { CampaignsByCategorySelector, ICampaignsByCategorySelectorComponent } from '../CampaignsByCategorySelector';
import { styleSheet } from './styles';

interface IProps extends IImpersonationContextComponentProps {
	campaignBrowser?: EmailCampaignBrowserViewModel;
	cardSize?: CardSize;
	socialBrowser?: SocialMediaCampaignBrowserViewModel;
	cardCtaText?: string;
	dragDropProps?: {
		droppableId: string;
		type: string;
		isDragDisabled?: boolean;
		/**
		 * Styles to apply to the template card while dragging
		 */
		draggingStyles?: StyleDeclarationValue[];
	};
	onCampaignByCategorySelectorRef?(ref?: ICampaignsByCategorySelectorComponent): void;
	onCampaignTemplateClicked?(template: ITemplate, schedule?: IScheduledSend, referrer?: string): void;
	onSocialMediaTemplateClicked?(template: ITemplate, schedule?: IScheduledSend, referrer?: string): void;
	onTemplatesLoaded?(): void;
	retainIndustryOnClearSearch?: boolean;
	selectedTemplate?: ITemplate;
	onSetTemplateType?: (type: CampaignType) => void;
	showNewCampaignButtons?: boolean;
	styles?: StyleDeclarationValue[];
	templates?: TemplatesViewModel;
	useWholeCardAsCTA?: boolean;
	supportedCampaignTypes: CampaignType[];
}

export const _CampaignSearch: React.FC<IProps> = props => {
	const {
		campaignBrowser,
		cardSize,
		cardCtaText,
		dragDropProps,
		onCampaignByCategorySelectorRef,
		onCampaignTemplateClicked,
		onTemplatesLoaded,
		retainIndustryOnClearSearch,
		selectedTemplate,
		onSetTemplateType,
		showNewCampaignButtons,
		styles,
		socialBrowser,
		templates: propsTemplates,
		useWholeCardAsCTA,
		impersonationContext,
		supportedCampaignTypes,
	} = props;
	const userSession = useUserSession();

	const account = impersonationContext?.isValid ? impersonationContext.account : userSession?.account;
	const [industry, setIndustry] = React.useState(account?.additionalInfo?.industry);
	const [templateType, setTemplateType] = React.useState(CampaignType.Email);
	const [searchQuery, setSearchQuery] = React.useState('');
	const campaigns = React.useRef<EmailCampaignBrowserViewModel>(
		campaignBrowser || new EmailCampaignBrowserViewModel(userSession).impersonate(impersonationContext)
	).current;
	const socialCampaigns = React.useRef<SocialMediaCampaignBrowserViewModel>(
		socialBrowser || new SocialMediaCampaignBrowserViewModel(userSession).impersonate(impersonationContext)
	).current;

	const templates = React.useRef<TemplatesViewModel>(
		propsTemplates || new TemplatesViewModel(userSession).impersonate(campaigns.impersonationContext)
	).current;

	const errorMessages = useErrorMessages();
	const logger = useEventLogging('CampaignSearch');

	const onTemplateClicked = React.useCallback(
		async (templateId: string) => {
			try {
				const template = await templates.getByIdExpandedByLastUsedBy(templateId);
				onCampaignTemplateClicked?.(template);
			} catch (error) {
				errorMessages.pushApiError(error);

				logger.logApiError('TemplateLoad-Error', error);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[onCampaignTemplateClicked]
	);

	const onTemplateTypeSelected = (newType: CampaignType) => {
		setTemplateType(newType);
		onSetTemplateType?.(newType);
	};

	const onCampaignSearch = (query: string, newIndustry: Industry) => {
		setSearchQuery(query);
		switch (templateType) {
			case CampaignType.Email: {
				campaigns.search(query, newIndustry)?.catch(error => {
					errorMessages.pushApiError(error);
					logger.logApiError('CampaignsSearch-Error', error);
				});
				break;
			}
			case CampaignType.Social: {
				socialBrowser.search(query, newIndustry)?.catch(error => {
					errorMessages.pushApiError(error);
					logger.logApiError('SocialMediaCampaignsSearch-Error', error);
				});
				break;
			}
			default: {
				break;
			}
		}
	};

	return (
		<div className={css(styleSheet.container, ...styles)}>
			<div className={css(styleSheet.searchContainer, !showNewCampaignButtons && styleSheet.searchContainerNoButton)}>
				<CampaignSearchInputField
					onChange={onCampaignSearch}
					industry={industry}
					styles={[showNewCampaignButtons ? styleSheet.campaignSearch : undefined]}
				/>
				{showNewCampaignButtons ? (
					<NewCampaignButtonsDropDown supportedCampaignTypes={supportedCampaignTypes} />
				) : null}{' '}
			</div>
			<CampaignsByCategorySelector
				campaignBrowser={campaigns}
				cardSize={cardSize}
				supportedCampaignTypes={supportedCampaignTypes}
				socialBrowser={socialCampaigns}
				cardContainerStyles={[styleSheet.cardContainer]}
				cardCtaText={cardCtaText}
				dragDropProps={dragDropProps}
				isSearch={!!searchQuery}
				onCampaignTemplateClicked={onTemplateClicked}
				onIndustrySelected={setIndustry}
				onTemplateTypeSelected={onTemplateTypeSelected}
				onRef={onCampaignByCategorySelectorRef}
				onTemplatesLoaded={onTemplatesLoaded}
				retainIndustryOnClearSearch={retainIndustryOnClearSearch}
				selectedTemplate={selectedTemplate}
				selectedTemplateType={templateType}
				showIndustrySelector={true}
				styles={[styleSheet.campaignSelector]}
				templates={templates}
				useWholeCardAsCTA={useWholeCardAsCTA}
			/>
		</div>
	);
};

const CampaignSearchAsObserver = observer(_CampaignSearch);
export const CampaignSearch = inject(ImpersonationContextKey)(CampaignSearchAsObserver);
