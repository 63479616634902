import * as Api from '@ViewModels';
import { IAutorunOptions, IReactionDisposer, IReactionPublic, autorun, observe } from 'mobx';
import * as React from 'react';

/**
 * Wrapper around mobx autorun
 *
 * @param view Passthrough function to mobx
 * @param deps UseEffect deps
 * @param opts Passthrough options to mobx
 */
export const useAutorun = (
	view: (r: IReactionPublic) => any,
	deps: React.DependencyList = [],
	opts?: IAutorunOptions
) => {
	const disposerRef = React.useRef<IReactionDisposer>(null);

	// calls/clears the mobx disposer
	const wrappedDisposerRef = React.useRef<() => void>(() => {
		disposerRef.current?.();

		disposerRef.current = null;
	});

	React.useEffect(() => {
		disposerRef.current = autorun(view, opts);
		return wrappedDisposerRef.current;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [view, opts, ...deps]);
	return wrappedDisposerRef;
};

/**
 * Create a self-disposable, run-once, listener for a vm's "isLoaded" prop.
 *
 * @param viewModel Vm to observe
 * @param onFirstLoad Function to call when "isLoaded" prop first switches from "false" to "true"
 * @param deps Defaults to [viewModel] if not provided. If supplied, caller is fully responsible for contents.
 */
export const useOnFirstLoad = <T extends Api.ViewModel = Api.ViewModel>(
	viewModel: T,
	onFirstLoad: () => void,
	deps?: React.DependencyList
) => {
	const onFirstLoadDisposerRef = React.useRef<() => void>(null);
	const dispose = () => {
		if (onFirstLoadDisposerRef.current) {
			onFirstLoadDisposerRef.current();

			onFirstLoadDisposerRef.current = null;
		}
	};
	React.useEffect(
		() => {
			onFirstLoadDisposerRef.current = observe(viewModel, 'isLoaded', ({ newValue, oldValue }) => {
				if (newValue && !oldValue) {
					onFirstLoad();
					dispose();
				}
			});
			return dispose;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		deps || [viewModel]
	);
};
