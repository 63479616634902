import { css } from 'aphrodite';
import * as React from 'react';
import { SizeConstraint } from '../../../models';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	date?: string;
	dateAccessory?: React.ReactNode;
	data?: string;
	elementRef?(ref?: HTMLElement): void;
	iconSrc?: string;
	onClick?(e: React.MouseEvent<HTMLElement>): void;
	onMouseEnter?(e: React.MouseEvent<HTMLElement>): void;
	onMouseLeave?(e: React.MouseEvent<HTMLElement>): void;
	middleAccessory?: React.ReactNode;
	rightAccessory?: React.ReactNode;
	sizeConstraint?: SizeConstraint;
}

export class ActivityListItem extends React.PureComponent<IProps> {
	public render() {
		const {
			sizeConstraint,
			className,
			iconSrc,
			date,
			dateAccessory,
			middleAccessory,
			rightAccessory,
			children,
			elementRef,
			onMouseEnter,
			onMouseLeave,
			onClick,
			data,
		} = this.props;
		const isCompact = sizeConstraint === 'normal';

		return (
			<li
				className={`${css(styleSheet.activityListItem, isCompact && styleSheet.compactItem)} ${className || ''}`}
				onClick={onClick}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				ref={elementRef}
				data-id={data}
			>
				{!!iconSrc && (
					<img
						className={css(isCompact ? styleSheet.activityListItemIconCompact : styleSheet.activityListItemIcon)}
						src={iconSrc}
					/>
				)}
				<div
					className={css(isCompact ? styleSheet.activityListItemContentCompact : styleSheet.activityListItemContent)}
				>
					<div className={css(styleSheet.activityListItemContentHeader)}>
						<span className={css(styleSheet.activityListItemContentDate)}>
							{date}
							{dateAccessory}
						</span>
						{middleAccessory}
						{rightAccessory}
					</div>
					{children}
				</div>
			</li>
		);
	}
}
