import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import {
	BioPlaceholder,
	CityPlaceholder,
	CompanyNamePlaceholder,
	FirstNamePlaceholder,
	FullNamePlaceholder,
	LastNamePlaceholder,
	SenderEmailPlaceholder,
	SenderFirstNamePlaceholder,
	SenderFullNamePlaceholder,
	SenderPhonePlaceholder,
	StatePlaceholder,
} from '../../../../models/Token';
import { ComposeEmailViewModel } from '../../../../viewmodels/AppViewModels';
import { FirstNamePlaceholderInfo } from '../../richContent/RichContentDocumentEditor/FirstNamePlaceholderInfo';
import { styleSheet } from './styles';

export interface IPlaceholderPopoverProps {
	coordinate: { x: number; y: number; documentBounds?: DOMRect };
	mutationObserver?: MutationObserver;
	placeholderValue: string;
	customFields?: Api.IFormResponse<string>;
}

const birthdayRegExp = new RegExp(/Birthday/i);

const PlaceholdersWithoutSubstitutions = [
	LastNamePlaceholder.value,
	BioPlaceholder.value,
	SenderFirstNamePlaceholder.value,
	SenderFullNamePlaceholder.value,
	SenderPhonePlaceholder.value,
	SenderEmailPlaceholder.value,
	FullNamePlaceholder.value,
];

const getPlaceholderSubstitution = (value: string) => {
	switch (value) {
		case CompanyNamePlaceholder.value: {
			return 'Company Name';
		}
		case CityPlaceholder.value: {
			return 'City';
		}
		case StatePlaceholder.value: {
			return 'State';
		}
		default: {
			return null;
		}
	}
};

function MergeFieldPopOverMessageContent({ header, body }: { header: string; body: string }) {
	return (
		<div className={css(styleSheet.mergeFieldPopoverContent)}>
			<div className={css(styleSheet.mergeFieldPopoverContentHeader)}>{header}</div>
			<div className={css(styleSheet.mergeFieldPopoverBody)}>{body}</div>
		</div>
	);
}

export function MergeFieldPopOverMessage({
	dismiss,
	emailComposer,
	individualRecipient,
	popoverProps,
}: {
	dismiss(): void;
	emailComposer: ComposeEmailViewModel;
	individualRecipient?: Api.ContactViewModel;
	popoverProps?: IPlaceholderPopoverProps;
}) {
	const subPlaceholderName = getPlaceholderSubstitution(popoverProps?.placeholderValue);

	const customField = popoverProps?.customFields?.fields?.find(
		x => `contact.customProperties.${x.name}` === popoverProps?.placeholderValue
	);
	if (
		popoverProps?.placeholderValue === FirstNamePlaceholder.value ||
		customField?.mergeFieldFallback === FirstNamePlaceholder.symbol
	) {
		return (
			<FirstNamePlaceholderInfo
				isBirthdayTemplate={birthdayRegExp.test(emailComposer?.emailMessage?.sendEmailRoute)}
				contact={
					emailComposer?.selectedCustomEmail ? emailComposer?.selectedCustomEmailContact : individualRecipient ?? null
				}
				onFinishClicked={dismiss}
			/>
		);
	} else if (subPlaceholderName) {
		return (
			<MergeFieldPopOverMessageContent
				body={`If the ${subPlaceholderName} is lacking we will replace ${subPlaceholderName} with "your ${subPlaceholderName.toLowerCase()}".`}
				header='Smart Field'
			/>
		);
	} else if (PlaceholdersWithoutSubstitutions.includes(popoverProps?.placeholderValue)) {
		return (
			<MergeFieldPopOverMessageContent
				body='When using this merge field, there will be no substitute if the contact is missing this info.'
				header='No Fallback Value'
			/>
		);
	} else if (customField) {
		return (
			<MergeFieldPopOverMessageContent
				body='When using this merge field, there will be no substitute if the contact is missing this info'
				header='Custom Field'
			/>
		);
	}

	return null;
}
