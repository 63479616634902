import { css } from 'aphrodite';
import * as React from 'react';
import { ActionItemCheckboxIcon } from '../../svgs/icons/ActionItemCheckboxIcon';
import { styleSheet } from './styles';

export interface IProps extends React.AllHTMLAttributes<HTMLInputElement> {
	fillColor?: string;
	checkboxClassName?: string;
	className?: string;
	id: string;
	rootElementRef?(ref?: HTMLElement): void;
}

export const ActionItemCheckbox: React.FC<IProps> = props => {
	const { className, checkboxClassName, id, children, rootElementRef, fillColor, ...restProps } = props;
	return (
		<label
			ref={rootElementRef}
			htmlFor={props.id}
			className={`${css(styleSheet.container)} action-item-checkbox ${props.className || ''}`}
		>
			<input
				{...restProps}
				className={`${css(styleSheet.input)} action-item-checkbox-input ${props.checkboxClassName || ''}`}
				id={props.id}
				type='checkbox'
			/>
			<ActionItemCheckboxIcon fillColor={fillColor} />
			<span>{children}</span>
		</label>
	);
};
