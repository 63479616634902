import { ContactViewModel, IKeyFact } from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { IUserSessionComponentProps } from '../../../../models/AppState';
import { IEventLoggingComponentProps, useEventLogging } from '../../../../models/Logging';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { ContactHistory } from '../../../containers/People/Contact/Activity/History';
import { ITabViewChild, ITabbarItem, TabView } from '../../TabView';
import { EditableContact } from '../../contacts/EditableContact';
import { EntityAnalysis } from '../../entities/EntityAnalysis';
import { EntityRichContent } from '../../entities/EntityRichContent';
import { styleSheet } from './styles';
import './styles.less';

interface IProps extends IUserSessionComponentProps, IEventLoggingComponentProps {
	className?: string;
	contact: ContactViewModel;
	initialEdit?: boolean;
	keyFactToHighlight?: IKeyFact;
	onContactEditButtonClicked?(contact: ContactViewModel): void;
	onContactUpdated?(contact: ContactViewModel): void;
	styles?: StyleDeclarationValue[];
}

function _ContactContextTabView({
	contact,
	className,
	styles,
	initialEdit,
	onContactEditButtonClicked,
	onContactUpdated,
	keyFactToHighlight,
}: IProps) {
	const { logEvent } = useEventLogging('ContactContextTabView');
	const userSession = useUserSession();
	const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
	const tabbarItems = React.useMemo(() => {
		const _tabbarItems: ITabbarItem<string>[] = [
			{
				content: 'Profile',
				dataContext: 'Profile',
			},
			{
				content: 'Notes',
				dataContext: 'RichContent',
			},
		];
		const canViewContactHistory =
			!!userSession.user.emailProviderFeatures.calendar.canViewHistoryByContact ||
			!!userSession.user.emailProviderFeatures.email.canViewHistoryByContact;
		if (canViewContactHistory) {
			if (userSession.account.planDetails.planId > 0) {
				_tabbarItems.push({
					content: 'Analysis',
					dataContext: 'Analysis',
				});
			} else {
				_tabbarItems.push({
					content: 'History',
					dataContext: 'History',
				});
			}
		}

		return _tabbarItems;
	}, [userSession]);
	const onSelectedTabIndexChanged = (selectedIndex: number) => {
		setSelectedTabIndex(selectedIndex);
	};
	const onShowRelationshipAnalysisButtonClicked = () => {
		const index = tabbarItems.findIndex(x => x.dataContext === 'Analysis');
		if (index >= 0) {
			setSelectedTabIndex(index);
		}
	};
	React.useEffect(() => {
		if (contact && !contact.isLoading) {
			contact
				.load()
				?.then(() => {
					logEvent('ContactLoad');
				})
				?.catch(error => {
					logEvent('ContactLoad-Error', { ...error });
				});
		}
	}, [contact, logEvent]);
	const getContactTabViewContent = (): ITabViewChild[] => {
		const tabs = tabbarItems.map<ITabViewChild>(x => {
			switch (x.dataContext) {
				case 'Profile': {
					return {
						content: (
							<EditableContact
								className={css(styleSheet.contactInfo)}
								compactLayout={true}
								contact={contact}
								disableCompanyLink={true}
								showSendMessage={false}
								showHwcActionButton={false}
								initialEdit={initialEdit}
								keyFactToHighlight={keyFactToHighlight}
								onContactEditButtonClicked={onContactEditButtonClicked}
								onContactUpdated={onContactUpdated}
								onShowRelationshipAnalysisButtonClicked={onShowRelationshipAnalysisButtonClicked}
								scrollToTopOffset={-35}
							/>
						),
						tabbarItem: x,
					};
				}
				case 'RichContent': {
					return {
						content: (
							<EntityRichContent
								className='contact-context-tabview-entity-notes'
								entity={contact}
								readonly={true}
								sendMessageDisabled={true}
								sizeConstraint='compact'
							/>
						),
						tabbarItem: x,
					};
				}
				case 'Analysis': {
					return {
						content: <EntityAnalysis entity={contact} sizeConstraint='compact' />,
						tabbarItem: x,
					};
				}
				case 'History': {
					return {
						content: (
							<ContactHistory
								className='contact-context-tabview-history'
								contactId={contact?.id}
								contactName={contact?.name}
								userSession={userSession}
							/>
						),
						tabbarItem: x,
					};
				}
				default: {
					break;
				}
			}
		});
		return tabs;
	};
	return (
		<TabView
			className={`contact-context-tabview ${className || ''} ${css(...(styles || []))}`}
			hasFixedHeaders={true}
			onSelectedTabIndexChanged={onSelectedTabIndexChanged}
			selectedTabIndex={selectedTabIndex}
			scrollViewId='contact-context-tabview-content'
		>
			{getContactTabViewContent}
		</TabView>
	);
}

export const ContactContextTabView = observer(_ContactContextTabView);
