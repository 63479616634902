import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { Industry } from '../../../../extViewmodels';
import { getIndustries, getIndustryName } from '../../../../models/UiUtils';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import {
	useHandwrittenCardCategories,
	useHandwrittenCardCategoryTemplates,
	useHandwrittenCardSearchTemplates,
	useMyTemplates,
} from '../../../../queries';
import { KnownCategories } from '../../../../viewmodels/AppViewModels';
import { baseStyleSheet } from '../../../styles/styles';
import { LoadingSpinner } from '../../LoadingSpinner';
import { Modal } from '../../Modal';
import { ISelectOption, Select } from '../../Select';
import { HandwrittenFoldedCardThumbnail } from '../HandwrittenfoldedCardThumbnail';
import { SearchField } from './components/SearchField';
import { styleSheet } from './styles';

const getIndustryOptions = (userAccountId: string) => {
	return getIndustries({ accountId: userAccountId }).map(industry => {
		const industryName = getIndustryName(industry);
		return {
			dataContext: industry,
			id: `industry-option-${industryName}`,
			text: industryName,
		};
	});
};

const SEARCH_DELAY = 600;
export const HandwrittenCardTemplateSearchModal = ({
	isOpen,
	onClose,
	onTemplateClicked,
}: {
	isOpen: boolean;
	onClose: () => void;
	onTemplateClicked: (template: Api.IHandwrittenCardTemplate) => void;
}) => {
	const userSession = useUserSession();

	const industryOptions = getIndustryOptions(userSession.account.id);
	const [selectedIndustry, setSelectedIndustry] = React.useState<ISelectOption<Industry>>(industryOptions[0]);
	const [searchValue, setSearchValue] = React.useState('');
	const [debouncedSearchValue, setDebouncedSearchValue] = React.useState(searchValue);
	React.useEffect(() => {
		const timeout = setTimeout(() => {
			setDebouncedSearchValue(searchValue.trim());
		}, SEARCH_DELAY);
		return () => clearTimeout(timeout);
	}, [searchValue]);
	const searchTemplatesQuery = useHandwrittenCardSearchTemplates({
		enabled: !!debouncedSearchValue,
		industry: selectedIndustry.dataContext,
		search: debouncedSearchValue,
	});
	const categoryQuery = useHandwrittenCardCategories({
		enabled: !!selectedIndustry.dataContext,
		industry: selectedIndustry.dataContext,
		withMyTemplates: true,
	});
	const categoryOptions: ISelectOption<string>[] = React.useMemo(() => {
		return categoryQuery.data
			? categoryQuery.data.map(category => ({
					dataContext: category,
					id: `hwc-category-option-${category}`,
					text: category,
				}))
			: [];
	}, [categoryQuery.data]);
	const [selectedCategoryOption, setSelectedCategoryOption] = React.useState<ISelectOption<string>>(
		categoryOptions.length > 0 ? categoryOptions[0] : null
	);
	// Track category options to reset selected category when options change
	const [prevCategoryOptions, setPrevCategoryOptions] = React.useState(categoryOptions);
	if (prevCategoryOptions !== categoryOptions) {
		setPrevCategoryOptions(categoryOptions);
		setSelectedCategoryOption(categoryOptions[0]);
	}
	const selectedCategory = selectedCategoryOption?.dataContext;
	const cardTemplatesQuery = useHandwrittenCardCategoryTemplates({
		category: selectedCategory,
		enabled: !!selectedCategory && selectedCategory !== KnownCategories.MyTemplates,
		industry: selectedIndustry.dataContext,
	});
	const myTemplatesQuery = useMyTemplates<Api.IHandwrittenCardTemplate>({
		enabled: selectedCategory === KnownCategories.MyTemplates,
		industry: selectedIndustry.dataContext,
		templateType: Api.TemplateType.HandwrittenCard,
	});
	const isSearching = !!debouncedSearchValue;
	// Show loading status if either the search or category query is loading
	const isLoading =
		(isSearching && searchTemplatesQuery.isLoading) ||
		(!isSearching && selectedCategory === KnownCategories.MyTemplates && myTemplatesQuery.isLoading) ||
		(!isSearching && selectedCategory !== KnownCategories.MyTemplates && cardTemplatesQuery.isLoading);
	const isEmpty =
		(isSearching && searchTemplatesQuery.status === 'success' && searchTemplatesQuery.data.length === 0) ||
		(!isSearching &&
			selectedCategory === KnownCategories.MyTemplates &&
			myTemplatesQuery.status === 'success' &&
			myTemplatesQuery.data.length === 0) ||
		(!isSearching &&
			selectedCategory !== KnownCategories.MyTemplates &&
			cardTemplatesQuery.status === 'success' &&
			cardTemplatesQuery.data.length === 0);
	let cardList: Api.IHandwrittenCardTemplate[] = [];
	if (isSearching && searchTemplatesQuery.status === 'success') {
		cardList = searchTemplatesQuery.data;
	} else if (
		!isSearching &&
		selectedCategory === KnownCategories.MyTemplates &&
		myTemplatesQuery.status === 'success'
	) {
		cardList = myTemplatesQuery.data;
	} else if (!isSearching && cardTemplatesQuery.status === 'success') {
		cardList = cardTemplatesQuery.data;
	}
	const renderSelectedOptionTitle = (label: string, selectedValue: string) => (
		<>
			<span className={css(styleSheet.triggerLabel)}>{label}:</span>
			<span className={css(styleSheet.triggerText)}>{selectedValue}</span>
		</>
	);
	const handleChangeIndustry = (industry: ISelectOption<Industry>) => {
		setSelectedIndustry(industry);

		setSelectedCategoryOption(null);
	};
	return (
		<Modal shouldCloseOnOverlayClick={false} useDefaultHeader isOpen={isOpen} onRequestClose={onClose}>
			<div className={`${css(styleSheet.container)} campaigns-by-category-selector`}>
				<header className={css(styleSheet.header)}>Select Card to Send</header>
				<SearchField
					onChange={ev => setSearchValue(ev.target.value)}
					onClickToClear={() => setSearchValue('')}
					placeholder='Search card type or keywords'
					value={searchValue}
				/>
				<Select
					onOptionClick={handleChangeIndustry}
					options={industryOptions}
					selectedOption={selectedIndustry}
					selectedOptionTitle={renderSelectedOptionTitle('Industry', selectedIndustry.text)}
					styles={[styleSheet.field]}
					triggerStyles={[styleSheet.trigger]}
				/>
				<Select
					onOptionClick={option => setSelectedCategoryOption(option)}
					options={categoryOptions}
					selectedOption={selectedCategoryOption}
					selectedOptionTitle={renderSelectedOptionTitle('Category', selectedCategoryOption?.text)}
					styles={[styleSheet.field, debouncedSearchValue ? styleSheet.fieldDisabled : null]}
					triggerStyles={[styleSheet.trigger]}
					disabled={!!debouncedSearchValue}
				/>
				{isLoading ? (
					<div className={css(styleSheet.loadingContainer)}>
						<LoadingSpinner />
					</div>
				) : null}
				{isEmpty ? <p className={css(styleSheet.cardListEmpty)}>Oops... No cards were found.</p> : null}
				<div className={css(styleSheet.cardListContainer)}>
					{cardList.map(card => {
						return (
							<div key={card.id} className={css(styleSheet.cardListItem)}>
								{/* Replace with HandwrittenFoldedCardThumbnail for folded cards */}
								<HandwrittenFoldedCardThumbnail
									src={card.thumbnail.publicUrl}
									alt={card.name}
									styleDeclaration={styleSheet.cardListItemImage}
								/>
								<h3 className={css(styleSheet.cardListItemTitle)}>{card.name}</h3>
								<footer className={css(styleSheet.cardListItemCta)}>
									<button className={css(baseStyleSheet.ctaButtonReverseSmall)} onClick={() => onTemplateClicked(card)}>
										Choose Card
									</button>
								</footer>
							</div>
						);
					})}
				</div>
			</div>
		</Modal>
	);
};
