import { css } from 'aphrodite';
import * as React from 'react';
import { grayIconFill } from '../../styles/colors';
import { HoverCard, HoverCardContent, HoverCardPortal, HoverCardTrigger } from '../HoverCard';
import { styleSheet } from './styles';
import './styles.less';

interface IProps<T = any> {
	animateDots?: boolean;
	className?: string;
	disabled?: boolean;
	menuButtonClassName?: string;
	menuItems: IMoreMenuItem<T>[];
	onMenuItemClicked?(menuItem: IMoreMenuItem<T>, e: React.MouseEvent<HTMLElement>): void;
}

export interface IMoreMenuItem<T = any> {
	key?: string;
	name: string;
	representedObject?: T;
}

export function DeprecatedMoreMenu({
	animateDots,
	menuItems,
	disabled,
	menuButtonClassName,
	className,
	onMenuItemClicked: onMenuItemClickedProp,
}: IProps) {
	const [open, setOpen] = React.useState(false);
	const onMenuItemClicked = (menuItem: IMoreMenuItem<any>) => (e: React.MouseEvent<HTMLElement>) => {
		setOpen(false);
		onMenuItemClickedProp?.(menuItem, e);
	};
	return (
		<HoverCard openDelay={0} open={!disabled && open} onOpenChange={setOpen}>
			<HoverCardTrigger asChild>
				<button className={`more-menu-trigger ${menuButtonClassName || ''}`} disabled={disabled} tabIndex={0}>
					<svg xmlns='http://www.w3.org/2000/svg' width='18' height='12' viewBox='0 0 18 4'>
						<g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
							<g fill={grayIconFill} className='more-menu-trigger-dots'>
								<path
									className={css(styleSheet.dot0, animateDots ? styleSheet.jump : null)}
									d='M4,2 C4,3.106 3.104,4 2,4 C0.895,4 0,3.106 0,2 C0,0.895 0.895,0 2,0 C3.104,0 4,0.895 4,2'
								/>
								<path
									className={css(styleSheet.dot1, animateDots ? styleSheet.jump : null)}
									d='M11,2 C11,3.106 10.104,4 9,4 C7.895,4 7,3.106 7,2 C7,0.895 7.895,0 9,0 C10.104,0 11,0.895 11,2'
								/>
								<path
									className={css(styleSheet.dot2, animateDots ? styleSheet.jump : null)}
									d='M18,2 C18,3.106 17.104,4 16,4 C14.895,4 14,3.106 14,2 C14,0.895 14.895,0 16,0 C17.104,0 18,0.895 18,2'
								/>
							</g>
						</g>
					</svg>
				</button>
			</HoverCardTrigger>
			<HoverCardPortal>
				<HoverCardContent side='bottom' className={`more-menu ${className || ''}`}>
					<div className='more-menu-content'>
						<ul>
							{menuItems
								.filter(x => !!x)
								.map((item, i) => {
									return (
										<li
											key={item.key ?? i}
											onClick={onMenuItemClicked(item)}
											tabIndex={0}
											role='button'
											aria-label={item.name}
											className='more-menu-content-item'
										>
											{item.name}
										</li>
									);
								})}
						</ul>
					</div>
				</HoverCardContent>
			</HoverCardPortal>
		</HoverCard>
	);
}
