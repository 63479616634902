import * as Api from '@ViewModels';
import equal from 'fast-deep-equal';
import * as React from 'react';
import { useClassifiedContactPolicies } from '../queries';
import { ComposeEmailViewModel, ComposeResourceEmailViewModel } from '../viewmodels/AppViewModels';
import { useEventLogging } from './Logging';
import { useAutorun } from './hooks/mobxHooks';
import { useContextGuard } from './hooks/useContextGuard';

export type LinesOfBusinessSelection = boolean | { label: string; value: string }[];
export type ClassifiedLinesOfBusinessSelection = Record<string, LinesOfBusinessSelection>;

export interface IRenewalEmailContext {
	classifiedPolicies?: Api.IClassifiedPolicies;
	isFetchingPolicies?: boolean;
	onSelectedPoliciesChanged?(selectedPolicies: string[]): void;
	selectedPolicies?: string[];
}

export const RenewalEmailContext = React.createContext<IRenewalEmailContext>({});

export function RenewalEmailContextProvider<T extends ComposeResourceEmailViewModel = ComposeResourceEmailViewModel>({
	children,
	emailComposer,
	eventLoggingCategory,
}: React.PropsWithChildren<{
	emailComposer?: T;
	eventLoggingCategory: string;
}>) {
	const { logEvent, logApiError } = useEventLogging(eventLoggingCategory);

	const [contactsRequest, setContactsRequest] = React.useState<Api.IResourceSelectorContactsByOwnerRequest>(
		emailComposer?.resourceSelectorContactPoliciesRequest
	);

	const [selectedPolicies, setSelectedPolicies] = React.useState<string[]>(emailComposer?.selectedPolicies || []);

	const [resourceSelectorId, setResourceSelectorId] = React.useState<string | undefined>(
		emailComposer?.resourceSelectorId
	);

	useAutorun(() => {
		const request = emailComposer?.resourceSelectorContactPoliciesRequest;
		if (!equal(request, contactsRequest)) {
			setContactsRequest(request);
		}

		if (emailComposer) {
			if (!equal(selectedPolicies, emailComposer.selectedPolicies)) {
				setSelectedPolicies(emailComposer.selectedPolicies || []);
			}
			if (!equal(resourceSelectorId, emailComposer.resourceSelectorId)) {
				setResourceSelectorId(emailComposer.resourceSelectorId);
			}
		}
	}, [emailComposer]);

	const { data: classifiedPolicies, isFetching: isFetchingPolicies } = useClassifiedContactPolicies({
		contactsRequest,
		enabled: Boolean(contactsRequest) && Boolean(resourceSelectorId === Api.ResourceSelectorId.PolicyRenew),
	});

	const onSelectedPoliciesChanged = React.useCallback(
		(policies: string[]) => {
			setSelectedPolicies(policies);
			if (emailComposer) {
				const [contactsFetchPromise, approximationPromise] = emailComposer.setSelectedPolicies(policies);

				if (contactsFetchPromise) {
					logEvent('ContactsLoad');
					contactsFetchPromise.catch((error: Api.IOperationResultNoValue) => {
						logApiError('ContactsLoad-Error', error);
					});
				}

				if (approximationPromise) {
					logEvent('HasEmailApproximationLoad');
					approximationPromise.catch((error: Api.IOperationResultNoValue) => {
						logApiError('HasEmailApproximationLoad-Error', error);
					});
				}
			}
		},
		[emailComposer, logApiError, logEvent]
	);

	// reset selection to all whenever we refetch policies
	React.useEffect(() => {
		if (!isFetchingPolicies) {
			const selectAll = Object.values(classifiedPolicies?.linesOfBusiness || [])
				.flat()
				.map(x => x.value);
			onSelectedPoliciesChanged(selectAll);
		}
	}, [onSelectedPoliciesChanged, classifiedPolicies, isFetchingPolicies]);

	const context = React.useMemo(
		() => ({
			classifiedPolicies,
			isFetchingPolicies,
			onSelectedPoliciesChanged,
			selectedPolicies,
		}),
		[classifiedPolicies, isFetchingPolicies, onSelectedPoliciesChanged, selectedPolicies]
	);
	return <RenewalEmailContext.Provider value={context}>{children}</RenewalEmailContext.Provider>;
}

export const useRenewalEmailContext = () => useContextGuard(RenewalEmailContext, 'RenewalEmailContext');

export interface IEmailComposerContext<T extends ComposeEmailViewModel = ComposeEmailViewModel> {
	emailComposer?: T;
	flow?: string;
	sendTestEmail?(): Promise<Api.IOperationResult<Api.ISendEmailResponse>>;
}

export const EmailComposerContext = React.createContext<IEmailComposerContext>(null);

export const useEmailComposerContext = <
	TFollowUpOptions extends Api.IFollowUpOptions = Api.IFollowUpOptions,
	TFollowUpSource = any,
	T extends ComposeEmailViewModel<TFollowUpOptions, TFollowUpSource> = ComposeEmailViewModel<
		TFollowUpOptions,
		TFollowUpSource
	>,
>() => useContextGuard(EmailComposerContext, 'EmailComposerContext') as IEmailComposerContext<T>;

export interface IEmailComposerContextChildProps {
	emailComposerContext?: IEmailComposerContext;
}

export function withEmailComposerContext<T = any>(WrappedComponent: React.ComponentType<T>) {
	const sfc: React.FC<T & IEmailComposerContextChildProps> = props => {
		return (
			<EmailComposerContext.Consumer>
				{context => {
					return <WrappedComponent emailComposerContext={context} {...props} />;
				}}
			</EmailComposerContext.Consumer>
		);
	};
	return sfc;
}
