import { ContactViewModel } from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { getDefaultDateStringValue, getDefaultTimeStringValue, getUnsubscribeReason } from '../../../../models/UiUtils';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	contact?: ContactViewModel;
	styles?: StyleDeclarationValue[];
}

export const ContactEmailUnsubscribeInfo: React.FC<IProps> = props => {
	const { className, styles, contact } = props;

	const unsubscribeDate = contact.unsubscribeMetadata?.date;
	return (
		<div className={`${css(styleSheet.container, ...(styles || []))} component-class ${className || ''}`}>
			{unsubscribeDate && (
				<div>
					{`Contact unsubscribed ${getUnsubscribeReason(contact.unsubscribeMetadata)} on ${getDefaultDateStringValue(
						unsubscribeDate
					)} at ${getDefaultTimeStringValue(unsubscribeDate)}`}
				</div>
			)}
		</div>
	);
};
