import * as Api from '@ViewModels';
import { action, computed, observable } from 'mobx';
import { GameViewModel } from './games';

export abstract class AchievementsViewModel<
	TRewardConfig extends Api.IAchievementRewardConfiguration = Api.IAchievementRewardConfiguration,
	TAchievementConfig extends
		Api.IAchievementConfiguration<TRewardConfig> = Api.IAchievementConfiguration<TRewardConfig>,
	TAchievement extends AchievementViewModel<TRewardConfig> = AchievementViewModel<TRewardConfig>,
> extends Api.ViewModel {
	@observable.ref protected mAchievements: TAchievement[];

	constructor(userSession: Api.UserSessionContext) {
		super(userSession);
		this.createAchievement = this.createAchievement.bind(this);
	}

	@computed
	public get fetchResults() {
		return this.mAchievements;
	}

	@action
	public load = async () => {
		if (this.loading) {
			return;
		}

		this.loading = true;
		const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<
			Api.IAchievement<TRewardConfig, TAchievementConfig>[]
		>(this.composeApiUrl({ urlPath: 'achievement' }), 'GET');

		this.loading = false;

		if (!opResult.success) {
			throw opResult;
		}

		const achievements = opResult.value.map(x => this.createAchievement(x));
		await Promise.all(achievements.map(x => x.load()));
		this.mAchievements = achievements;
		this.loaded = true;
		return opResult;
	};

	protected abstract createAchievement(model: Api.IAchievement<TRewardConfig, TAchievementConfig>): TAchievement;
}

export class AchievementViewModel<
	TRewardConfig extends Api.IAchievementRewardConfiguration = Api.IAchievementRewardConfiguration,
	TGameType extends Api.IGame = Api.IGame,
> extends Api.ViewModel {
	@observable.ref protected mAchievement: Api.IAchievement<TRewardConfig>;

	@observable.ref protected mGame: GameViewModel<TGameType>;

	constructor(userSession: Api.UserSessionContext, achievement: Api.IAchievement<TRewardConfig>) {
		super(userSession);
		this.mAchievement = achievement;
		this.canSaveConfiguration = this.canSaveConfiguration.bind(this);
		this.mSetConfiguration = this.mSetConfiguration.bind(this);
	}

	@computed
	public get configuration() {
		return this.mAchievement?.achievementConfiguration;
	}

	@computed
	public get description() {
		return this.mAchievement?.description;
	}

	@computed
	public get type() {
		return this.mAchievement?.type;
	}

	@action
	public load = async () => {
		if (this.mGame?.isLoaded) {
			return;
		}

		let gameId: string = null;
		switch (this.mAchievement?.achievementConfiguration?.rewardConfiguration.rewardType) {
			case Api.AchievementRewardType.GameToken: {
				const config = this.mAchievement.achievementConfiguration
					.rewardConfiguration as any as Api.IGameTokenRewardConfiguration;
				gameId = config.gameId;
				break;
			}
			default: {
				break;
			}
		}

		if (!gameId) {
			return;
		}

		if (this.loading) {
			return;
		}

		this.loading = true;

		const game = new GameViewModel<TGameType>(this.mUserSession, { id: gameId } as TGameType);
		const opResult = await game.load();

		this.loading = false;

		if (!opResult.success) {
			throw opResult;
		}

		this.loaded = true;
		this.mGame = game;
	};

	@action
	public setConfiguration = async (config: Partial<Api.IAchievementConfiguration<TRewardConfig>>) => {
		if (this.busy) {
			return;
		}

		this.busy = true;
		const opResult = await this.mSetConfiguration(config);
		this.busy = false;
		if (!opResult.success) {
			throw opResult;
		}

		return opResult;
	};

	public canSaveConfiguration(config: Api.IAchievementConfiguration<TRewardConfig>) {
		return !!config?._type && !!config?.rewardConfiguration;
	}

	protected async mSetConfiguration(config: Partial<Api.IAchievementConfiguration<TRewardConfig>>) {
		const create = !this.mAchievement?.achievementConfiguration?.id;
		const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<
			Api.IAchievementConfiguration<TRewardConfig>
		>(
			this.composeApiUrl({ urlPath: `achievement/configuration${create ? '' : `/${config.id}`}` }),
			create ? 'POST' : 'PUT',
			config._type
				? config
				: {
						...config,
						_type: 'AchievementConfiguration',
					}
		);

		if (opResult.success) {
			this.mAchievement = {
				...this.mAchievement,

				achievementConfiguration: opResult.value,
			};
		}

		return opResult;
	}
}
