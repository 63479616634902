import { css } from 'aphrodite';
import { useHistory } from 'react-router';
import { IContact } from '../../../../extViewmodels';
import { getDisplayName } from '../../../../models/UiUtils';
import { useFullscreenModal } from '../../../../models/hooks/appStateHooks';
import { styleSheet } from './styles';

interface IProps {
	isFullScreenModal: boolean;
	spouseContact: IContact;
}

export const SpouseLink = ({ isFullScreenModal, spouseContact }: IProps) => {
	const fullscreenModal = useFullscreenModal();
	const history = useHistory();
	const handleSpouseClick = () => {
		if (isFullScreenModal) {
			fullscreenModal.history.push(`/people/${spouseContact.id}`);
			return;
		}
		history.push(`/people/${spouseContact.id}`);
	};

	return (
		<a
			href='#'
			className={css(styleSheet.spouse)}
			onClick={ev => {
				ev.preventDefault();
				handleSpouseClick();
			}}
		>
			{getDisplayName(spouseContact)}
		</a>
	);
};
