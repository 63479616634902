import { ActionItemViewModel, EntityViewModel, IEntity, RichContentReferenceMethod } from '@ViewModels';
import { css } from 'aphrodite';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { SizeConstraint } from '../../../../models';
import { IUserSessionComponentProps, UserSessionViewModelKey } from '../../../../models/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '../../../../models/Logging';
import { convertRawRichTextContentStateToRichContentEditorState } from '../../../../models/UiUtils';
import { baseStyleSheet } from '../../../styles/styles';
import { MentionField } from '../../notes/MentionField';
import { RichContentDocumentEditor } from '../../richContent/RichContentDocumentEditor';
import { ActionItemAssociatedNoteHeader } from '../ActionItemAssociatedNoteHeader';
import { ActionItemCard } from '../ActionItemCard';
import { styleSheet } from './styles';

interface IProps extends IEventLoggingComponentProps, IUserSessionComponentProps {
	actionItem: ActionItemViewModel;
	canToggleCompleted?: boolean;
	className?: string;
	contentClassName?: string;
	hideMentionField?: boolean;
	onCheckChanged?(): void;
	onClick?(e: React.MouseEvent<HTMLElement>): void;
	onEntityClicked?(entity: EntityViewModel<IEntity>, e: React.MouseEvent<HTMLElement>): void;
	onSendMessageClicked?(e: React.MouseEvent<HTMLElement>): void;
	rightAccessory?: React.ReactNode;
	sendMessageDisabled?: boolean;
	sizeConstraint?: SizeConstraint;
}

interface IState {
	isComplete?: boolean;
}

export class _ActionItem extends React.Component<IProps, IState> {
	public readonly state: IState = {};

	public render() {
		const {
			className,
			actionItem,
			rightAccessory,
			contentClassName,
			loggingCategory,
			onClick,
			onCheckChanged,
			sendMessageDisabled,
			sizeConstraint,
		} = this.props;

		return (
			<ActionItemCard
				actionItem={actionItem}
				assignee={actionItem.assignee}
				canToggleCompleted={true}
				className={`${css(styleSheet.container)} action-item ${className || ''}`}
				dueDate={actionItem.dueDate}
				header={this.renderHeader()}
				loggingCategory={loggingCategory}
				onCheckChanged={onCheckChanged}
				onClick={onClick}
				readonly={true}
				sizeConstraint={sizeConstraint}
			>
				<div className={css(styleSheet.content)}>
					<div className={`${css(styleSheet.info)} action-item-content ${contentClassName || ''}`}>
						<RichContentDocumentEditor
							className={css(styleSheet.editor)}
							contentState={this.readOnlyEditorState}
							readOnly={true}
						/>
					</div>
					{rightAccessory}
				</div>
				{!sendMessageDisabled && actionItem.referencedContactsForSendMessage.length > 0 && (
					<button
						className={css(baseStyleSheet.ctaButtonSmall, styleSheet.sendButton)}
						onClick={this.onSendMessageClicked}
					>
						<span>Send Email</span>
					</button>
				)}
			</ActionItemCard>
		);
	}

	private renderHeader() {
		const { hideMentionField } = this.props;
		const associatedNoteHeader = this.renderAssociatedNoteHeader();
		const mentions = !hideMentionField && this.renderMentionFieldHeader(!!associatedNoteHeader);
		if (!associatedNoteHeader && !mentions) {
			return null;
		}

		return (
			<>
				{associatedNoteHeader}
				{mentions}
			</>
		);
	}

	private renderAssociatedNoteHeader() {
		const { actionItem } = this.props;
		if (actionItem.associatedNoteModel) {
			return (
				<ActionItemAssociatedNoteHeader
					actionItem={actionItem.toJs()}
					onSeeNoteClicked={this.onSeeAssociatedNoteClicked}
				/>
			);
		}
		return null;
	}

	private renderMentionFieldHeader(hasAssociatedNoteHeader: boolean) {
		const { actionItem, sizeConstraint, onEntityClicked } = this.props;
		const mentionedContacts =
			!!actionItem.mentionedEntities && actionItem.mentionedEntities.length > 0
				? actionItem.mentionedEntities.filter(x => x.method === RichContentReferenceMethod.Explicit).map(x => x.entity)
				: null;
		if (!!mentionedContacts && mentionedContacts.length > 0) {
			return (
				<MentionField
					className={css(hasAssociatedNoteHeader ? styleSheet.mentionsWithNoteHeader : null)}
					label='Who is this reminder about?'
					mentionedEntities={mentionedContacts}
					readOnly={true}
					sizeConstraint={sizeConstraint}
					onEntityClicked={onEntityClicked}
				/>
			);
		}
		return null;
	}

	@computed
	private get readOnlyEditorState() {
		const { actionItem } = this.props;
		return actionItem
			? convertRawRichTextContentStateToRichContentEditorState(actionItem.rawContentState, actionItem.preview)
			: null;
	}

	private onSendMessageClicked = (e: React.MouseEvent<HTMLElement>) => {
		const { logInput, onSendMessageClicked } = this.props;

		logInput('SendMessage', 'Click');
		if (onSendMessageClicked) {
			onSendMessageClicked(e);
		}
	};

	private onSeeAssociatedNoteClicked = (e: React.MouseEvent<HTMLElement>) => {
		// prevent the click event from opening the edit action item modal, because we could be navigating away from the current "page"
		e.stopPropagation();
	};
}

const ActionItemAsObserver = observer(_ActionItem);
const ActionItemWithContext = inject(UserSessionViewModelKey)(ActionItemAsObserver);
export const ActionItem = withEventLogging(ActionItemWithContext, 'ActionItem');
