import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { SizeConstraint } from '../../../../models';
import { IUserSessionComponentProps, UserSessionViewModelKey } from '../../../../models/AppState';
import { RichContentViewModel } from '../../../../viewmodels/AppViewModels';
import { VisibilityIndicatorIcon } from '../../VisibilityIndicatorIcon';
import { styleSheet } from './styles';

interface IProps extends IUserSessionComponentProps {
	className?: string;
	namePrefix?: string;
	richContent: RichContentViewModel;
	sizeConstraint?: SizeConstraint;
}

const RichContentCreatorHeaderSfc: React.FC<IProps> = props => {
	const { className, namePrefix, richContent, sizeConstraint, userSession } = props;

	const userIsCreator = richContent.creatorId === userSession.user.id;
	return (
		<span className={`${css(styleSheet.container)} rich-content-creator-header ${className || ''}`}>
			{!!richContent.creatorName && (
				<span
					className={`${css(
						styleSheet.name,
						sizeConstraint === 'compact' ? styleSheet.nameCompact : null
					)} truncate-text`}
				>
					{`${namePrefix || ''}${richContent.creatorNameShort}`}
				</span>
			)}
			{!!userIsCreator &&
				(richContent.isPublic ? null : (
					<VisibilityIndicatorIcon className={css(styleSheet.privacyIcon)} visibility={richContent.visibility} />
				))}
		</span>
	);
};

const RichContentCreatorHeaderAsObserver = observer(RichContentCreatorHeaderSfc);
export const RichContentCreatorHeader = inject(UserSessionViewModelKey)(RichContentCreatorHeaderAsObserver);
