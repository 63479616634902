import { PersistentStorageManager, storageTypeAvailable } from '@AppModels/Storage';

import {
	asApiError,
	IBaseApiModel,
	ICredential,
	IEventLoggingService,
	IOperationResult,
	IOperationResultNoValue,
	IUserPreferences,
	LogEventContextType,
	NotifyOf,
	UserSessionContext,
	UserViewModel,
	WebServiceHelper,
} from '@ViewModels';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import { action, computed, observable, runInAction } from 'mobx';
import { IPushNotificationMessage } from '../models';

const DefaultFirebaseConfig = {
	apiKey: 'AIzaSyCzVqYM3bYCsFz7hFMAwM2GDsPzF188sLs',
	appId: '1:994032689745:web:2f10dd7108ae7fb8b2ce70',
	authDomain: 'levitate-184800.firebaseapp.com',
	databaseURL: 'https://levitate-184800.firebaseio.com',
	messagingSenderId: '994032689745',
	projectId: 'levitate-184800',
	storageBucket: '',
};

const VapidKey = 'BG2irfOk2jhvYzcsQyrenhawCOkpYMcYULhhtHTYwGpALNYUO8V9Kjkhun8QgSKVYgQArfHEVSEEEgOOjkTreXc';
const FirebaseApp = initializeApp(DefaultFirebaseConfig);

export enum NotificationProvider {
	Invalid = 'Invalid',
	Firebase = 'Firebase',
}

export interface IPushNotificationSubscriptionRequest {
	endPointUrl: string;
	provider: NotificationProvider;
	tokens: string[];
}

export type IPushNotificationSubscription = IPushNotificationSubscriptionRequest & IBaseApiModel;

interface IPushNotificationSettings {
	inactive?: boolean;
	subscription?: IPushNotificationSubscription;
}

export type PushNotificationSubscriptionStore = Record<string, IPushNotificationSettings>;

export type BrowserPushNotificationMessageHandler = (e: MessageEvent) => void;

export const PushNotificationsStorageKey = 'pushNotificationSettings';

export class BrowserPushNotificationsViewModel {
	@observable private busy: boolean;
	@observable private inactive: boolean;
	@observable private notificationSetting: string;
	@observable.ref private mSubscription: IPushNotificationSubscription;
	@observable.ref private mUserSession: UserSessionContext;
	private mLogger: IEventLoggingService;
	private mMessageHandlers: BrowserPushNotificationMessageHandler[];
	private static EventLoggingCategory = 'BrowserPushNotifications';

	public static get isSupported() {
		return (
			!!FirebaseApp &&
			storageTypeAvailable('localStorage') &&
			!!window.Notification &&
			!!window.ServiceWorkerRegistration &&
			!!('showNotification' in ServiceWorkerRegistration.prototype)
		);
	}

	public static getlocalSettings(): PushNotificationSubscriptionStore {
		const json = PersistentStorageManager.local.get(PushNotificationsStorageKey);
		return json ? JSON.parse(json) : undefined;
	}

	public static setLocalSettings(settings: PushNotificationSubscriptionStore) {
		if (!settings) {
			PersistentStorageManager.local.remove(PushNotificationsStorageKey);
		} else {
			PersistentStorageManager.local.set(PushNotificationsStorageKey, JSON.stringify(settings));
		}
	}

	private static async getlocalSettingsAsync() {
		const settings =
			await PersistentStorageManager.local.getObject<PushNotificationSubscriptionStore>(PushNotificationsStorageKey);
		return settings || {};
	}

	public static async setLocalSettingsAsync(settings: PushNotificationSubscriptionStore) {
		const result = await PersistentStorageManager.local.setObject<PushNotificationSubscriptionStore>(
			PushNotificationsStorageKey,
			settings
		);
		return result;
	}

	public static async removeSubscription(serviceHelper: WebServiceHelper, credentials: ICredential) {
		if (!serviceHelper || !credentials || !credentials.user_id) {
			return {
				success: false,
				systemCode: 401,
				systemMessage: 'No credentials',
			};
		}

		const settings = await this.getlocalSettingsAsync();
		if (!settings || !settings[credentials.user_id]) {
			return { success: true };
		}

		const subSettings = settings[credentials.user_id];
		if (!subSettings.subscription || !subSettings.subscription.id) {
			return { success: true };
		}

		return serviceHelper.callWebServiceAsync(
			`PushNotification/subscription/${subSettings.subscription.id}`,
			'DELETE',
			null,
			credentials,
			false
		);
	}

	constructor(userSession: UserSessionContext, eventLogger?: IEventLoggingService) {
		this.mUserSession = userSession;
		this.mLogger = eventLogger || {
			addLogger: () => {
				return;
			},
			logEvent: (args, context) => console.log(args, context),
			removeLogger: () => {
				return;
			},
		};
		this.mMessageHandlers = [];
	}

	@computed
	public get isBusy() {
		return !!this.busy;
	}

	@computed
	public get isActive() {
		return !!this.mSubscription && !!this.mSubscription.id && !this.inactive;
	}

	@computed
	public get userNotificationsNotOptedOut() {
		return (
			this.mUserSession.user.userPreferences.notifyOf.ReadEmails?.pushNotifications !== false &&
			this.mUserSession.user.userPreferences.notifyOf.TextsReceived?.pushNotifications !== false
		);
	}

	@computed
	public get shouldShowPromptDialog() {
		return (
			BrowserPushNotificationsViewModel.isSupported &&
			!this.inactive &&
			this.notificationSetting === 'default' &&
			this.userNotificationsNotOptedOut
		);
	}

	@computed
	public get subscription() {
		return this.mSubscription;
	}

	@computed
	public get notificationDenied() {
		return window.Notification && window.Notification.permission === 'denied';
	}

	@computed
	public get canEnable() {
		return BrowserPushNotificationsViewModel.isSupported && !this.notificationDenied;
	}

	@action
	public async hasBeenDisabled(): Promise<boolean> {
		if (!(await this.loadSettings())) {
			return false;
		}

		return this.inactive;
	}

	@action
	public deactivateSubscription = async () => {
		this.busy = true;

		if (!!this.mSubscription && !!this.mSubscription.id) {
			this.logEvent('Deactivate', {
				id: this.mSubscription.id,
				provider: this.mSubscription.provider,
			});
			const result = await this.mUserSession.webServiceHelper.callWebServiceAsync(
				`PushNotification/subscription/${this.mSubscription.id}`,
				'DELETE'
			);

			const subscription = { ...this.mSubscription };
			delete subscription.id;
			this.mSubscription = subscription;

			if (!result.success) {
				this.busy = false;
				this.logApiError('Deactivate-Error', result);
				throw result;
			}
		}

		this.inactive = true;

		await this.saveSubscriptionToLocalStorage();
		this.mToggleListeningForMessages(false);

		this.busy = false;
	};

	@action
	public loadSettings = async () => {
		if (!BrowserPushNotificationsViewModel.isSupported) {
			return false;
		}

		const stored: IPushNotificationSettings = (await this.loadSubscriptionStore()) || {};
		this.inactive = stored.inactive || false;

		this.mSubscription = stored.subscription;

		return true;
	};

	public init = async (askForPermissionIfNeeded = false) => {
		if (this.isBusy || !BrowserPushNotificationsViewModel.isSupported) {
			return;
		}

		this.busy = true;
		await this.mInit(askForPermissionIfNeeded).catch(() => {
			// nothing to do
		});
		this.busy = false;
	};

	@action
	public toggleNotifications = async (
		enablePushNotifications: boolean,
		userPreferences?: IUserPreferences,
		userViewModel?: UserViewModel
	) => {
		if (!BrowserPushNotificationsViewModel.isSupported || this.isBusy) {
			return;
		}

		this.busy = true;

		if (enablePushNotifications) {
			const userPrefs: IUserPreferences = JSON.parse(
				JSON.stringify(userPreferences || this.mUserSession.user.userPreferences)
			);

			if (!userPrefs.notifyOf[NotifyOf.ReadEmails].pushNotifications) {
				// make sure it is enabled the user preference is enabled

				userPrefs.notifyOf[NotifyOf.ReadEmails].pushNotifications = true;
				const editUserPrefsPromise = (
					userViewModel || new UserViewModel(this.mUserSession, this.mUserSession.user)
				).updateUserPreferences(userPrefs);
				if (editUserPrefsPromise) {
					const updatedUserPrefs = await editUserPrefsPromise.catch(error => {
						const err = asApiError(error);
						this.logEvent('EnableBrowserPush-Error', err);
						this.busy = false;
						throw err;
					});

					this.mUserSession.user.userPreferences = updatedUserPrefs;
				}
			}

			await this.enableBrowserPush().catch(() => {
				// do nothing
			});

			this.busy = false;
			return;
		}

		await this.deactivateSubscription().catch(error => {
			const err = asApiError(error);
			this.logEvent('DisableBrowserPush-Error', err);
			this.busy = false;
			throw err;
		});

		this.busy = false;
	};

	// TODO: Remove this once we identify the cause of the issue
	private isWindowSupported(): { supported: boolean; reason?: string } {
		if (typeof window === 'undefined') {
			return { supported: false, reason: 'window is undefined' };
		}
		if (!('indexedDB' in window)) {
			return { supported: false, reason: 'indexedDB not available' };
		}
		if (!navigator.cookieEnabled) {
			return { supported: false, reason: 'cookies not enabled' };
		}
		if (!('serviceWorker' in navigator)) {
			return { supported: false, reason: 'serviceWorker not available' };
		}
		if (!('PushManager' in window)) {
			return { supported: false, reason: 'PushManager not available' };
		}
		if (!('Notification' in window)) {
			return { supported: false, reason: 'Notification not available' };
		}
		if (!('fetch' in window)) {
			return { supported: false, reason: 'fetch not available' };
		}
		if (!Object.prototype.hasOwnProperty.call(ServiceWorkerRegistration.prototype, 'showNotification')) {
			return { supported: false, reason: 'ServiceWorkerRegistration.showNotification not available' };
		}
		if (!Object.prototype.hasOwnProperty.call(PushSubscription.prototype, 'getKey')) {
			return { supported: false, reason: 'PushSubscription.getKey not available' };
		}
		return { supported: true };
	}

	private mInit = async (askForPermissionIfNeeded = false) => {
		try {
			if (!(await this.loadSettings())) {
				return;
			}

			if (!!this.inactive && !askForPermissionIfNeeded) {
				const error: IOperationResultNoValue = {
					systemCode: 400,
					systemMessage: 'Subscription is inactive',
				};
				throw error;
			}

			// notification permission
			this.notificationSetting = window.Notification.permission;
			if (this.notificationSetting === 'default' && askForPermissionIfNeeded) {
				this.notificationSetting = await window.Notification.requestPermission();
			}

			if (this.notificationSetting === 'denied') {
				const error: IOperationResultNoValue = {
					systemCode: 403,
					systemMessage: 'Permission denied',
				};
				throw error;
			}

			if (this.notificationSetting !== 'granted') {
				const error: IOperationResultNoValue = {
					systemCode: 401,
					systemMessage: 'Permission required',
				};
				throw error;
			}

			// TODO: Remove this once we identify the cause of the issue
			// Check window support before initializing Firebase
			const support = this.isWindowSupported();
			if (!support.supported) {
				const error: IOperationResultNoValue = {
					systemCode: 400,
					systemMessage: `Firebase support check failed: ${support.reason}`,
				};
				this.logApiError('Firebase-Support-Check-Failed', error);
				throw error;
			}

			const firebaseMessaging = getMessaging(FirebaseApp);
			// get the token
			const token = await getToken(firebaseMessaging, { vapidKey: VapidKey });

			if (!token) {
				const error: IOperationResultNoValue = {
					systemCode: 500,
					systemMessage: 'Token load error',
				};
				throw error;
			}

			// save token
			this.logEvent('GetToken-Success');
			this.mSubscription = {
				...(this.mSubscription || {}),
				endPointUrl: 'https://fcm.googleapis.com/fcm/send',
				provider: NotificationProvider.Firebase,
				tokens: [token],
			};

			const opResult = await this.mSubscribe();
			if (!opResult.success) {
				throw opResult;
			}

			runInAction(() => {
				this.inactive = false;
				this.saveSubscriptionToLocalStorage();
				this.mToggleListeningForMessages(true);
			});

			return {
				success: true,
				systemCode: 200,
				value: this.mSubscription,
			};
		} catch (e) {
			const error = asApiError(e);
			this.logApiError('Init-Error', error);
			throw error;
		}
	};

	private enableBrowserPush = async () => {
		if (!!this.subscription && !this.isActive && Notification.permission === 'granted') {
			await this.mSubscribe();
			return;
		}

		await this.mInit(true);
	};

	public addNotificationMessageHandler = (handler: BrowserPushNotificationMessageHandler) => {
		if (handler) {
			if (this.mMessageHandlers.indexOf(handler) < 0) {
				this.mMessageHandlers.push(handler);
			}
			return () => {
				const index = this.mMessageHandlers.indexOf(handler);
				if (index >= 0) {
					this.mMessageHandlers.splice(index, 1);
				}
			};
		}
		return () => {
			return;
		};
	};

	private async mSubscribe(): Promise<IOperationResult<IPushNotificationSubscription>> {
		if (!this.mSubscription) {
			return;
		}

		const eventAction = this.mSubscription.id ? 'UpdateSubscription' : 'Subscribe';

		this.logEvent(eventAction, {
			id: this.mSubscription.id,
			provider: this.mSubscription.provider,
		});
		const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<IPushNotificationSubscription>(
			`PushNotification/subscription${this.mSubscription.id ? `/${this.mSubscription.id}` : ''}`,
			this.mSubscription.id ? 'PUT' : 'POST',
			this.mSubscription
		);

		if (!opResult.success) {
			if (this.mSubscription.id) {
				// failed to update, try to create new one
				this.mSubscription.id = undefined;
				return this.mSubscribe();
			}

			this.logApiError(`${eventAction}-Error`, opResult);
			throw opResult;
		}

		const subscription = {
			...this.mSubscription,
			...opResult.value,
		};
		this.mSubscription = subscription;
		this.inactive = false;

		await this.saveSubscriptionToLocalStorage();

		return opResult;
	}

	private mToggleListeningForMessages = (listen: boolean) => {
		if (listen) {
			navigator.serviceWorker.addEventListener('message', this.onMessage, true);
		} else {
			navigator.serviceWorker.removeEventListener('message', this.onMessage, true);
		}
	};

	private onMessage = (message: MessageEvent) => {
		const handlers = [...this.mMessageHandlers];
		setTimeout(() => {
			handlers.forEach(x => x(message));
		});

		this.showNotification(message.data);
	};

	private showNotification = (firebaseData: any) => {
		const data = firebaseData['firebase-messaging-msg-data'].data as IPushNotificationMessage;
		const notificationOptions = {
			body: data.message,
			icon: data.iconUrl,
		};

		const notify = new Notification(data.title, notificationOptions);
		notify.onclick = () => {
			window.open(data.clickUrl, '_blank');
			notify.close();
		};
	};

	private logApiError = (eventAction: string, error?: IOperationResultNoValue) => {
		if (this.mLogger) {
			const context = error ? { ...error } : undefined;
			this.mLogger.logEvent(
				{
					action: eventAction,
					category: BrowserPushNotificationsViewModel.EventLoggingCategory,
				},
				context
			);
		}
	};

	private logEvent = (eventAction: string, context?: LogEventContextType) => {
		if (this.mLogger) {
			this.mLogger.logEvent(
				{
					action: eventAction,
					category: BrowserPushNotificationsViewModel.EventLoggingCategory,
				},
				context
			);
		}
	};

	public denyPushNotifications() {
		const settings = {
			[this.mUserSession.user.id]: { inactive: true },
		};
		BrowserPushNotificationsViewModel.setLocalSettingsAsync(settings);
		this.inactive = true;
	}

	private async saveSubscriptionToLocalStorage() {
		if (!this.mUserSession || !this.mUserSession.user || !this.mUserSession.user.id) {
			return false;
		}

		const settings = await BrowserPushNotificationsViewModel.getlocalSettingsAsync();
		settings[this.mUserSession.user.id] = {
			inactive: this.inactive,
			subscription: this.mSubscription,
		};

		const success = await BrowserPushNotificationsViewModel.setLocalSettingsAsync(settings);
		return success;
	}

	private async loadSubscriptionStore() {
		if (!this.mUserSession || !this.mUserSession.user || !this.mUserSession.user.id) {
			return undefined;
		}

		const settings = await BrowserPushNotificationsViewModel.getlocalSettingsAsync();
		return settings[this.mUserSession.user.id];
	}
}
