import { ITemplate, TemplatesViewModel } from '@ViewModels';
import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { IUserSessionComponentProps, UserSessionViewModelKey } from '../../../../../models/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '../../../../../models/Logging';
import { SvgIcon } from '../../../svgs/icons/SvgIcon';
import { MessageTemplateSelectModal } from '../../guide/MessageTemplateSelect';
import { styleSheet } from './styles';

interface IProps extends IUserSessionComponentProps, IEventLoggingComponentProps {
	className?: string;
	onTemplateSelected?(template: ITemplate): void;
	templates?: TemplatesViewModel;
}

interface IState {
	showingMessageSelectorModal?: boolean;
}

class _EmailMessageTemplateSelectorButton extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			showingMessageSelectorModal: false,
		};
	}
	public render() {
		const { className } = this.props;
		const { showingMessageSelectorModal } = this.state;

		return (
			<div className={`${css(styleSheet.container)} email-message-template-selector-button ${className || ''}`}>
				<button className={css(styleSheet.button)} onClick={this.onToggleShowMessageSelectorModal(true)}>
					<SvgIcon height={16} width={16}>
						<g fill='#00528C' fillRule='evenodd'>
							<path d='M1.33333333,1.33333333 L8,1.33333333 L8,4 L10.6666667,4 L10.6666667,6 L12,6 L12,3.05733333 L8.94266667,0 L1.33333333,0 C0.598,0 0,0.598666667 0,1.33333333 L0,13.3333333 C0,14.0686667 0.598,14.6666667 1.33333333,14.6666667 L6.66666667,14.6666667 L6.66666667,13.3333333 L1.33333333,13.3333333 L1.33333333,1.33333333 Z' />
							<polygon points='8 6 2.667 6 2.667 7.333 8 7.333' />
							<polygon points='2.667 10 6 10 6 8.667 2.667 8.667' />
							<path d='M10.6666667,12.6666667 C9.564,12.6666667 8.66666667,11.7693333 8.66666667,10.6666667 C8.66666667,9.564 9.564,8.66666667 10.6666667,8.66666667 C11.7693333,8.66666667 12.6666667,9.564 12.6666667,10.6666667 C12.6666667,11.7693333 11.7693333,12.6666667 10.6666667,12.6666667 L10.6666667,12.6666667 Z M13.4446667,12.502 C13.794,11.9746667 14,11.3453333 14,10.6666667 C14,8.82866667 12.5046667,7.33333333 10.6666667,7.33333333 C8.82866667,7.33333333 7.33333333,8.82866667 7.33333333,10.6666667 C7.33333333,12.5046667 8.82866667,14 10.6666667,14 C11.3453333,14 11.9753333,13.794 12.502,13.4446667 L14.6666667,15.6093333 L15.6093333,14.6666667 L13.4446667,12.502 Z' />
						</g>
					</SvgIcon>
					<div className={`${css(styleSheet.buttonText)} truncate-text`}>Use existing email template</div>
				</button>
				<MessageTemplateSelectModal
					hideCreateNewMessageOption={true}
					modalProps={{
						isOpen: showingMessageSelectorModal,
						onRequestClose: this.onMessageSelectorModalRequestClose,
						useDefaultHeader: true,
					}}
					title='Use Existing Email Template'
				/>
			</div>
		);
	}

	private onToggleShowMessageSelectorModal = (show: boolean) => () => {
		this.setState({
			showingMessageSelectorModal: show,
		});
	};

	private onMessageSelectorModalRequestClose = (template: ITemplate, cancel?: boolean) => {
		if (!!template && !cancel) {
			this.onTemplateSelected(template);
		} else {
			this.setState({
				showingMessageSelectorModal: false,
			});
		}
	};

	private onTemplateSelected = (template: ITemplate) => {
		const { onTemplateSelected } = this.props;
		this.setState({
			showingMessageSelectorModal: false,
		});
		if (!!template && !!onTemplateSelected) {
			onTemplateSelected(template);
		}
	};
}

const EmailMessageTemplateSelectorButtonAsObserver = observer(_EmailMessageTemplateSelectorButton);
const EmailMessageTemplateSelectorButtonWithContext = inject(UserSessionViewModelKey)(
	EmailMessageTemplateSelectorButtonAsObserver
);
export const EmailMessageTemplateSelectorButton = withEventLogging(
	EmailMessageTemplateSelectorButtonWithContext,
	'EmailMessageTemplateSelectorButton'
);
