import { IKeyFact, KeyDateKind } from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { baseStyleSheet } from '../../../styles/styles';
import { DeprecatedPopover, PopoverType } from '../../DeprecatedPopover';
import { KeyDateIcon } from '../../svgs/icons/KeyDateIcon';
import { styleSheet } from './styles';

interface IProps {
	keyFact: IKeyFact;
	onRemoveKeyDate?(): void;
	readonly?: boolean;
	textValueClassName?: string;
}

function _KeyFactWithKeyDate({ keyFact, onRemoveKeyDate, readonly, textValueClassName }: IProps) {
	const userSession = useUserSession();
	const [showingKeyDatePopover, setShowingKeyDatePopover] = React.useState(false);
	const popoverTimeoutHandle = React.useRef(null);
	const days = userSession.account?.preferences?.renewalEmailDaysToLookAhead;
	const renewalLookAhead = days ? days.toLocaleString() : '30-60';

	const textBefore = keyFact.value.substring(0, keyFact.keyDate.span.offset);
	const dateText = keyFact.value.substring(
		keyFact.keyDate.span.offset,

		keyFact.keyDate.span.offset + keyFact.keyDate.span.length
	);

	const textAfter = keyFact.value.substring(keyFact.keyDate.span.offset + keyFact.keyDate.span.length);
	const clearPopoverTimeout = () => {
		if (popoverTimeoutHandle.current) {
			clearTimeout(popoverTimeoutHandle.current);
			popoverTimeoutHandle.current = null;
		}
	};
	const startPopoverTimeout = () => {
		clearPopoverTimeout();

		popoverTimeoutHandle.current = setTimeout(() => {
			toggleDisplayOfKeyDatePopover(false)();
			clearPopoverTimeout();
		}, 300);
	};
	const toggleDisplayOfKeyDatePopover = (_showingKeyDatePopover: boolean) => () => {
		if (showingKeyDatePopover) {
			clearPopoverTimeout();
		}
		setShowingKeyDatePopover(_showingKeyDatePopover);
		if (!_showingKeyDatePopover) {
			startPopoverTimeout();
		}
	};
	const onRemoveKeyDateClicked = () => {
		setShowingKeyDatePopover(false);
		onRemoveKeyDate?.();
	};
	return (
		<span className={textValueClassName || ''} id={keyFact.id}>
			<span>{textBefore}</span>
			<span className={css(styleSheet.keyDate)}>
				<span>{dateText}</span>
				<DeprecatedPopover
					anchor={
						<span
							className={css(styleSheet.keyDateIcon)}
							onMouseEnter={toggleDisplayOfKeyDatePopover(true)}
							onMouseLeave={readonly ? toggleDisplayOfKeyDatePopover(false) : startPopoverTimeout}
						>
							<KeyDateIcon kind={keyFact.keyDate.kind} />
						</span>
					}
					className='key-fact-key-date-popover'
					dismissOnClickOutside={true}
					isOpen={!!showingKeyDatePopover}
					onRequestClose={toggleDisplayOfKeyDatePopover(false)}
					preferredPlacement='above'
					type={PopoverType.white}
				>
					<div
						className={css(styleSheet.popoverContent)}
						onMouseEnter={clearPopoverTimeout}
						onMouseLeave={startPopoverTimeout}
					>
						{keyFact.keyDate.kind === KeyDateKind.Renewal ? (
							<div>
								We will automatically remind
								<br />
								{`you ${renewalLookAhead} days before this date.`}
							</div>
						) : (
							<div>
								We will automatically remind
								<br />
								you every year on this date.
							</div>
						)}

						{!readonly && (
							<button
								className={css(baseStyleSheet.brandLink, styleSheet.popoverRemoveButton)}
								onClick={onRemoveKeyDateClicked}
							>
								<span>Remove</span>
							</button>
						)}
					</div>
				</DeprecatedPopover>
			</span>
			<span>{textAfter}</span>
		</span>
	);
}

export const KeyFactWithKeyDate = observer(_KeyFactWithKeyDate);
