import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { getMaxAttachmentByteCountForEmailConfigurations } from '../../../../extViewmodels/Utils';
import { EventLogger } from '../../../../models/Logging';
import { getFileSizeStringValue } from '../../../../models/UiUtils';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import {
	AttachmentsToBeUploadedViewModel,
	IFileAttachment,
	IImpersonationContext,
	IInProgressFileAttachment,
} from '../../../../viewmodels/AppViewModels';
import { baseStyleSheet } from '../../../styles/styles';
import { DeprecatedCloseButton } from '../../DeprecatedCloseButton';
import { LoadingSpinner } from '../../LoadingSpinner';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	emailAttachments?: AttachmentsToBeUploadedViewModel<File>;
	impersonationContext?: IImpersonationContext;
	inProgressAttachments?: IInProgressFileAttachment[];
	onRequestRemoveSavedEmailAttachment?(savedAttachment: IFileAttachment): Promise<void>;
	readonly?: boolean;
	savedAttachments?: IFileAttachment[];
}

function EmailAttachment({
	size,
	exceedsMaxByteCount,
	name,
	onRemoveClicked,
	pending,
}: {
	size: number;
	exceedsMaxByteCount?: boolean;
	name: string;
	onRemoveClicked?(e: React.MouseEvent<HTMLElement>): void;
	pending?: boolean;
}) {
	const fileSizeStringValue = getFileSizeStringValue(size);
	return (
		<div
			className={`${css(
				styleSheet.item,
				exceedsMaxByteCount ? styleSheet.itemError : null,
				pending ? styleSheet.itemPending : null
			)}`}
			key={name}
		>
			<span className={css(baseStyleSheet.truncateText)} title={name}>
				{name}
			</span>
			<span className={css(styleSheet.container)} title={fileSizeStringValue}>
				{fileSizeStringValue}
			</span>
			{pending ? (
				<LoadingSpinner type='tiny' />
			) : onRemoveClicked ? (
				<DeprecatedCloseButton styles={[styleSheet.itemRemove]} onClick={onRemoveClicked} />
			) : null}
		</div>
	);
}

export const EmailAttachments = observer(function EmailAttachmentsBase({
	className,
	emailAttachments,
	impersonationContext,
	inProgressAttachments,
	onRequestRemoveSavedEmailAttachment,
	readonly,
	savedAttachments,
}: IProps) {
	const userSession = useUserSession();
	const onRemoveSavedEmailAttachment = (savedAttachment: IFileAttachment) => (e: React.MouseEvent<HTMLElement>) => {
		e.stopPropagation();
		e.preventDefault();
		onRequestRemoveSavedEmailAttachment?.(savedAttachment);
	};

	const onRemoveClicked = (file: File) => (e: React.MouseEvent<HTMLElement>) => {
		e.stopPropagation();
		e.preventDefault();

		emailAttachments.remove([file]);
		EventLogger.logInput('EmailAttachments', `Remove`, 'Click', {
			size: file.size,
			type: file.type,
		});
	};

	if (emailAttachments?.attachments?.length === 0 && savedAttachments?.length === 0) {
		return null;
	}

	const maxBytes = getMaxAttachmentByteCountForEmailConfigurations(
		impersonationContext?.account?.emailProviderConfigurations || userSession.account.emailProviderConfiguration
	);
	const hasExceededMaxByteCount =
		emailAttachments?.hasExceededMaxByteCount ||
		emailAttachments?.byteCount +
			(savedAttachments || []).reduce((count, x) => count + x.fileSize, 0) +
			inProgressAttachments?.reduce((count, x) => count + x.fileSize, 0) >
			maxBytes;
	return (
		<div className={`email-attachments ${className || ''} ${css(styleSheet.container)}`}>
			{!!hasExceededMaxByteCount && (
				<div className={css(styleSheet.error)}>
					{`You’ve exceeded the attachment size limit of ${getFileSizeStringValue(maxBytes)}.`}
				</div>
			)}
			<div className={css(styleSheet.content)}>
				{inProgressAttachments?.map(x => {
					return (
						<EmailAttachment
							key={x.uploadTaskId}
							name={x.fileName}
							onRemoveClicked={!readonly && x.id ? onRemoveSavedEmailAttachment(x as IFileAttachment) : undefined}
							pending={Boolean(x.uploadPromise)}
							size={x.fileSize}
						/>
					);
				})}
				{savedAttachments?.map(x => {
					return (
						<EmailAttachment
							key={x.id}
							name={x.fileName}
							onRemoveClicked={onRemoveSavedEmailAttachment(x)}
							size={x.fileSize}
						/>
					);
				})}
				{emailAttachments?.attachmentsWithMetadata.map((x, i) => {
					return (
						<EmailAttachment
							exceedsMaxByteCount={x.exceedsMaxByteCount}
							key={`${x.file.name || ''}-${i}`}
							name={x.file.name}
							onRemoveClicked={!readonly && onRemoveClicked(x.file)}
							size={x.file.size}
						/>
					);
				})}
			</div>
		</div>
	);
});
