import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import MediaQuery from 'react-responsive';
import { CardSize } from '../../../../models';
import {
	getDisplayName,
	getPlainTextPreviewForRawRichTextContent,
	getShortenedString,
	getTemplateCardGreeting,
} from '../../../../models/UiUtils';
import { ITemplateCard } from '../../../../viewmodels/AppViewModels';
import { baseStyleSheet } from '../../../styles/styles';
import { Button } from '../../Button';
import { MoreMenuDropdown } from '../../MoreMenuDropdown';
import { ISelectOption } from '../../Select';
import { styleSheet } from './styles';

export const CampaignTemplateCardTruncatedBody = ({
	style,
	showReadMore = true,
	text,
	truncated,
}: {
	style?: StyleDeclarationValue[];
	showReadMore?: boolean;
	text: string;
	truncated: boolean;
}) => {
	return (
		<p className={css(styleSheet.textContent, ...(style || []))}>
			<span>
				{text}
				{truncated ? '... ' : ''}
			</span>
			{truncated && showReadMore ? (
				<strong className={css(baseStyleSheet.fontBold)} dangerouslySetInnerHTML={{ __html: 'Read more' }} />
			) : null}
		</p>
	);
};

export const CampaignTemplateCardCta = ({
	ctaText,
	finraImage,
	isFinraReviewed,
	onCtaClicked,
	useWholeCardAsCTA,
}: {
	ctaText: string;
	finraImage: string;
	isFinraReviewed: boolean;
	onCtaClicked: () => void;
	useWholeCardAsCTA: boolean;
}) => {
	if (!onCtaClicked && useWholeCardAsCTA) {
		return null;
	}
	return (
		<div className={css(baseStyleSheet.horizontalStack, styleSheet.buttonStack)}>
			<Button kind='reverse' size='small' onClick={onCtaClicked}>
				<span>{ctaText || 'View Campaign'}</span>
			</Button>
			{isFinraReviewed ? (
				<figure className={css(styleSheet.reviewedStack)}>
					<img src={finraImage} height={14} />
					<span className={css(styleSheet.reviewed)}> Reviewed</span>
				</figure>
			) : null}
		</div>
	);
};

enum MenuOption {
	DELETE = 'DELETE',
	SHARE = 'SHARE',
	UNSHARE = 'UNSHARE',
	SHAREDBY = 'SHAREDBY',
}

export const CampaignTemplateCardMenu = ({
	card,
	onDelete,
	onShare,
	userSession,
}: {
	card: ITemplateCard;
	onDelete?: () => void;
	onShare?: (val: boolean) => void;
	userSession: Api.UserSessionContext;
}) => {
	const options: ISelectOption<MenuOption>[] = [
		{
			dataContext: MenuOption.DELETE,
			id: 'campaign-template-card-menu-option-delete',
			text: 'Delete',
		},
	];

	const canShare = () => {
		return (
			onShare &&
			(card.creator?.id === userSession?.user?.id || userSession?.user?.role?.toLowerCase().includes('admin'))
		);
	};

	const onMenuOptionClick = (selectedOption: ISelectOption<MenuOption>) => {
		switch (selectedOption.dataContext) {
			case MenuOption.DELETE:
				onDelete?.();
				break;
			case MenuOption.SHARE:
				onShare?.(true);
				break;
			case MenuOption.UNSHARE:
				onShare?.(false);
				break;
			default:
				break;
		}
	};

	if (canShare()) {
		if (card?.scope === Api.TemplateScope.Account) {
			options.push(
				{
					dataContext: MenuOption.UNSHARE,
					id: 'campaign-template-card-menu-option-unshare',
					text: 'Unshare',
				},
				{
					dataContext: MenuOption.SHAREDBY,
					id: 'campaign-template-card-menu-option-shared-by',
					preventClose: true,
					styles: [styleSheet.sharedBy],
					text: `shared by ${
						card.creator
							? card.creator?.id === userSession?.user?.id
								? 'You'
								: getDisplayName(card.creator, true)
							: 'unknown'
					}`,
				}
			);
		} else {
			options.push({
				dataContext: MenuOption.SHARE,
				id: 'campaign-template-card-menu-option-share',
				text: 'Share',
			});
		}
	} else {
		options.push({
			dataContext: MenuOption.SHAREDBY,
			id: 'campaign-template-card-menu-option-shared-by',
			preventClose: true,
			styles: [styleSheet.sharedBy],
			text: `shared by ${
				card.creator
					? card.creator?.id === userSession?.user?.id
						? 'You'
						: getDisplayName(card.creator, true)
					: 'unknown'
			}`,
		});
	}

	return (
		<div className={css(styleSheet.menu)}>
			<MoreMenuDropdown options={options} onOptionClick={onMenuOptionClick} />
		</div>
	);
};

export const CampaignSummaryFromContent = ({
	card,
	cardSize,
	isAccountTemplate,
}: {
	card: ITemplateCard;
	cardSize: CardSize;
	isAccountTemplate: boolean;
}) => {
	return (
		<div
			className={css(styleSheet.textContainer, cardSize === CardSize.Small ? styleSheet.textContainerSmall : undefined)}
		>
			<MediaQuery minWidth='1200px'>
				{matches => {
					const maxCharCount = matches || cardSize === CardSize.Medium ? 120 : 100;

					let greeting: string = null;

					if (isAccountTemplate) {
						let lineCount = 0;
						let offset = 0;

						const cardGreeting = getTemplateCardGreeting(card?.content);

						const { text, truncated } = getPlainTextPreviewForRawRichTextContent(card?.content, {
							maxCharCount,
							startingLineOffset(rootElement, lines) {
								lineCount = lines?.length || 0;
								if (lineCount > 0) {
									((lines?.length ? lines : null) || [rootElement])?.forEach((_, index) => {
										if (!greeting && index === 0) {
											const placeholder = cardGreeting?.placeholder;
											if (placeholder?.previousElementSibling) {
												/**
												 * Offset is set to zero because of the following edge case:
												 *
												 * - Where the placeholder is not the first element in the paragraph and the paragraph has content
												 *   after the placeholder in the same element
												 */
												offset = 0;
												return offset;
											}
											/**
											 * Greeting is set here to remove it from the string in the following case:
											 *
											 * - Where the greeting has more that one word before the placeholder.
											 */
											greeting = cardGreeting.greeting;
											if (greeting) {
												offset = 1;
											}
										}
									});
									return offset;
								}
							},
						});

						/** If greeting is null, then the template does not have a greeting, so its set here as a fallback */
						if (!greeting) {
							greeting = cardGreeting.greeting;
						}

						if (greeting?.includes('img')) {
							greeting = greeting.replace(new RegExp(`<img.*?src="(.*?)"[^>]*>`), '');
						}

						/** Removes the greeting from the first paragraph's text, removes any commas, and trims the text */

						const content = greeting ? text.replace(',', '').replace(cardGreeting.greetingString, '').trim() : text;

						if (cardSize === CardSize.Large) {
							return (
								<div className={css(!matches && styleSheet.noPaddingTop)}>
									{greeting ? (
										<div className={css(styleSheet.cardText)} dangerouslySetInnerHTML={{ __html: `${greeting}` }} />
									) : null}
									<CampaignTemplateCardTruncatedBody
										text={content}
										truncated={(truncated && text?.length >= maxCharCount) || (truncated && lineCount > 1)}
									/>
								</div>
							);
						}
					}
					const summary = card?.summary;
					const shortSummary = summary ? getShortenedString(summary?.trim(), maxCharCount) : '';
					return (
						<>
							<CampaignTemplateCardTruncatedBody
								style={cardSize === CardSize.Small ? [styleSheet.textContentSmall] : []}
								text={shortSummary}
								truncated={summary?.length >= maxCharCount}
								showReadMore={false}
							/>
						</>
					);
				}}
			</MediaQuery>
		</div>
	);
};
