import * as Api from '@ViewModels';
import { Editor } from '@tinymce/tinymce-react';
import * as React from 'react';
import * as tinymce from 'tinymce';
import { DefaultRawRichTextContentBlockStyleAttribute } from '../../../extViewmodels';
import { Noop } from '../../../extViewmodels/Utils';
import { FirstNamePlaceholder, LastNamePlaceholder } from '../../../models/Token';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import AppBuildInfo from '../../assets/build-info.json';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { ValidElementsStringValue } from '../../components/richContent/RichContentDocumentEditor/HtmlEditor';
import { PlaceholdersPlugin } from '../../components/richContent/RichContentDocumentEditor/plugins';
import { titles } from '../../styles/colors';

interface IProps {
	handwritingStyle?: Api.HandwritingStyle;
	postcardContent: string;
	onChangePostcardContent: (value: string) => void;
	onChangeHandwritingStyle?: (value: Api.HandwritingStyle) => void;
}

const initSettings: tinymce.RawEditorSettings = {
	base_url: `./static/tinymce/${encodeURIComponent(AppBuildInfo.tinyMceVersion)}`,
	branding: false,
	browser_spellcheck: true,
	content_style: `
		@font-face {
			font-family: 'Aladdin';
			font-style: normal;
			font-weight: normal;
			src: url('./fonts/Aladdin.ttf') format('truetype');
		}
		@font-face {
			font-family: 'Cinderella';
			font-style: normal;
			font-weight: normal;
			src: url('./fonts/Cinderella.ttf') format('truetype');
		}
		@font-face {
			font-family: 'Donald';
			font-style: normal;
			font-weight: normal;
			src: url('./fonts/Donald.ttf') format('truetype');
		}
		@font-face {
			font-family: 'Nemo';
			font-style: normal;
			font-weight: normal;
			src: url('./fonts/Nemo.ttf') format('truetype');
		}
		@font-face {
			font-family: 'Scar';
			font-style: normal;
			font-weight: normal;
			src: url('./fonts/Scar.ttf') format('truetype');
		}
		@font-face {
			font-family: 'Tarzan';
			font-style: normal;
			font-weight: normal;
			src: url('./fonts/Tarzan.ttf') format('truetype');
		}
		@font-face {
			font-family: 'Woody';
			font-style: normal;
			font-weight: normal;
			src: url('./fonts/Woody.ttf') format('truetype');
		}
	`,
	contextmenu: false,
	convert_urls: false,
	forced_root_block_attrs: {
		style: DefaultRawRichTextContentBlockStyleAttribute,
	},
	menubar: false,
	min_height: 100,
	placeholders: [FirstNamePlaceholder, LastNamePlaceholder],
	plugins: 'placeholders',
	resize: false,
	statusbar: false,
	toolbar: `hwcFontsMenu | mergeFieldMenu`,
	valid_elements: ValidElementsStringValue,
};

export function PostcardTextEditor({
	handwritingStyle,
	onChangeHandwritingStyle,
	postcardContent,
	onChangePostcardContent,
}: IProps) {
	const [initialized, setInitialized] = React.useState(false);
	const userSession = useUserSession();
	const onSetup = React.useCallback(
		(editor: tinymce.Editor) => {
			if (onChangeHandwritingStyle) {
				let hwStyle = handwritingStyle;
				editor.ui.registry.addMenuButton('hwcFontsMenu', {
					fetch(callback) {
						const items = Api.SupportedHandwritingStyles.map(value => {
							return {
								onAction() {
									hwStyle = value;
									onChangeHandwritingStyle(value);
								},
								onSetup(api) {
									api.setActive(value === hwStyle);
									return Noop;
								},
								text: value,
								type: 'togglemenuitem',
							} as tinymce.Ui.Menu.ToggleMenuItemSpec;
						});
						callback(items);
					},
					icon: 'change-case',
					text: 'Font',
				});
			}
			editor.ui.registry.addMenuButton('mergeFieldMenu', {
				fetch: callback => {
					callback([
						{
							getSubmenuItems: () => {
								return [
									{
										onAction: () => {
											editor.insertContent('{{first name}}');
										},
										text: 'First Name',
										type: 'menuitem',
									},
									{
										onAction: () => {
											editor.insertContent('{{lastName}}');
										},
										text: 'Last Name',
										type: 'menuitem',
									},
								];
							},
							text: 'Contact',
							type: 'nestedmenuitem',
						},
					]);
				},
				text: 'Merge Fields',
			});

			PlaceholdersPlugin.onInit(userSession, editor);
		},
		[onChangeHandwritingStyle, handwritingStyle, userSession]
	);
	const init = React.useMemo(() => {
		return {
			...initSettings,
			content_style: `${initSettings.content_style} 
			body {
				background: transparent;
				color: ${titles};
				margin: 0;
				padding: 10px;
			}
			`,
			setup: onSetup,
		};
	}, [onSetup]);
	return (
		<>
			{!initialized ? <LoadingSpinner type='small' /> : null}
			<Editor
				init={init}
				value={postcardContent}
				onInit={() => setInitialized(true)}
				onEditorChange={newValue => onChangePostcardContent(newValue)}
			/>
		</>
	);
}
