import { FullScreenModalViewModelKey, IFullscreenModalComponentProps } from '@AppModels/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import { getCompanyInitials, getContactTitleAndCompany, getPrincipalInitials } from '@AppModels/UiUtils';
import { CompanyViewModel, ContactViewModel, EntityViewModel, getTypeForEntity } from '@ViewModels';
import { Avatar } from '@WebComponents/Avatar';
import { DeprecatedPopover, PopoverType } from '@WebComponents/DeprecatedPopover';
import { css } from 'aphrodite';
import { inject } from 'mobx-react';
import * as React from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { Avatar2 } from '../../Avatar2';
import { styleSheet } from './styles';

interface IProps extends IEventLoggingComponentProps, RouteComponentProps<any>, IFullscreenModalComponentProps {
	className?: string;
	entities?: EntityViewModel[];
	overflowEntities?: EntityViewModel[];
}

interface IState {
	redirectPath?: string;
	showingOverflowPopover?: boolean;
}

class _FeedCardAvatars extends React.PureComponent<IProps, IState> {
	private mMounted: boolean;
	private mTimeoutHandle: any;
	public readonly state: IState = {
		showingOverflowPopover: false,
	};

	public componentDidMount() {
		this.mMounted = true;
	}

	public componentWillUnmount() {
		this.clearTimeout();
		this.mMounted = false;
	}

	public render() {
		const { className, entities, overflowEntities } = this.props;
		const { showingOverflowPopover, redirectPath } = this.state;
		if (!entities || entities.length === 0) {
			return null;
		}

		const overflowPopoverAnchor = !!overflowEntities && overflowEntities.length > 0 && (
			<div
				className={css(styleSheet.overflow)}
				onMouseEnter={this.toggleOverflowPopover(true)}
				onMouseLeave={this.toggleOverflowPopover(false, 150)}
				style={{ transform: `translateX(-${6 * entities.length}px)` }}
			>
				{`+${overflowEntities.length}`}
			</div>
		);

		return (
			<div className={`feed-card-avatars ${className || ''}`}>
				<div className={css(styleSheet.content)} style={{ marginRight: `-${6 * entities.length}px` }}>
					{entities.map(x => {
						if (getTypeForEntity(x) === 'company') {
							const company = x as CompanyViewModel;
							return (
								<Avatar2
									styleDeclaration={[styleSheet.avatar]}
									key={x.id}
									fallbackText={getCompanyInitials(x)}
									imgSrc={company?.logoUrl}
								/>
							);
						}
						const contact = x as ContactViewModel;
						return (
							<Avatar2
								styleDeclaration={[styleSheet.avatar]}
								key={x.id}
								fallbackText={getPrincipalInitials(contact)}
								imgSrc={contact?.profilePic}
							/>
						);
					})}
					{!!overflowPopoverAnchor && (
						<DeprecatedPopover
							anchor={overflowPopoverAnchor}
							isOpen={!!showingOverflowPopover}
							preferredPlacement='right'
							type={PopoverType.white}
						>
							<div
								className={css(styleSheet.overflowPopoverContent)}
								onMouseLeave={this.toggleOverflowPopover(false)}
								onMouseEnter={this.toggleOverflowPopover(true)}
							>
								{overflowEntities.map((x, i) => {
									const entityTitleAndCompany = x instanceof ContactViewModel ? getContactTitleAndCompany(x) : null;
									return (
										<div
											key={`${x.id}-${i}`}
											className={css(styleSheet.overflowPopoverEntityRow)}
											onClick={this.onEntityClicked(x)}
										>
											<Avatar className={css(styleSheet.avatar)} entityVm={x} />
											<div className={css(styleSheet.overflowPopoverEntityDetails)}>
												<div className={css(styleSheet.overflowPopoverEntityName)} title={x.name}>
													{x.name}
												</div>
												{!!entityTitleAndCompany && (
													<div className={css(styleSheet.overflowPopoverEntityTitle)} title={entityTitleAndCompany}>
														{entityTitleAndCompany}
													</div>
												)}
											</div>
											{!!redirectPath && <Redirect push={true} to={redirectPath} />}
										</div>
									);
								})}
							</div>
						</DeprecatedPopover>
					)}
				</div>
			</div>
		);
	}

	private onEntityClicked = (entity: EntityViewModel) => () => {
		const { logInput, fullscreenModal } = this.props;
		const type = getTypeForEntity(entity.toJs());

		logInput('NavigateToEntityProfile', 'Click', { id: entity.id, type });
		const redirectPath = `/${type === 'company' ? 'companies' : 'people'}/${entity.id}`;
		if (fullscreenModal) {
			fullscreenModal.history.push({
				pathname: redirectPath,
				state: { viewModel: entity },
			});
		} else {
			this.setState({
				redirectPath,
			});
		}
	};

	private toggleOverflowPopover = (show: boolean, delay?: number) => () => {
		this.clearTimeout();
		const performUpdate = () => {
			if (this.mMounted) {
				this.setState({
					showingOverflowPopover: show,
				});
			}
		};

		if (delay !== undefined && delay !== null) {
			this.mTimeoutHandle = setTimeout(performUpdate, delay);
		} else {
			performUpdate();
		}
	};

	private clearTimeout = () => {
		if (this.mTimeoutHandle) {
			clearTimeout(this.mTimeoutHandle);
			this.mTimeoutHandle = null;
		}
	};
}

const FeedCardAvatarsWithRouter = withRouter(_FeedCardAvatars);
const FeedCardAvatarsWithContext = inject(FullScreenModalViewModelKey)(FeedCardAvatarsWithRouter);
export const FeedCardAvatars = withEventLogging(FeedCardAvatarsWithContext, 'FeedCardAvatars');
