import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { getDisplayName } from '../../../../../models/UiUtils';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { AssigneeIcon } from '../../../../components/svgs/icons/AssigneeIcon';
import { CompaniesIcon } from '../../../../components/svgs/icons/CompaniesIcon';
import { TagIcon } from '../../../../components/svgs/icons/TagIcon';
import { Tag } from '../../../../components/tags/Tag';
import { grayIconFill } from '../../../../styles/colors';
import { baseStyleSheet } from '../../../../styles/styles';
import { styleSheet } from './styles';

interface IContactProps {
	className?: string;
	entity?: Api.IContact | Api.IAccountTag | Api.ICompany;
	highlight: boolean;
	isBusy?: boolean;
	onClick(): void;
	secondaryInfo?: string | JSX.Element;
	styles?: StyleDeclarationValue[];
	type: 'contact' | 'tag' | 'company';
}

export const ContactsAutocompleteResultsItem: React.FC<IContactProps> = props => {
	const { className, styles, entity, highlight, onClick, type, secondaryInfo, isBusy } = props;
	switch (type) {
		case 'company': {
			const company = entity as Api.ICompany;
			return (
				<div
					className={`${css(styleSheet.container, highlight && styleSheet.highlight, ...(styles || []))} ${
						className || ''
					}`}
					onClick={onClick}
				>
					<span>
						<CompaniesIcon stroke={grayIconFill} className={css(styleSheet.companiesIcon)} />
					</span>

					<span className={css(styleSheet.name)}>{company.companyName}</span>
				</div>
			);
		}
		case 'contact': {
			const contact = entity as Api.IContact;
			return (
				<div
					className={`${css(styleSheet.container, highlight && styleSheet.highlight, ...(styles || []))} ${
						className || ''
					}`}
					onClick={onClick}
				>
					<div className={css(styleSheet.icon)}>
						<AssigneeIcon fillColor={grayIconFill} />
						{isBusy && <LoadingSpinner type='tiny' className={css(baseStyleSheet.absoluteCenter)} />}
					</div>
					<span className={`autocomplete-result-item ${css(styleSheet.name)}`}>{getDisplayName(contact)}</span>
					{secondaryInfo && <span className={css(styleSheet.company)}>{secondaryInfo}</span>}
				</div>
			);
		}
		case 'tag': {
			const tag = entity as Api.IAccountTag;
			return (
				<div
					onClick={onClick}
					className={`${css(styleSheet.container, highlight && styleSheet.highlight, ...(styles || []))} ${
						className || ''
					}`}
				>
					<span>
						<TagIcon fillColor={grayIconFill} />
					</span>
					<span className={css(styleSheet.name)}>
						<Tag tagValue={tag.tag} />
					</span>
				</div>
			);
		}
		default: {
			break;
		}
	}
};
