import * as Api from '@ViewModels';
import { css, StyleDeclarationValue } from 'aphrodite';
import * as React from 'react';
import { useErrorMessages } from '../../../models/hooks/appStateHooks';
import { Checkbox } from '../../components/Checkbox';
import { TextInputFormField } from '../../components/TextInputFormField';
import { baseStyleSheet as bs } from '../../styles/styles';
import { styleSheet } from './styles';

// TODO: This should not be under HWC
export function CampaignComposerBaseLayout({
	mainHeader,
	children,
	sideBar,
	styles = [],
}: {
	mainHeader: React.ReactNode;
	children: React.ReactNode;
	sideBar: React.ReactNode;
	styles?: StyleDeclarationValue[];
}) {
	return (
		<div className={css(bs.boxBorder, bs.flex, bs.hFull, bs.p4, ...styles)}>
			<div
				className={css(
					bs.borderBlue100,
					bs.border,
					bs.borderSolid,
					bs.rounded,
					bs.shadow,
					bs.flex,
					bs.flex1,
					bs.flexCol,
					bs.hFull
				)}
			>
				<div className={css(styleSheet.templateContainerHeader)}>{mainHeader}</div>
				{children}
			</div>
			<div className={css(styleSheet.sideNav)}>{sideBar}</div>
		</div>
	);
}

export function BulkHwcCustomLayout({
	mainHeader,
	children,
	sideBar,
	footer,
}: {
	mainHeader: React.ReactNode;
	children: React.ReactNode;
	sideBar: React.ReactNode;
	footer: React.ReactNode;
}) {
	return (
		<div className={css(styleSheet.customRoot)}>
			<div className={css(styleSheet.customLayoutOuterWrapper)}>
				<div className={css(styleSheet.customMainNav)}>
					<div className={css(styleSheet.templateContainerHeader)}>{mainHeader}</div>
					{children}
				</div>
				<div className={css(styleSheet.sideNav)}>{sideBar}</div>
			</div>
			<div className={css(styleSheet.customLayoutFooter)}>{footer}</div>
		</div>
	);
}

export function SaveTemplateModal({
	initialTemplateScope,
	actionButtonsDisabled = false,
	onCancelButtonClicked,
	onSaveButtonClicked,
}: {
	actionButtonsDisabled?: boolean;
	initialTemplateScope: Api.TemplateScope;
	onSaveButtonClicked: ({ templateName, scope }: { templateName: string; scope: Api.TemplateScope }) => void;
	onCancelButtonClicked: () => void;
}) {
	const errorMessages = useErrorMessages();
	const [newTemplateName, setNewTemplateName] = React.useState('');
	const [newTemplateScope, setNewTemplateScope] = React.useState<Api.TemplateScope>(
		initialTemplateScope ?? Api.TemplateScope.User
	);
	const onShareOptionCheckChanged = () => {
		setNewTemplateScope(
			newTemplateScope === Api.TemplateScope.Account ? Api.TemplateScope.User : Api.TemplateScope.Account
		);
	};
	const handleSaveButtonClicked = ({ templateName, scope }: { templateName: string; scope: Api.TemplateScope }) => {
		const name = templateName.trim();
		if (!name) {
			errorMessages.push({
				messages: ['Please enter a name'],
			});
			return;
		}
		onSaveButtonClicked({ scope, templateName: name });
	};
	return (
		<>
			<div className={css(styleSheet.customCardHeaderUserName, bs.mb4)}>Save as new card</div>
			<div className={css(styleSheet.customCardHeaderUserInfo, bs.mb2)}>
				Template Name
				<span className='required'>*</span>
			</div>
			<TextInputFormField
				className={css(bs.mb2)}
				inputId='save-card-message-template-input'
				onChange={ev => setNewTemplateName(ev.target.value)}
				type='text'
				value={newTemplateName}
			/>
			<Checkbox
				className={css(bs.mb4)}
				checked={newTemplateScope === Api.TemplateScope.Account}
				id='save-card-template-share-checkbox'
				onChange={onShareOptionCheckChanged}
			>
				<span>Share template with co-workers</span>
			</Checkbox>
			<div className={css(bs.horizontalStack)}>
				<button
					className={css(bs.ctaButton)}
					disabled={actionButtonsDisabled}
					onClick={() => handleSaveButtonClicked({ scope: newTemplateScope, templateName: newTemplateName })}
				>
					Save
				</button>
				<button className='cta-button-reverse' disabled={actionButtonsDisabled} onClick={onCancelButtonClicked}>
					Cancel
				</button>
			</div>
		</>
	);
}
