import { AutomationTemplateViewModel, EmailMessageViewModel, ITemplate } from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { baseStyleSheet } from '../../../styles/styles';
import { Dropdown } from '../../Dropdown';
import { AutomationsIcon } from '../../svgs/icons/AutomationsIcon';
import { DisclosureIcon } from '../../svgs/icons/DisclosureIcon';
import { styleSheet } from './styles';

interface IProps {
	alternateKeyDateTemplates?: ITemplate[];
	automationTemplates?: AutomationTemplateViewModel[];
	className?: string;
	emailMessage?: EmailMessageViewModel<File>;
	initialEmailBodyTemplate?: ITemplate;
	onSetTemplate?(template?: ITemplate | AutomationTemplateViewModel): void;
	selectedTemplate?: ITemplate | AutomationTemplateViewModel;
	styles?: StyleDeclarationValue[];
}

const AlternateKeyDatesHeaderItem: React.FC<{
	template?: ITemplate | AutomationTemplateViewModel;
	emailMessage?: EmailMessageViewModel<File>;
}> = observer(({ template, emailMessage }) => {
	return template instanceof AutomationTemplateViewModel ? (
		<div
			className={css(
				baseStyleSheet.truncateText,
				baseStyleSheet.horizontalStack,
				styleSheet.automationTemplateSelectorItem
			)}
		>
			<div className={css(styleSheet.automationTemplateSelectorAutomationIcon)}>
				<AutomationsIcon fillColor='#fff' />
			</div>
			<div className={css(baseStyleSheet.truncateText)}>{template.name}</div>
		</div>
	) : (
		<div className={css(baseStyleSheet.truncateText)}>{template?.name || emailMessage?.subject}</div>
	);
});

export const AlternateKeyDatesHeader: React.FC<IProps> = observer(props => {
	const {
		alternateKeyDateTemplates = [],
		automationTemplates = [],
		className,
		emailMessage,
		initialEmailBodyTemplate,
		onSetTemplate,
		selectedTemplate,
		styles = [],
	} = props;

	const [isOpen, setIsOpen] = React.useState(false);
	const onOpenChanged = React.useCallback((open: boolean) => {
		setIsOpen(open);
	}, []);

	const setTemplate = React.useCallback(
		(template: ITemplate) => () => {
			setIsOpen(false);
			onSetTemplate?.(template);
		},
		[onSetTemplate]
	);

	// dedupe and do not show selected in choice list
	const templateChoices = [...automationTemplates, ...alternateKeyDateTemplates, initialEmailBodyTemplate]
		.filter(x => !!x)
		.filter((x, idx, self) => {
			return idx === self.findIndex(y => y.id === x.id) && (selectedTemplate ? selectedTemplate.id !== x.id : true);
		});

	return (
		<Dropdown
			anchor={
				<div className={css(baseStyleSheet.truncateText, styleSheet.dropdownAnchor)}>
					<AlternateKeyDatesHeaderItem template={selectedTemplate} emailMessage={emailMessage} />
					<DisclosureIcon className={css(styleSheet.dropdownIcon)} />
				</div>
			}
			className={`alternate-key-dates-header ${className || ''} ${css(styleSheet.dropdownInline, ...styles)}`}
			contentClassName={css(styleSheet.dropdownMenu)}
			contentPositionY='bottom'
			isOpen={isOpen}
			onOpenChanged={onOpenChanged}
			openOnClick={true}
		>
			<>
				{templateChoices?.map((x, idx) => {
					return (
						<div
							className={css(styleSheet.dropdownItem, baseStyleSheet.truncateText)}
							key={`${x.id}-${idx}`}
							onClick={setTemplate(x)}
						>
							<AlternateKeyDatesHeaderItem template={x} />
						</div>
					);
				})}
			</>
		</Dropdown>
	);
});
