import {
	IContact,
	IMeetingContext,
	RichContentContextSource,
	RichContentReferenceMethod,
	getTypeForEntity,
} from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ILocationState } from '../../../../models';
import { IQuickAddEntityComponentProps, QuickAddEntityViewModelKey } from '../../../../models/AppState';
import { ContactViewModel, NoteViewModel } from '../../../../viewmodels/AppViewModels';
import { brandPrimary } from '../../../styles/colors';
import { baseStyleSheet } from '../../../styles/styles';
import { DeprecatedPopover, PopoverType } from '../../DeprecatedPopover';
import { ContactSearchListItem } from '../../contacts/ContactSearchListItem';
import { FullscreenModalNavLink } from '../../fullscreen/FullscreenModalNavLink';
import { CalendarDateIcon } from '../../svgs/icons/CalendarDateIcon';
import { styleSheet } from './styles';
import './styles.less';

interface IProps extends IQuickAddEntityComponentProps {
	className?: string;
	note: NoteViewModel;
	styles?: StyleDeclarationValue[];
}

interface IState {
	showingUserListPopover?: boolean;
}

class _MeetingAttendeesHeader extends React.Component<IProps & RouteComponentProps<any>, IState> {
	public state: IState = {};

	public render() {
		const { note, className, styles } = this.props;
		if (!note.context || note.context.source !== RichContentContextSource.Meeting || !note.mentionedEntities) {
			return null;
		}

		const implicityReferencedContacts = note.mentionedEntities
			.filter(x => x.method === RichContentReferenceMethod.Implicit && getTypeForEntity(x.entity.toJs()) !== 'company')
			.map(x => x.entity as ContactViewModel);
		const meetingName = note.context.name;
		return (
			<div className={`${css(...(styles || []))} meeting-attendees-header ${className || ''}`}>
				{implicityReferencedContacts.length === 0 ? (
					<div className={css(baseStyleSheet.horizontalStack)}>
						<CalendarDateIcon className={css(styleSheet.dateIcon)} fillColor={brandPrimary} />
						<div className='truncate-text' title={meetingName ? meetingName : undefined}>
							{`Notes from ${meetingName ? `meeting: "${meetingName}"` : 'a meeting'}`}
							{this.renderMeetingContext()}
						</div>
					</div>
				) : (
					<React.Fragment>
						<span>{'Meeting with '}</span>
						{this.getAttendeeList(implicityReferencedContacts)}
					</React.Fragment>
				)}
			</div>
		);
	}

	private renderMeetingContext() {
		const { context } = this.props.note;
		if (
			context &&
			(Object.prototype.hasOwnProperty.call(context, 'durationInMinutes') ||
				Object.prototype.hasOwnProperty.call(context, 'meetingStatus'))
		) {
			const meetingContext: IMeetingContext = context as any;
			const value = [
				meetingContext.meetingStatus,
				`${meetingContext.durationInMinutes ? `${meetingContext.durationInMinutes} min` : ''}`,
			]
				.filter(x => !!x)
				.reduce((prev, curr) => {
					return `${prev}${prev ? ', ' : ''}${curr}`;
				}, '');
			if (!value) {
				return null;
			}
			return <span>{`, ${value}`}</span>;
		}
	}

	private getAttendeeList(implicityReferencedContacts: ContactViewModel[]) {
		const elements: JSX.Element[] = [];
		const contactsBeforeThePopover = implicityReferencedContacts.slice(0, 3);
		const popoverContacts = implicityReferencedContacts.slice(3);
		contactsBeforeThePopover.forEach((contact, i) => {
			if (i > 0) {
				elements.push(<span key={i}>{', '}</span>);
			}

			if (!contact.isValid) {
				elements.push(
					<span
						className='meeting-attendees-header-attendee'
						key={contact.id}
						onClick={this.onInvalidContactClick(contact)}
					>
						{contact.name}
					</span>
				);
			} else {
				const locationState: ILocationState<ContactViewModel, IContact> = {
					viewModel: contact,
				};
				elements.push(
					<FullscreenModalNavLink
						className='meeting-attendees-header-attendee'
						key={contact.id}
						onClick={this.onAttendeeClicked}
						to={{ pathname: `/people/${contact.id}`, state: locationState }}
					>
						<span>{contact.name}</span>
					</FullscreenModalNavLink>
				);
			}
		});

		if (popoverContacts.length > 0) {
			elements.push(
				<span key={elements.length} className=''>
					{' and '}
				</span>
			);
			elements.push(
				<DeprecatedPopover
					key={elements.length}
					className='meeting-attendees-header-popover'
					anchor={
						<span
							className='meeting-attendees-header-popover-trigger'
							onMouseEnter={this.onTogglePopover(true)}
							onMouseLeave={this.onTogglePopover(false)}
						>
							{`${popoverContacts.length} other${popoverContacts.length > 1 ? 's' : ''}`}
						</span>
					}
					isOpen={this.state.showingUserListPopover}
					dismissOnClickOutside={true}
					type={PopoverType.white}
					preferredPlacement='right'
					contentClassName='meeting-attendees-header-popover-content'
				>
					<div className='meeting-attendees-header-contact-list'>
						{popoverContacts.map(contact => {
							return (
								<ContactSearchListItem
									className='meeting-attendees-header-contact-list-item'
									key={contact.id}
									entity={contact.toJs()}
								/>
							);
						})}
					</div>
				</DeprecatedPopover>
			);
		}
		return elements;
	}

	private onAttendeeClicked = (e: React.MouseEvent<HTMLAnchorElement>) => {
		e.stopPropagation();
	};

	private onTogglePopover = (show: boolean) => () => {
		this.setState({
			showingUserListPopover: show,
		});
	};

	private onInvalidContactClick = (contact: ContactViewModel) => () => {
		if (this.props.quickAddEntity) {
			this.props.quickAddEntity.show({
				entity: contact.toJs(),
				onComplete: (error, entity) => {
					if (!error) {
						contact.setEntity(entity);
					}
				},
				type: 'contact',
			});
		}
	};
}

const MeetingAttendeesHeaderAsObserver = observer(_MeetingAttendeesHeader);
const MeetingAttendeesHeaderAsInjected = inject(QuickAddEntityViewModelKey)(MeetingAttendeesHeaderAsObserver);
export const MeetingAttendeesHeader = withRouter(MeetingAttendeesHeaderAsInjected);
