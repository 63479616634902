import { useUserSession } from '@AppModels/hooks/appStateHooks';
import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { baseStyleSheet } from '../../../styles/styles';
import { AutomationSteps } from '../../automation/AutomationSteps';
import { AutomationStartedGraphic } from '../../svgs/graphics/AutomationStartedGraphic';
import { AutomationsWorkflowGraphic } from '../../svgs/graphics/AutomationsWorkflowGraphic';
import { styleSheet } from './styles';

export const AutomationConfirmationHeader = ({
	automationTemplate,
}: {
	automationTemplate: Api.IAutomationTemplate;
}) => {
	const userSession = useUserSession();
	const automationTemplateVm = React.useMemo(() => {
		return new Api.AutomationTemplateViewModel(userSession, automationTemplate);
	}, [automationTemplate, userSession]);
	return (
		<>
			<figure className={css(styleSheet.graphic)}>
				<AutomationsWorkflowGraphic secondaryStyle={true} />
			</figure>
			<h2 className={css(styleSheet.automationTitle)}>Looks like the tag you just added can trigger an automation.</h2>
			<article className={css(styleSheet.automation)}>
				<h3 className={css(styleSheet.automationName)}>{automationTemplate?.name}</h3>
				<div className={css(styleSheet.section)}>
					<div className={css(styleSheet.sectionLabel)}>Steps</div>
					<div className={css(baseStyleSheet.horizontalStack)}>
						<AutomationSteps automationTemplate={automationTemplateVm} maxStepsToDisplay={8} />
					</div>
				</div>
			</article>
		</>
	);
};

interface IAutomationStartedProps {
	className?: string;
	onAutomationConfirmClosed?: () => void;
	styles?: StyleDeclarationValue[];
}

class _AutomationStartedNotification extends React.Component<IAutomationStartedProps> {
	public render() {
		const { className, styles } = this.props;
		return (
			<div
				className={`${css(styleSheet.startedContainer, ...(styles || []))} automation-started-notification ${
					className || ''
				}`}
			>
				<div className={css(baseStyleSheet.flexCenter)}>
					<AutomationStartedGraphic />
				</div>
				<div className={css(styleSheet.startedTitle)}>Automation Started</div>
				<div className={css(styleSheet.startedMessage)}>
					If the first step of an automation is an email, we will start sending in 30 minutes.
				</div>
				<div className={css(baseStyleSheet.flexCenter)}>
					<button className={css(baseStyleSheet.ctaButton)} onClick={this.onDoneClick}>
						<span>Done</span>
					</button>
				</div>
			</div>
		);
	}

	private onDoneClick = () => {
		this.props.onAutomationConfirmClosed();
	};
}

const AutomationStartedNotificationAsObserver = observer(_AutomationStartedNotification);
export const AutomationStartedNotification = inject()(AutomationStartedNotificationAsObserver);
