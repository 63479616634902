import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { IModalContext, ModalChildComponentContextKey } from '../../../../models';
import { EnvironmentKey, FullScreenModalViewModelKey, IEnvironmentComponentProps } from '../../../../models/AppState';
import { useEventLogging } from '../../../../models/Logging';
import { getDisplayName } from '../../../../models/UiUtils';
import { useErrorMessages, useToaster } from '../../../../models/hooks/appStateHooks';
import { useHouseholdDeleteMutation } from '../../../../queries';
import { success } from '../../../styles/colors';
import { bs } from '../../../styles/styles';
import { DeprecatedPopover, PopoverType } from '../../DeprecatedPopover';
import { TinyPopover, PopoverType as TinyPopoverType } from '../../TinyPopover';
import { BulletIcon } from '../../svgs/icons/BulletIcon';
import { HomeIcon } from '../../svgs/icons/HomeIcon';
import { TrashIcon } from '../../svgs/icons/TrashIcon';
import { XIcon } from '../../svgs/icons/XIcon';
import { SpouseLink } from './presentation';
import { styleSheet } from './styles';

interface Props extends IModalContext, IEnvironmentComponentProps {
	spouseContact: Api.IContact;
	onHouseholdDeleted: () => void;
}

function _KeyFactHousehold({ spouseContact, parentModal, environment, onHouseholdDeleted }: Props) {
	const errorMessages = useErrorMessages();
	const toaster = useToaster();
	const { logApiError } = useEventLogging('KeyFactHousehold');
	const [hoveringListItem, setHovering] = React.useState(false);
	const [showingKeyDatePopover, setShowingKeyDatePopover] = React.useState(false);
	const [showDeleteHouseholdPopover, setShowDeleteHouseholdPopover] = React.useState(false);
	const popoverTimeoutHandle = React.useRef(null);
	const clearPopoverTimeout = () => {
		if (popoverTimeoutHandle.current) {
			clearTimeout(popoverTimeoutHandle.current);
			popoverTimeoutHandle.current = null;
		}
	};
	const startPopoverTimeout = () => {
		clearPopoverTimeout();

		popoverTimeoutHandle.current = setTimeout(() => {
			toggleDisplayOfKeyDatePopover(false)();
			clearPopoverTimeout();
		}, 300);
	};
	const toggleDisplayOfKeyDatePopover = (_showingKeyDatePopover: boolean) => () => {
		if (showingKeyDatePopover) {
			clearPopoverTimeout();
		}
		setShowingKeyDatePopover(_showingKeyDatePopover);
		if (!_showingKeyDatePopover) {
			startPopoverTimeout();
		}
	};
	const handleShowDeleteHouseholdPopoverClose = () => {
		setShowDeleteHouseholdPopover(false);
	};
	const handleDeleteHouseholdClick = () => {
		householdDeleteMutation.mutate({ id: spouseContact.household.id });
		setShowDeleteHouseholdPopover(false);
	};
	const householdDeleteMutation = useHouseholdDeleteMutation({
		onError: error => {
			errorMessages.pushApiError(error);
			logApiError('DeleteHouseholdMutation', error);
		},
		onSuccess: () => {
			onHouseholdDeleted();
			toaster.push({
				message: 'Household key fact removed',
				type: 'successMessage',
			});
		},
	});
	return (
		<li
			className={css(bs.flex, bs.itemsCenter, bs.textSm, bs.listNone, bs.py1, bs.textTitles, bs.gap2, styleSheet.root)}
			onMouseEnter={() => setHovering(true)}
			onMouseLeave={() => setHovering(false)}
		>
			<div className={`${css(bs.flex, bs.itemsCenter, bs.flexShrink0, bs.h4, bs.w4, bs.justifyCenter)}`}>
				<BulletIcon />
			</div>
			<div className={css(bs.flex, bs.itemsCenter, bs.justifyBetween, bs.flex1, bs.pr2)}>
				<span className={css(bs.flex, bs.itemsCenter)}>
					Spouse of{' '}
					{environment?.appType !== 'plugin' ? (
						<SpouseLink
							spouseContact={spouseContact}
							isFullScreenModal={parentModal?.name === FullScreenModalViewModelKey}
						/>
					) : (
						<strong className={css(bs.textBlack, bs.textSm, bs.ml1, bs.noUnderline)}>
							{getDisplayName(spouseContact)}
						</strong>
					)}{' '}
					<DeprecatedPopover
						anchor={
							<span
								className={css(styleSheet.homeIcon)}
								onMouseEnter={toggleDisplayOfKeyDatePopover(true)}
								onMouseLeave={startPopoverTimeout}
							>
								<HomeIcon width={12} height={14} stroke={success} />
							</span>
						}
						dismissOnClickOutside
						isOpen={!!showingKeyDatePopover}
						onRequestClose={toggleDisplayOfKeyDatePopover(false)}
						preferredPlacement='above'
						type={PopoverType.white}
					>
						<div
							className={css(styleSheet.popoverContent)}
							onMouseEnter={clearPopoverTimeout}
							onMouseLeave={startPopoverTimeout}
						>
							<strong>Household:</strong> you can customize whether to send a group email or handwritten card to
							individuals or households.
						</div>
					</DeprecatedPopover>
				</span>
				<TinyPopover
					align='end'
					anchor={
						<button
							className={css(
								bs.flex,
								...(hoveringListItem || showDeleteHouseholdPopover
									? [bs.opacity100, bs.pointerEventsAuto]
									: [bs.opacity0, bs.pointerEventsNone])
							)}
							onClick={() => setShowDeleteHouseholdPopover(true)}
							disabled={householdDeleteMutation.isLoading}
						>
							<TrashIcon />
						</button>
					}
					arrow={{
						arrowSize: 10,
					}}
					dismissOnOutsideAction
					isOpen={showDeleteHouseholdPopover}
					onRequestClose={handleShowDeleteHouseholdPopoverClose}
					placement={['top']}
					type={TinyPopoverType.blue}
				>
					<div className={css(bs.boxBorder, bs.maxW80)}>
						<div
							className={css(bs.flex, bs.justifyEnd, bs.pt1, bs.px2)}
							onClick={handleShowDeleteHouseholdPopoverClose}
						>
							<XIcon width={13} height={13} />
						</div>
						<div className={css(bs.p4, bs.boxBorder)}>
							<p>Do you want to delete this key fact?</p>
							<button
								className={css(bs.ctaButtonDestructiveSolid)}
								disabled={householdDeleteMutation.isLoading}
								onClick={handleDeleteHouseholdClick}
							>
								Delete
							</button>
						</div>
					</div>
				</TinyPopover>
			</div>
		</li>
	);
}

const KeyFactHouseholdAsObserver = observer(_KeyFactHousehold);
export const KeyFactHousehold = inject(ModalChildComponentContextKey, EnvironmentKey)(KeyFactHouseholdAsObserver);
