import { ContactViewModel, IOperationResultNoValue } from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { EventLogger } from '../../../../models/Logging';
import { brandPrimary } from '../../../styles/colors';
import { baseStyleSheet } from '../../../styles/styles';
import { Dropdown } from '../../Dropdown';
import { ClockIcon } from '../../svgs/icons/ClockIcon';
import { styleSheet } from './styles';

interface IProps {
	anchorClassName?: string;
	className?: string;
	contact?: ContactViewModel;
	disabled?: boolean;
	keepInTouchFrequency?: number;
	loggingCategory?: string;
	onFrequencyChanged?(keepInTouchFrequency: number): void;
	onOpen?(): void;
}

const KitOptions: [string, number][] = [
	['Every month', 1],
	['Every 2 months', 2],
	['Every 3 months', 3],
	['Every 6 months', 6],
	['Every year', 12],
	['No schedule', -1],
];

interface IState {
	isOpen?: boolean;
	keepInTouchFrequency?: number;
}

class _KitDropdown extends React.Component<IProps, IState> {
	private mMounted: boolean;
	public state: IState = {
		keepInTouchFrequency: -1,
	};

	public componentDidMount() {
		this.mMounted = true;
		const { contact, keepInTouchFrequency, onFrequencyChanged } = this.props;
		if (this.state.keepInTouchFrequency !== keepInTouchFrequency && !!onFrequencyChanged) {
			// controlled case
			this.setState({
				keepInTouchFrequency: keepInTouchFrequency || -1,
			});
		} else {
			if (!!contact && !!contact.isValid && !!contact.userHasKeepInTouch) {
				const promise = contact.loadKeepInTouch();
				if (promise) {
					promise
						.then(() => {
							if (this.mMounted) {
								this.setState({
									keepInTouchFrequency: contact.keepInTouch.frequency || -1,
								});
							}
						})
						.catch(() => {
							if (this.mMounted) {
								this.setState({
									keepInTouchFrequency: -1,
								});
							}
						});
				}
			}
		}
	}

	public componentWillUnmount() {
		this.mMounted = false;
	}

	public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
		if (this.state.keepInTouchFrequency !== nextProps.keepInTouchFrequency && !!nextProps.onFrequencyChanged) {
			// controlled case
			this.setState({
				keepInTouchFrequency: nextProps.keepInTouchFrequency,
			});
		}
	}

	public render() {
		const { className = '' } = this.props;
		const { isOpen, keepInTouchFrequency } = this.state;
		return (
			<Dropdown
				anchor={this.getAnchor()}
				className={`${css(styleSheet.kitDropdown)} ${className}`}
				contentPositionX={keepInTouchFrequency > 0 ? 'right' : 'left'}
				isOpen={isOpen}
				onOpenChanged={this.onOpenChanged}
			>
				<ul className={css(styleSheet.options)}>
					{KitOptions.map(x => {
						return (
							<li className={css(styleSheet.option)} key={x[1]} onClick={this.onFrequencySelected(x[1])}>
								{x[0]}
							</li>
						);
					})}
				</ul>
			</Dropdown>
		);
	}

	private onOpenChanged = (isOpen: boolean) => {
		this.setState({
			isOpen,
		});
	};

	private onTriggerClicked = () => {
		const { onOpen } = this.props;
		this.setState({
			isOpen: !this.state.isOpen,
		});

		if (onOpen) {
			onOpen();
		}
	};

	private onFrequencySelected = (frequency: number) => () => {
		const { contact, loggingCategory, onFrequencyChanged } = this.props;
		const nextState: IState = {
			isOpen: false,
		};

		if (onFrequencyChanged) {
			onFrequencyChanged(frequency);
		} else {
			if (contact) {
				const currentFrequency = this.state.keepInTouchFrequency;
				if (frequency > 0) {
					const promise = contact.keepInTouch.save({
						contactId: contact.id,
						frequency,
					});
					if (promise) {
						nextState.keepInTouchFrequency = frequency;
						EventLogger.logEvent(
							{
								action: 'CreateKit',
								category: loggingCategory || 'KitDropdown',
								label: `${frequency}`,
							},
							{ frequency }
						);
						promise.catch((error: IOperationResultNoValue) => {
							this.setState({
								keepInTouchFrequency: currentFrequency,
							});
							EventLogger.logEvent(
								{
									action: 'CreateKit-Error',
									category: loggingCategory || 'KitDropdown',
								},
								{ ...error }
							);
						});
					}
				} else {
					if (contact.keepInTouch.Id) {
						const promise = contact.keepInTouch.delete();
						if (promise) {
							nextState.keepInTouchFrequency = frequency;
							EventLogger.logEvent({
								action: 'DeleteKit',
								category: loggingCategory || 'KitDropdown',
							});
							promise.catch((error: IOperationResultNoValue) => {
								this.setState({
									keepInTouchFrequency: currentFrequency,
								});
								EventLogger.logEvent(
									{
										action: 'DeleteKit-Error',
										category: loggingCategory || 'KitDropdown',
									},
									{ ...error }
								);
							});
						}
					}
				}
			} else {
				nextState.keepInTouchFrequency = frequency;
			}
		}

		this.setState(nextState);
	};

	private getAnchor = () => {
		const { anchorClassName = '' } = this.props;
		const { keepInTouchFrequency } = this.state;

		const hasKit = keepInTouchFrequency > 0;

		const optionText = hasKit ? KitOptions.find(x => x[1] === keepInTouchFrequency)[0] : null;
		return (
			<div className={`${css(styleSheet.anchor)} ${anchorClassName}`}>
				<div className={css(styleSheet.anchorContent)}>
					{hasKit ? (
						<React.Fragment>
							<div className={css(styleSheet.kitDescription, baseStyleSheet.truncateText)}>
								<ClockIcon className={css(styleSheet.icon)} fillColor={brandPrimary} />

								<span>{`Keep in touch ${optionText.charAt(0).toLocaleLowerCase()}${optionText.substring(1)}`}</span>
							</div>
							<button className={css(baseStyleSheet.link, styleSheet.anchorButton)} onClick={this.onTriggerClicked}>
								Change
							</button>
						</React.Fragment>
					) : (
						<button
							className={css(styleSheet.anchorButton, baseStyleSheet.truncateText)}
							onClick={this.onTriggerClicked}
						>
							<ClockIcon className={css(styleSheet.icon)} />
							<span>Set a reminder to keep in touch</span>
						</button>
					)}
				</div>
			</div>
		);
	};
}

/**
 * If you do not provide a contact and use this component as a controlled component (i.e. supply "keepInTouchFrequency"
 * and the on change handler), then no api call will be made on change
 */
export const KitDropdown = observer(_KitDropdown);
