import { ILocationState } from '@AppModels/.';
import { FullscreenModalNavLink } from '@WebComponents/fullscreen/FullscreenModalNavLink';
import { css } from 'aphrodite';
import { inject } from 'mobx-react';
import * as React from 'react';
import { EnvironmentKey, IEnvironmentComponentProps } from '../../../../models/AppState';
import { ContactViewModel, IContact } from '../../../../viewmodels/AppViewModels';
import { styleSheet } from './styles';

interface IProps extends IEnvironmentComponentProps {
	className?: string;
	recipients?: ContactViewModel[];
	seenOnDate?: Date;
}

const EmailMessageHeaderSfc: React.FC<IProps> = props => {
	const { className, recipients } = props;
	if (!recipients || recipients.length === 0) {
		return null;
	}

	return (
		<div className={`${css(styleSheet.header)} ${className || ''}`}>
			<div className={css(styleSheet.recipients)}>
				<span>Message sent to &nbsp;</span>
				{recipients.map((x, i) => {
					const locationState: ILocationState<ContactViewModel, IContact> = {
						viewModel: x,
					};
					return (
						<React.Fragment key={x.id}>
							<FullscreenModalNavLink
								className={css(styleSheet.inlineMention)}
								to={{
									pathname: `/${props.environment.appType === 'plugin' ? 'plugin/contact' : 'people'}/${x.id}`,
									state: locationState,
								}}
							>
								{x.name}
							</FullscreenModalNavLink>
							{i < recipients.length - 1 ? <span>, and &nbsp;</span> : null}
						</React.Fragment>
					);
				})}
			</div>
		</div>
	);
};

export const EmailMessageHeader = inject(EnvironmentKey)(EmailMessageHeaderSfc);
