import { CSSProperties, StyleSheet } from 'aphrodite';
import { brandPrimary, tagBackgroundColor, titles, urgentDate } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';

export const TagLeftAccessoryIconSize = 20;
export const TagHeight = 30;
export const TagFontSize = 12;
export const TagCssProps: CSSProperties = {
	background: tagBackgroundColor,
	borderRadius: TagHeight / 2,
	boxSizing: 'border-box',
	color: titles,
	fontSize: TagFontSize,
	height: TagHeight,
	letterSpacing: '0',
	padding: '0 10px',
};

export const styleSheet = StyleSheet.create({
	alertIcon: {
		flexShrink: 0,
		height: 16,
		width: 16,
	},
	container: {
		':hover': {
			...CssSelectors.allDescendants(
				{
					display: 'flex',
				},
				'.tag-remove-button'
			),
		},
		...TagCssProps,
		overflow: 'visible',
		position: 'relative',
		transition: 'all 300ms linear',
	},
	containerSelected: {
		background: brandPrimary,
		color: '#fff',
	},
	containerWithLeftAsset: {
		padding: '0px 15px 0px 6px',
	},
	content: {
		alignItems: 'center',
		display: 'flex',
		height: '100%',
		justifyContent: 'center',
		position: 'relative',
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	removeButton: {
		alignItems: 'center',
		display: 'none',
		justifyContent: 'center',
		position: 'absolute',
		right: -2,
		top: -6,
	},
	selectedIndicator: {
		height: TagLeftAccessoryIconSize,
		width: TagLeftAccessoryIconSize,
	},
	tagAlertPopoverMessage: {
		color: urgentDate,
		fontSize: 12,
		letterSpacing: '0',
		padding: '5px 10px',
	},
	tagButton: {
		display: 'flex',
		height: '100%',
		width: '100%',
	},
	tagValue: {
		maxWidth: 200,
	},
	automationPopover: {
		fontSize: 14,
		letterSpacing: '0',
		padding: '5px 10px',
	},
	automationPopoverMessage: {
		color: '#4a4a4a',
		padding: '5px 12px',
	},
	automationPopoverName: {
		color: brandPrimary,
		padding: '5px 12px',
	},
	automationStartButton: {
		margin: '10px 10px',
		padding: '5px 12px',
	},
	automationXIcon: {
		cursor: 'pointer',
		marginRight: 5,
		textAlign: 'right',
	},
});
