import { ITemplate, Industry, TemplateSendOption, TemplateType } from '@ViewModels';
import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import {
	EnvironmentKey,
	IEnvironmentComponentProps,
	IToasterComponentProps,
	ToasterViewModelKey,
} from '../../../../models/AppState';
import { copyToClipboard } from '../../../../models/UiUtils';
import { DayPicker } from '../../../../web/components/DayPicker';
import { DeprecatedCloseButton } from '../../../../web/components/DeprecatedCloseButton';
import { Modal } from '../../../../web/components/Modal';
import { RadioButton } from '../../../../web/components/RadioButton';
import { PopoverType, TinyPopover } from '../../../../web/components/TinyPopover';
import { AutoCompleteSearchField } from '../../../../web/components/autocomplete/AutoCompleteSearchField';
import { SearchIcon } from '../../../../web/components/svgs/icons/SearchIcon';
import { Tag } from '../../../../web/components/tags/Tag';
import { baseStyleSheet } from '../../../../web/styles/styles';
import { styleSheet } from './styles';

const CommonTags = ['Client', 'Prospect'];

const InsuranceTags = [...CommonTags, 'Commercial', 'Personal'];

const RealEstateTags = [...CommonTags, 'Past Client', 'Past Buyer', 'Past Seller', 'Keep in Touch', 'Agent'];

const FinancialLegalTags = [...CommonTags, 'Keep in Touch'];

interface IProps extends IToasterComponentProps, IEnvironmentComponentProps {
	industry?: Industry;
	isOpen: boolean;
	onRequestClose(): void;
	template: ITemplate;
}

export const CopyTemplateLinkModal: React.FC<IProps> = inject(
	EnvironmentKey,
	ToasterViewModelKey
)(
	observer(props => {
		const { environment, isOpen, onRequestClose, industry, template, toaster } = props;
		const [tagsToInclude, setTagsToInclude] = React.useState([]);
		const [inputFocused, setInputFocused] = React.useState(false);
		const [input, setInput] = React.useState('');
		const [calendar, showCalendar] = React.useState(false);

		const [date, setDate] = React.useState<Date>(null);
		const [sendOption, setSendOption] = React.useState<TemplateSendOption>('now');
		const placement = React.useRef<['bottom', 'top']>(['bottom', 'top']);
		const placementReverse = React.useRef<['top', 'bottom']>(['top', 'bottom']);

		React.useEffect(() => {
			if (!isOpen) {
				setTagsToInclude([]);

				setDate(null);
				setSendOption('now');
				setInput('');
				setInputFocused(false);
			}
		}, [isOpen]);

		const onCloseDropdown = () => setInputFocused(false);
		const removeTag = (tag: string) => () => setTagsToInclude([...tagsToInclude.filter(x => x !== tag)]);
		const onInputAction = (focus: boolean) => () => setInputFocused(focus);
		const onRadioClick = (when: TemplateSendOption) => () => setSendOption(when);
		const toggleCalendar = (show: boolean) => () => showCalendar(show);

		const onDayClick = (day: Date) => {
			setDate(day);
		};

		const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
			if (event.keyCode === 13 && input) {
				setTagsToInclude(Array.from(new Set([...tagsToInclude, input])));
				setInput('');
			}
		};

		const onTagSelected = (tag: string) => () => {
			setTagsToInclude(Array.from(new Set([...tagsToInclude, tag])));
			setInputFocused(false);
		};

		const renderTags = () => {
			const tags =
				industry === Industry.Insurance
					? InsuranceTags
					: industry === Industry.RealEstate
						? RealEstateTags
						: FinancialLegalTags;
			return tags

				.filter(x => !tagsToInclude?.includes(x) && x.toLowerCase().includes(input.toLowerCase()))
				.map(x => {
					return (
						<div key={x} className={css(styleSheet.tag)} onClick={onTagSelected(x)}>
							{x}
						</div>
					);
				});
		};

		const renderSelectedTags = () => {
			return (
				<div className={css(styleSheet.selectedTags)}>
					{tagsToInclude.map((x, idx, arr) => {
						return (
							<span className={css(styleSheet.time)} key={x}>
								<Tag tagValue={x} onRemoveButtonClicked={removeTag(x)} showRemoveButton={true} />
								{!(idx === arr.length - 1) && <span className={css(styleSheet.or)}>or</span>}
							</span>
						);
					})}
				</div>
			);
		};

		const onGenerateLink = () => {
			if (sendOption === 'later' && !date) {
				toaster?.push({
					message: 'You must select a date to send at a specific time',
					type: 'errorMessage',
				});
				return;
			}

			let linkToTemplate =
				process.env.LINKS_URL +
				`/launch/templates?cmd=${template.templateType === TemplateType.SocialMediaPost ? 'send' : 'bulkSend'}&id=${
					template.id
				}`;
			if (tagsToInclude.length) {
				tagsToInclude.forEach(x => {
					linkToTemplate = linkToTemplate.concat(`&tag=${encodeURIComponent(x)}`);
				});
			}

			linkToTemplate = linkToTemplate
				.concat(`&sendOption=${encodeURIComponent(sendOption)}`)
				.concat(date ? `&date=${encodeURIComponent(moment(date).format('MM-DD-YYYY'))}` : '');

			const success = copyToClipboard(linkToTemplate);
			toaster?.push({
				message: success ? 'Direct Link copied' : 'Unable to copy link',
				type: success ? 'successMessage' : 'errorMessage',
			});
		};
		const disabledDays = template?.schedule?.expirationDate
			? [{ after: new Date(template?.schedule?.expirationDate) }]
			: [];

		const canSelectTags = template?.templateType !== TemplateType.SocialMediaPost;
		return (
			<Modal isOpen={isOpen} onRequestClose={onRequestClose} useDefaultHeader={true}>
				<div className={css(styleSheet.modalTitle)}>Copy Direct Link</div>
				<div className={css(styleSheet.inputTitle)}>
					{`Create the link with ${canSelectTags ? 'tags and ' : ''}send time preselected:`}
				</div>
				{canSelectTags && (
					<>
						{renderSelectedTags()}
						<TinyPopover
							anchor={
								<AutoCompleteSearchField
									anchorClassName={css(styleSheet.tagInput)}
									clearSearchFieldAfterSelectingItem={true}
									disableAutocomplete={true}
									inputProps={{
										onChange: e => setInput(e.target.value),
										onFocus: onInputAction(true),
										onKeyDown,
										placeholder: 'Search common tags in our system',
										value: input,
									}}
									leftAccessory={<SearchIcon className={css(styleSheet.searchIcon)} />}
									pageSize={10}
									resultsLimit={10}
									retainFocusAfterSelectingItem={true}
								/>
							}
							dismissOnOutsideAction={true}
							isOpen={inputFocused}
							onRequestClose={onCloseDropdown}
							placement={placement.current}
						>
							<div className={css(styleSheet.tagsContainer)}>{renderTags()}</div>
						</TinyPopover>
					</>
				)}
				<>
					<RadioButton
						checked={sendOption === 'now'}
						className={css(styleSheet.radio)}
						id='CategoriesByIndustry-Send-Time-Immediately'
						name='CategoriesByIndustry-Send-Time'
						onChange={onRadioClick('now')}
					>
						<span>Send immediately upon approval</span>
					</RadioButton>
					<RadioButton
						checked={sendOption === 'later'}
						className={css(styleSheet.radio)}
						id='CategoriesByIndustry-Send-Time-Later'
						name='CategoriesByIndustry-Send-Time'
						onChange={onRadioClick('later')}
					>
						<span className={css(styleSheet.time)}>
							<span>Send on a specific date:</span>
							<TinyPopover
								anchor={
									<span
										className={css(styleSheet.selectedTime, date && sendOption === 'later' && styleSheet.textBlack)}
										onClick={toggleCalendar(true)}
									>
										{!date ? 'Select time' : moment(date).format('MMM Do')}
									</span>
								}
								dismissOnOutsideAction={true}
								isOpen={calendar}
								onRequestClose={toggleCalendar(false)}
								placement={placementReverse.current}
								type={PopoverType.white}
								arrow={{ arrowColor: 'white', arrowSize: 10 }}
							>
								<div className={css(styleSheet.calendarPopoverContent)}>
									<div className={css(styleSheet.calendarPopoverContentHeader)}>
										<DeprecatedCloseButton onClick={toggleCalendar(false)} />
									</div>
									<DayPicker
										defaultSelectedDay={date}
										disabledDays={disabledDays}
										environment={environment}
										onDayClick={onDayClick}
										selectedDays={[date]}
									/>
								</div>
							</TinyPopover>
						</span>
					</RadioButton>
				</>
				<div className={css(styleSheet.modalButtons)}>
					<button className={css(baseStyleSheet.ctaButton)} onClick={onGenerateLink}>
						<span>Generate & Copy Link</span>
					</button>
				</div>
			</Modal>
		);
	})
);
