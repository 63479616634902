import * as Api from '@ViewModels';
import { DefaultSupportedIndustries } from '../../../../models';
import { CampaignType } from '../../../../models/AdminModels';
import { getIndustryName } from '../../../../models/UiUtils';
import { ISelectOption } from '../../../../web/components/Select';

export const GhostCalendarAccountAgeOptions: ISelectOption<Api.ContentCalendarSuggestionAccountAge>[] = [
	{
		dataContext: null,
		id: 'account-age-option-all',
		text: 'All customers',
	},
	{
		dataContext: Api.ContentCalendarSuggestionAccountAge.Existing,
		id: 'account-age-option-existing',
		text: 'Existing customers',
	},
	{
		dataContext: Api.ContentCalendarSuggestionAccountAge.New,
		id: 'account-age-option-new',
		text: 'New customers',
	},
];

export const GhostCalendarCampaignTypeOptions: ISelectOption<CampaignType>[] = [
	{
		dataContext: null,
		id: 'campaign-type-option-all',
		text: 'All',
	},
	{
		dataContext: CampaignType.Email,
		id: 'campaign-type-option-email',
		text: 'Email',
	},
	{
		dataContext: CampaignType.Social,
		id: 'campaign-type-option-social',
		text: 'Social Media',
	},
	{
		dataContext: CampaignType.Blog,
		id: 'campaign-type-option-blog',
		text: 'Blog',
	},
];

export const GhostCalendarAllIndustriesOption: ISelectOption = {
	dataContext: Api.ContentCalendarSuggestionAll,
	id: 'industry-option-all',
	text: 'All industries',
	type: 'checkbox',
};

export const GhostCalendarIndustryOptions: ISelectOption<Api.Industry>[] = DefaultSupportedIndustries.map(x => {
	const name = getIndustryName(x);
	return {
		dataContext: x,
		id: `industry-option-${name}`,
		text: name,
		type: 'checkbox',
	};
});

export const GhostCalendarAllSubverticalsOption: ISelectOption = {
	dataContext: Api.ContentCalendarSuggestionAll,
	id: 'subvertical-option-all',
	text: 'All subverticals',
	type: 'checkbox',
};

export interface ISelectedGhostCalendarFilterOptions {
	ages?: ISelectOption<Api.ContentCalendarSuggestionAccountAge>[];
	campaignType?: ISelectOption<CampaignType>[];
	industries?: ISelectOption<Api.Industry>[];
	subverticals?: ISelectOption<string>[];
}
