import { ICompany, IContact, IEntity, IOperationResultNoValue, QuickAddEntityViewModel } from '@ViewModels';
import { StyleDeclaration, css } from 'aphrodite';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { ErrorMessagesViewModelKey, IErrorMessageComponentProps } from '../../../models/AppState';
import { Topics } from '../../../models/LocalNotificationTopics';
import { EventLogger } from '../../../models/Logging';
import { TextInput } from '../../components/TextInput';
import { baseStyleSheet } from '../../styles/styles';
import { LoadingSpinner } from '../LoadingSpinner';
import {
	INotificationServiceComponentProps,
	withNotificationService,
} from '../LocalNotificationObserver/WithNotificationService';
import { RadioButton } from '../RadioButton';
import { styleSheet } from './styles';

interface IProps extends IErrorMessageComponentProps, INotificationServiceComponentProps<IEntity> {
	className?: string;
	contactFields?: IField[];
	disableAutoFocus?: boolean;
	fieldsStyles?: StyleDeclaration[];
	quickAddEntityVm: QuickAddEntityViewModel;
	onSave?: (entity: IContact | ICompany) => void;
	title?: string;
	showVisibility?: boolean;
}

export interface IField {
	autoFocus?: boolean;
	defaultValue?: string;
	optional?: boolean;
	labelText?: string;
	placeholderText?: string;
	propertyName: string;
	type: string;
}

const companyFields: IField[] = [
	{
		autoFocus: true,
		optional: false,
		placeholderText: 'Company Name',
		propertyName: 'companyName',
		type: 'text',
	},
	{
		optional: true,
		placeholderText: 'Email Domain',
		propertyName: 'emailDomain',
		type: 'text',
	},
];

const defaultFields: IField[] = [
	{
		autoFocus: false,
		optional: false,
		placeholderText: 'First Name',
		propertyName: 'firstName',
		type: 'text',
	},
	{
		optional: false,
		placeholderText: 'Last Name',
		propertyName: 'lastName',
		type: 'text',
	},
	{
		optional: true,
		placeholderText: 'Phone Number',
		propertyName: 'newPhoneNumber',
		type: 'text',
	},
	{
		optional: true,
		placeholderText: 'Email',
		propertyName: 'emailAddress',
		type: 'email',
	},
];

const quickAddEntity: React.FC<IProps> = props => {
	const contactFields: IField[] = props.contactFields || defaultFields;

	if (props.quickAddEntityVm.entityType === 'contact' && !!props.quickAddEntityVm.contactCreateOptions) {
		if (Object.prototype.hasOwnProperty.call(props.quickAddEntityVm.contactCreateOptions, 'requireEmail')) {
			const field = contactFields.find(x => x.propertyName === 'emailAddress');
			if (field) {
				field.optional = !props.quickAddEntityVm.contactCreateOptions.requireEmail;
			}
		}
	}

	// eslint-disable-next-line react-hooks/rules-of-hooks
	React.useEffect(() => {
		props.quickAddEntityVm.defaultOnErrorHandler = (error: IOperationResultNoValue) => {
			props.errorMessages.push({
				messages: [error.systemMessage],
			});
			return false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fieldChanged = action((e: React.ChangeEvent<HTMLInputElement>) => {
		// @ts-ignore
		props.quickAddEntityVm[e.target.name] = e.target.value;
	});

	const togglePublicVisibility = (value: boolean) => () => {
		props.quickAddEntityVm.publicVisibility = value;
	};

	const entityType = props.quickAddEntityVm.entityType;
	const fields = entityType === 'contact' ? contactFields : companyFields;
	const content = fields.map((field, i) => {
		const id = `quick-add-entity-${field.propertyName}-field`;
		return (
			<div key={field.propertyName || i} className={css(styleSheet.fieldContainer)}>
				{!!field.labelText && (
					<label className={css(styleSheet.label)} htmlFor={id}>
						{field.labelText}
					</label>
				)}
				<TextInput
					autoFocus={!props.disableAutoFocus && !!field.autoFocus}
					className='quick-add-entity-fields-field'
					inputId={id}
					name={field.propertyName}
					onChange={fieldChanged}
					placeholder={field.placeholderText ? `${field.placeholderText}${field.optional ? ' (optional)' : ''}` : ''}
					type={field.type}
					value={
						field.defaultValue ||
						props.quickAddEntityVm[field.propertyName as keyof QuickAddEntityViewModel]?.toString() ||
						''
					}
				/>
			</div>
		);
	});

	const onSave = action(async () => {
		// try to strip '*@' from emailDomain
		if (entityType === 'company' && !!props.quickAddEntityVm.emailDomain) {
			let emailDomain = props.quickAddEntityVm.emailDomain;
			const indexOfFirstAt = emailDomain.indexOf('@');
			if (indexOfFirstAt > 0) {
				emailDomain = emailDomain.substring(indexOfFirstAt + 1);
			}
			emailDomain = emailDomain.trim();
			emailDomain = emailDomain.replace(/[^0-9a-zA-Z\-.]/g, '');
			props.quickAddEntityVm.emailDomain = emailDomain;
		}

		if (entityType === 'contact' && !!props.quickAddEntityVm.emailAddress) {
			props.quickAddEntityVm.emailAddress = props.quickAddEntityVm.emailAddress.trim();
		}

		try {
			const result = await props.quickAddEntityVm.save();
			if (entityType === 'contact') {
				const { postNotification } = props;

				postNotification({
					info: result.value,
					topic: Topics.CREATE_CONTACT,
				});
			}

			props.onSave?.(result.value);
		} catch (error) {
			EventLogger.logEvent(
				{
					action: 'Save-Error',
					category: `Add${entityType.charAt(0).toUpperCase()}${entityType.substring(1)}`,
				},
				{ ...(error as IOperationResultNoValue) }
			);
		}
	});

	return (
		<div className={`quick-add-entity ${css(styleSheet.container)} ${props.className || ''}`}>
			<div className={`quick-add-entity-title ${css(styleSheet.title)}`}>
				{props.title || (entityType === 'contact' ? 'New Contact' : 'New Company')}
			</div>
			<div className={`quick-add-entity-fields ${css(styleSheet.fields)} ${css(...(props.fieldsStyles || []))}`}>
				{content}
			</div>
			{props.showVisibility && props.quickAddEntityVm.entityType === 'contact' && (
				<div className={`quick-add-entity-visibility ${css(styleSheet.visibility)}`}>
					<RadioButton
						checked={props.quickAddEntityVm.publicVisibility}
						className={`quick-add-entity-visibility-radio-button ${css(styleSheet.visibilityRadioButton)}`}
						id='quick-add-entity-visibility-public-option'
						name='visibilityRadio'
						onChange={togglePublicVisibility(true)}
						value='public'
					>
						Share with employees
					</RadioButton>
					<RadioButton
						checked={!props.quickAddEntityVm.publicVisibility}
						className={`quick-add-entity-visibility-radio-button ${css(styleSheet.visibilityRadioButton)}`}
						id='quick-add-entity-visibility-private-option'
						name='visibilityRadio'
						onChange={togglePublicVisibility(false)}
						value='private'
					>
						Keep contact private
					</RadioButton>
				</div>
			)}
			<button className={css(baseStyleSheet.ctaButton)} disabled={!props.quickAddEntityVm.canCreate} onClick={onSave}>
				Save
			</button>
			{!!props.quickAddEntityVm.isBusy && <LoadingSpinner className='absolute-center' type='large' />}
		</div>
	);
};

const ObservableQuickAddEntity = observer(quickAddEntity);
const QuickAddEntityWithNotifications = withNotificationService(ObservableQuickAddEntity);
export const QuickAddEntity = inject(ErrorMessagesViewModelKey)(QuickAddEntityWithNotifications);
