import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { useContactCustomFields, useCreateContactCustomPropertyMutation } from '../../../../queries';
import { TextInput } from '../../../../web/components/TextInput';
import { baseStyleSheet } from '../../../../web/styles/styles';
import { EditItemPenIcon } from '../../svgs/icons/EditItemPenIcon';
import { EntityInfoFieldLabel } from '../EntityInfoFieldLabel';
import { styleSheet } from './styles';

const isValidUrl = (str: string) => {
	try {
		new URL(str);
		return true;
	} catch {
		return false;
	}
};

const getInitialValue = (customField: Api.IFormField<string>, contact: Api.ContactViewModel) => {
	if (!contact.customProperties) {
		return '';
	}
	return contact.customProperties[customField.name] ?? '';
};

export const CustomField = ({
	customField,
	contact,
}: {
	customField: Api.IFormField<string>;
	contact: Api.ContactViewModel;
}) => {
	const [value, setValue] = React.useState<string>(getInitialValue(customField, contact));
	const [initialValue] = React.useState<string>(value);
	const [isEditing, setIsEditing] = React.useState(false);
	const [isHovering, setIsHovering] = React.useState(false);

	const createContactCustomPropertyMutation = useCreateContactCustomPropertyMutation({
		onSuccess: () => {
			setIsEditing(false);
		},
	});

	const onEdit = () => {
		setIsEditing(true);
	};

	const onSave = () => {
		createContactCustomPropertyMutation.mutate({
			contactId: contact.id,
			fieldName: customField.name,
			value,
		});
	};

	const onCancel = () => {
		setValue(initialValue);
		setIsEditing(false);
	};

	return (
		<section
			className={css(styleSheet.customField)}
			onMouseEnter={() => setIsHovering(true)}
			onMouseLeave={() => setIsHovering(false)}
		>
			<EntityInfoFieldLabel title={customField.label} />

			<div className={css(styleSheet.customFieldData)}>
				{isEditing ? (
					<>
						<TextInput
							inputId={`edit-custom-field-${customField.name}`}
							onChange={e => {
								setValue(e.target.value);
							}}
							type='text'
							value={value}
						/>
						<div className={css(styleSheet.actions)}>
							<button className={css(baseStyleSheet.ctaButtonSmall)} onClick={onSave}>
								Save
							</button>
							<button className={css(baseStyleSheet.ctaButtonReverseSmall)} onClick={onCancel}>
								Cancel
							</button>
						</div>
					</>
				) : (
					<>
						{value !== '' ? (
							<span title={value} className={css(baseStyleSheet.truncateText)}>
								{isValidUrl(value) ? (
									<a href={value} target='_blank' rel='noopener noreferrer'>
										{value}
									</a>
								) : (
									value
								)}
							</span>
						) : (
							<i>(No value set)</i>
						)}
					</>
				)}

				{!isEditing && isHovering ? (
					<button onClick={onEdit}>
						<EditItemPenIcon />
					</button>
				) : null}
			</div>
		</section>
	);
};

export const CustomFieldsEditor = ({ contact }: { contact: Api.ContactViewModel }) => {
	const userSession = useUserSession();

	const hasCustomContactFields = userSession.account.additionalInfo?.hasCustomContactFields;

	const customFieldsQuery = useContactCustomFields({
		refetchOnWindowFocus: false,
		enabled: hasCustomContactFields,
	});

	const customFields = customFieldsQuery.data?.fields;

	if (!hasCustomContactFields || !customFields) {
		return null;
	}

	return (
		<div className={css(styleSheet.container)}>
			{customFields.map(customField => {
				return (
					<CustomField key={`custom-field-section-${customField.name}`} customField={customField} contact={contact} />
				);
			})}
		</div>
	);
};
