import { StyleSheet } from 'aphrodite';
import * as React from 'react';
import { v4 as uuidgen } from 'uuid';
import { Popover, PopoverArrow, PopoverContent, PopoverTrigger } from '../Popover';
import './styles.less';

export interface IPopoverContext {
	registerCloseLock?(): () => void;
}

export const PopoverContext = React.createContext<IPopoverContext>({});

export interface IParentPopoverProps {
	preventClose?: boolean;
}

export const ParentPopover: React.FC<IParentPopoverProps> = props => {
	const closeLockRef = React.useRef<() => void>(null);
	const ctx = React.useContext(PopoverContext);
	React.useEffect(() => {
		closeLockRef.current?.();
		if (!!ctx?.registerCloseLock && props.preventClose) {
			closeLockRef.current = ctx.registerCloseLock();
		}
		return () => {
			closeLockRef.current?.();
		};
	}, [props.preventClose, ctx]);
	return null;
};

interface IProps {
	anchor: React.ReactNode;
	children: React.ReactNode;
	className?: string;
	contentClassName?: string;
	dismissOnClickOutside?: boolean;
	isOpen?: boolean;
	onRequestClose?(): void;
	place?: any;
	preferredPlacement?: any;
	tipSize?: number;
	type?: PopoverType;
}

interface IState {
	isOpen?: boolean;
	popoverContext?: IPopoverContext;
}

export enum PopoverType {
	custom = 0,
	error,
	white,
	emailGuide,
	blue,
	gray,
}

const s = StyleSheet.create({
	content: {
		padding: 0,
		backgroundColor: 'auto',
		borderRadius: 'auto',
		boxShadow: 'auto',
		border: 'auto',
	},
});

const getPopperClassNameForType = (type: PopoverType) => {
	switch (type) {
		case PopoverType.error: {
			return 'popover-content-error';
		}
		case PopoverType.white: {
			return 'popover-content-white';
		}
		case PopoverType.blue: {
			return 'popover-content-blue';
		}
		case PopoverType.emailGuide: {
			return 'popover-content-email-guide';
		}
		case PopoverType.gray: {
			return 'popover-content-gray';
		}
		default: {
			return '';
		}
	}
};

const sideMap: Partial<Record<string, 'top' | 'right' | 'bottom' | 'left'>> = {
	below: 'bottom',
	right: 'right',
	above: 'top',
	left: 'left',
};

/**
 * @deprecated Use Popover instead
 */
export class DeprecatedPopover extends React.Component<IProps, IState> {
	public static defaultProps: IProps = {
		anchor: null,
		children: null,
		type: PopoverType.custom,
	};

	public static getDerivedStateFromProps(props: IProps, state: IState) {
		const nextState: IState = {};

		if (state.isOpen !== props.isOpen) {
			nextState.isOpen = props.isOpen;
		}

		return Object.keys(nextState).length > 0 ? nextState : null;
	}

	private mCloseLocks: string[];

	constructor(props: IProps) {
		super(props);
		this.mCloseLocks = [];
		this.state = {
			isOpen: props.isOpen,
			popoverContext: {
				registerCloseLock: this.registerCloseLock,
			},
		};
	}

	public render() {
		const { type, tipSize = 7 } = this.props;
		const popperClassName = getPopperClassNameForType(type);
		return (
			<Popover open={this.state.isOpen}>
				<PopoverTrigger asChild>{this.props.anchor}</PopoverTrigger>
				<PopoverContent
					side={sideMap[this.props.place] ?? sideMap[this.props.preferredPlacement]}
					onInteractOutside={this.props.dismissOnClickOutside ? this.onOuterAction : undefined}
					styles={[s.content]}
					className={`${this.props.className || ''} Popover ${popperClassName}`}
				>
					<div className='Popover-body'>
						<div className={`popover-content ${this.props.contentClassName || ''}`}>
							<PopoverContext.Provider value={this.state.popoverContext}>{this.props.children}</PopoverContext.Provider>
						</div>
					</div>
					{tipSize > 1 ? <PopoverArrow className='Popover-tip' /> : null}
				</PopoverContent>
			</Popover>
		);
	}

	private registerCloseLock = () => {
		const lockId = uuidgen();
		this.mCloseLocks.push(lockId);
		return () => {
			this.mCloseLocks = this.mCloseLocks.filter(x => x !== lockId);
		};
	};

	private onOuterAction = () => {
		if (this.mCloseLocks.length > 0) {
			return;
		}
		if (this.props.onRequestClose) {
			this.props.onRequestClose();
		} else {
			this.setState({
				isOpen: false,
			});
		}
	};
}
