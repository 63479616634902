import { IUser, ResourceAutoCompleteViewModelType } from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { getDisplayName } from '../../../../models/UiUtils';
import { ResourceAutoCompleteViewModel, UserSessionContext } from '../../../../viewmodels/AppViewModels';
import { baseStyleSheet } from '../../../styles/styles';
import { DeprecatedPopover, PopoverType } from '../../DeprecatedPopover';
import { AutoCompleteDropdown } from '../../autocomplete/AutoCompleteDropdown';
import { ContactSearchListItem } from '../../contacts/ContactSearchListItem';
import { XIcon } from '../../svgs/icons/XIcon';
import CCHelpIconUrl from './ccHelpIcon.svg';
import { styleSheet } from './styles';

interface IProps {
	ccUsers?: IUser[];
	className?: string;
	onCCUsersChanged?(ccUsers: IUser[]): void;
	userSession: UserSessionContext;
}

interface IState {
	ccUsers?: IUser[];
	showHelpPopover?: boolean;
	inputHasFocus?: boolean;
}

class _CCField extends React.Component<IProps, IState> {
	private searchViewModel: ResourceAutoCompleteViewModel<IUser>;

	private dropdownElement: typeof AutoCompleteDropdown;
	public state: IState = {
		ccUsers: this.props.ccUsers || [],
	};

	public UNSAFE_componentWillMount() {
		this.searchViewModel = new ResourceAutoCompleteViewModel<IUser>(this.props.userSession, {
			type: ResourceAutoCompleteViewModelType.User,
		});
	}

	public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
		if (this.props.ccUsers !== nextProps.ccUsers) {
			this.setState({
				ccUsers: nextProps.ccUsers || [],
			});
		}
	}

	public render() {
		const { className } = this.props;
		return (
			<div className={`cc-field ${css(styleSheet.ccField)} ${className || ''}`}>
				<AutoCompleteDropdown
					anchor={this.anchorProvider}
					autoCompleteViewModel={this.searchViewModel}
					className={css(styleSheet.ccDropdown)}
					dropdownContentClassName={css(styleSheet.ccDropdownContent)}
					onItemSelected={this.onUserSelected}
					onRenderEmptyResults={this.onRenderEmptyResults}
					onRenderItem={this.onAutoCompleteItemRender}
					onRequestDeleteBackwards={this.onRequestDeleteBackwards}
					ref={this.onAutoCompleteDropdownRef}
					inputProps={{
						className: `${css(styleSheet.ccFieldText, styleSheet.ccFieldInput)}`,
						onBlur: this.onToggleInputFocus(false),
						onFocus: this.onToggleInputFocus(true),
						style: {
							opacity: this.state.inputHasFocus ? 1 : 0,
							position: this.state.inputHasFocus ? 'relative' : 'absolute',
						},
					}}
				/>
			</div>
		);
	}

	private onToggleInputFocus = (inputHasFocus: boolean) => () => {
		this.setState({
			inputHasFocus,
		});
	};

	private onAutoCompleteDropdownRef = (ref: any) => {
		this.dropdownElement = ref;
	};

	private onRequestDeleteBackwards = () => {
		if (!!this.state.ccUsers && this.state.ccUsers.length > 0) {
			// remove previous
			const ccUsers = [...this.state.ccUsers];
			ccUsers.pop();

			if (this.props.onCCUsersChanged) {
				this.props.onCCUsersChanged(ccUsers);
			} else {
				this.setState({
					ccUsers,
				});
			}
		}
	};

	private onRenderEmptyResults = () => {
		return (
			<div className={`${css(styleSheet.ccFieldNoMatches, baseStyleSheet.truncateText)}`}>
				No matching employee found.
			</div>
		);
	};

	private onAutoCompleteItemRender = (user: IUser, index: number, highlighted: boolean) => {
		return (
			<ContactSearchListItem
				className={`${css(styleSheet.ccFieldUser, highlighted && styleSheet.ccFieldUserHighlighted)}`}
				key={user.id || index}
				onMouseDown={this.onUserClicked(user)}
				renderEmail={true}
				tabIndex={0}
				user={user}
			/>
		);
	};

	private anchorProvider = (inputElement: React.ReactElement<any>) => {
		return (
			<div className={css(styleSheet.ccFieldTokenContainer)} onClick={this.onTokenContainerClicked}>
				<span className={css(styleSheet.ccFieldLabel)}>Cc:</span>
				<div className={css(styleSheet.ccFieldTokens)}>
					{!!this.state.ccUsers &&
						this.state.ccUsers.map((user, i) => {
							return (
								<div key={user.id || i} className={css(styleSheet.ccFieldToken, styleSheet.ccFieldText)}>
									{getDisplayName(user)}
									<button
										className={`${css(styleSheet.ccFieldRemoveButton)} cc-field-remove-button`}
										onClick={this.onRemoveUser(user)}
									>
										<XIcon height={12} width={12} />
									</button>
								</div>
							);
						})}
					{inputElement}
				</div>
				<DeprecatedPopover
					anchor={
						<button
							className={css(styleSheet.ccFieldHelp)}
							onMouseEnter={this.onToggleHelpPopover(true)}
							onMouseLeave={this.onToggleHelpPopover(false)}
						>
							<img src={CCHelpIconUrl} />
						</button>
					}
					dismissOnClickOutside={true}
					isOpen={this.state.showHelpPopover}
					preferredPlacement='below'
					type={PopoverType.white}
				>
					<div className={css(styleSheet.ccFieldHelpContent)}>
						{`You can CC an internal employee\n\ron this note and they will get an\n\remail with this note attached.`}
					</div>
				</DeprecatedPopover>
			</div>
		);
	};

	public focus = () => {
		if (this.dropdownElement) {
			(this.dropdownElement as any).focus();
		}
	};

	private onTokenContainerClicked = () => {
		this.focus();
	};

	private onToggleHelpPopover = (showHelpPopover: boolean) => () => {
		this.setState({
			showHelpPopover,
		});
	};

	private onRemoveUser = (user: IUser) => () => {
		const ccUsers = [...(this.state.ccUsers || [])];
		const index = ccUsers.findIndex(x => x.id === user.id);
		if (index >= 0) {
			ccUsers.splice(index, 1);
			if (this.props.onCCUsersChanged) {
				this.props.onCCUsersChanged(ccUsers);
			} else {
				this.setState({
					ccUsers,
				});
			}
		}
	};

	private onUserClicked = (user: IUser) => () => {
		this.onUserSelected(user);
		if (this.dropdownElement) {
			(this.dropdownElement as any).reset();
		}
	};

	private onUserSelected = (user: IUser) => {
		const currentUsers = this.state.ccUsers || [];
		if (currentUsers.map(x => x.id).indexOf(user.id) >= 0) {
			return;
		}

		const ccUsers = [...currentUsers, user];

		if (this.props.onCCUsersChanged) {
			this.props.onCCUsersChanged(ccUsers);
		} else {
			this.setState({
				ccUsers,
			});
		}
	};
}

export const CCField = observer(_CCField);
