import { inject } from 'mobx-react';
import * as React from 'react';
import { IFabContext } from '../../../models';
import { FabViewModelKey, IFabComponentProps } from '../../../models/AppState';

class _FabContext<T = any> extends React.PureComponent<IFabContext<T> & IFabComponentProps> {
	private mFabContextDisposer: () => void;
	public componentDidMount() {
		this.registerContext();
	}

	public componentWillUnmount() {
		this.unregisterContext();
	}

	public componentDidUpdate() {
		this.registerContext();
	}

	public render() {
		return null as JSX.Element;
	}

	private unregisterContext = () => {
		if (this.mFabContextDisposer) {
			this.mFabContextDisposer();
		}
	};

	private registerContext = () => {
		this.unregisterContext();
		const { fab, ...restProps } = this.props;

		const disposer = fab.registerContext(restProps || {});

		this.mFabContextDisposer = () => {
			disposer();

			this.mFabContextDisposer = null;
		};
	};
}

export const FabContext = inject(FabViewModelKey)(_FabContext);
