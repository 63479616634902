import { IEnvironment } from '../../models/Environment';
import { IAppTheme } from '../../models/Themes';
import * as AppViewModels from '../../viewmodels/AppViewModels';
import { BrowserPushNotificationsViewModel } from '../../viewmodels/PushNotifications';
import { GamificationViewModel } from '../viewModels/gamification';
import { PhoneCallsViewModel } from '../viewModels/phonecall';
import { WebsocketsListener } from '../viewModels/websockets';

export interface IAppState {
	actionItemComposer?: AppViewModels.ActionItemComposerViewModel;
	environment?: IEnvironment;
	errorMessages?: AppViewModels.ErrorMessageViewModel;
	gamification?: GamificationViewModel;
	noteComposer?: AppViewModels.NoteComposerViewModel;
	phoneCalls?: PhoneCallsViewModel;
	pushNotifications?: BrowserPushNotificationsViewModel;
	quickAddEntity?: AppViewModels.QuickAddEntityViewModel;
	singleEmailComposer?: AppViewModels.ComposeEmailToViewModel;
	theme?: IAppTheme;
	toaster?: AppViewModels.ToasterViewModel<AppViewModels.IAppToastMessage>;
	userSession?: AppViewModels.UserSessionContext;
	websockets?: WebsocketsListener;
}

export const ActionItemComposerViewModelKey = 'actionItemComposer';
export const EnvironmentKey = 'environment';
export const ErrorMessagesViewModelKey = 'errorMessages';
export const GamificationViewModelKey = 'gamification';
export const NoteComposerViewModelKey = 'noteComposer';
export const PhoneCallsViewModelKey = 'phoneCalls';
export const PushNotificationsViewModelKey = 'pushNotifications';
export const QuickAddEntityViewModelKey = 'quickAddEntity';
export const SingleEmailComposerKey = 'singleEmailComposer';
export const ThemeKey = 'theme';
export const ToasterViewModelKey = 'toaster';
export const UserSessionViewModelKey = 'userSession';
export const WebsocketsViewModelKey = 'websockets';

export const AppState: IAppState = {};
AppState[ActionItemComposerViewModelKey] = new AppViewModels.ActionItemComposerViewModel();
AppState[EnvironmentKey] = { appType: 'web' };
AppState[ErrorMessagesViewModelKey] = new AppViewModels.ErrorMessageViewModel();

AppState[GamificationViewModelKey] = null;
AppState[NoteComposerViewModelKey] = new AppViewModels.NoteComposerViewModel();

AppState[PhoneCallsViewModelKey] = null;

AppState[PushNotificationsViewModelKey] = null;
AppState[QuickAddEntityViewModelKey] = new AppViewModels.QuickAddEntityViewModel();
AppState[SingleEmailComposerKey] = new AppViewModels.ComposeEmailToViewModel();
AppState[ThemeKey] = new AppViewModels.AppTheme();
AppState[ToasterViewModelKey] = new AppViewModels.ToasterViewModel<AppViewModels.IAppToastMessage>();

AppState[WebsocketsViewModelKey] = null;
