import { StyleDeclarationValue, css } from 'aphrodite';
import { parse as getQueryStringParams } from 'query-string';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { useEventLogging } from '../../../models/Logging';
import { useInitiatePasswordResetMutation } from '../../../queries';
import LogoUrl from '../../assets/logo_blue.svg';
import { baseStyleSheet } from '../../styles/styles';
import { LoadingSpinner } from '../LoadingSpinner';
import { TextInput } from '../TextInput';
import { EmailGraphic } from '../svgs/graphics/EmailGraphic';
import { ForgotPasswordGraphic } from '../svgs/graphics/ForgotPasswordGraphic';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	initialEmailAddress?: string;
	hideLogo?: boolean;
	styles?: StyleDeclarationValue[];
	logo?: React.ReactNode;
}

export function ForgotPassword({ initialEmailAddress, hideLogo, logo, styles = [], className }: IProps) {
	const { logEvent } = useEventLogging('ForgotPassword');
	const [emailAddress, setEmailAddress] = React.useState(initialEmailAddress ?? '');
	const [errorMessage, setErrorMessage] = React.useState(null);
	const [showConfirmation, setShowConfirmation] = React.useState(false);
	const forgotPasswordMutation = useInitiatePasswordResetMutation({
		onError: error => {
			setErrorMessage(error.systemMessage);
			logEvent('Request-Error', { category: 'ForgotPassword', error });
		},
		onSuccess: () => {
			logEvent('Request', { category: 'ForgotPassword' });

			setErrorMessage('');
			setShowConfirmation(true);
		},
	});
	const forgotPasswordClick = () => {
		if (!emailAddress) {
			setErrorMessage('You must enter an email address.');
			return;
		}
		forgotPasswordMutation.mutate({ emailAddress });
	};
	const getContent = () => {
		if (showConfirmation) {
			return (
				<div>
					<div className={css(styleSheet.confirmationGraphic)}>
						<EmailGraphic />
					</div>
					<div className={`forgot-password-title ${css(styleSheet.title)}`}>Reset Password Email Sent!</div>
					<div className={`forgot-password-text ${css(styleSheet.text, styleSheet.centered)}`}>
						Please check your email for a link to reset your password.
						<br />
						The link expires after one hour.
					</div>
				</div>
			);
		}
		return (
			<div>
				<div className={css(styleSheet.graphic)}>
					<ForgotPasswordGraphic />
				</div>
				<div className={`forgot-password-title ${css(styleSheet.title)}`}>Forgot Password</div>
				<div className={css(styleSheet.bodyContainer)}>
					{errorMessage ? (
						<div className={`forgot-password-error ${css(styleSheet.text, styleSheet.errorMessage)}`}>
							{errorMessage}
						</div>
					) : (
						<div className={`forgot-password-text ${css(styleSheet.text)}`}>
							Enter your email and we&apos;ll send you a link to reset your password.
						</div>
					)}
					<div className={css(styleSheet.inputContainer)}>
						<TextInput
							autoFocus={true}
							className={css(styleSheet.input)}
							inputClassName={css(styleSheet.inputElement)}
							inputId='forgotPasswordEmail'
							onChange={ev => {
								setEmailAddress(ev.currentTarget.value);
								setErrorMessage(null);
							}}
							onKeyDown={ev => {
								if (ev.keyCode === 13) {
									forgotPasswordClick();
								}
							}}
							placeholder='you@yourcompany.com'
							type='text'
							value={emailAddress || ''}
						/>
						<span className={css(styleSheet.saveButtonWrapper)}>
							<button
								className={css(baseStyleSheet.ctaButtonSmall)}
								disabled={forgotPasswordMutation.isLoading}
								onClick={forgotPasswordClick}
							>
								Send
							</button>
						</span>
					</div>
				</div>
			</div>
		);
	};
	return (
		<div className={`${css(styleSheet.container, ...styles)} ${className || ''} `}>
			{!hideLogo ? logo ? logo : <img className={css(styleSheet.logo)} src={LogoUrl} /> : null}
			{forgotPasswordMutation.isLoading ? (
				<LoadingSpinner className={css(baseStyleSheet.flexCenter)} type='large' />
			) : (
				getContent()
			)}
		</div>
	);
}

interface IForgotPasswordRouteProps extends RouteComponentProps {
	className?: string;
	styles?: StyleDeclarationValue[];
	logo?: React.ReactNode;
}

export const ForgotPasswordRoute = withRouter(
	class ForgotPasswordRouteBase extends React.PureComponent<IForgotPasswordRouteProps, { email?: string }> {
		constructor(props: IForgotPasswordRouteProps) {
			super(props);
			this.state = {
				email: getQueryStringParams(props.location.search)?.email,
			};
		}

		public render() {
			const { styles = [], className = '', logo } = this.props;
			return (
				<div className={`forgot-password-container ${className} ${css(styleSheet.routeContainer, ...styles)}`}>
					<ForgotPassword initialEmailAddress={this.state.email} logo={logo} />
				</div>
			);
		}
	}
);
